import _ from "lodash";
import { useMutation } from "@apollo/client";

import {
  Stack,
  Typography,
  Paper,
  LoadingButton,
  Grid,
  Alert,
  Button,
  IconButton,
} from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";
import { alpha, useTheme } from "@samacare/design/core/styles";
import { AddTreatmentForm } from "./AddTreatmentForm";
import { useConfig, useSamaCareBrandName } from "../../hooks/config";
import {
  Authorization,
  BenefitsVerificationBasic,
  NewEnrollmentObjectType,
  BenefitsVerificationOutcomeStatus,
  NewEnrollmentStatusEnumType,
  Patient,
  Treatment,
  EnhancedServiecesEnum,
} from "@samacare/graphql";
import { generatePath } from "react-router-dom";
import { CreateBenefitsVerificationMutation } from "@@generated/graphql";
import getAuthorizationRoute from "../../util/getAuthorizationRoute";
import ROUTE_PATHS from "../ROUTE_PATHS";
import CreateBenefitsVerificationMutationGql from "../BenefitsVerifications/graphql/CreateBenefitsVerification.gql";
import { ALL_BENEFITS_VERIFICATIONS_QUERY_NAME } from "../../graphql/BenefitsVerificationOutcome";
import { renderDate } from "./utils";
import NewPriorAuthButton from "@@components/NewPriorAuthButton";
import {
  EnhancedServicesIcon,
  EnhancedServicesIndicatorFull,
} from "@samacare/component";
import { useFeatureFlag } from "@@hooks/index";
import React, { useEffect, useRef, useState } from "react";
import { addDays, isWithinInterval, subDays } from "date-fns";
import Add from "@samacare/design/core/icons/Add";
import MoreHoriz from "@samacare/design/core/icons/MoreHoriz";
import { openBvEditPage } from "../../BenefitsVerifications/SponsoredBvCreationSection";
import { StartEnhancedServiceCard } from "../../components/StartEnhancedServiceCard";

const serviceDetails = (
  serviceInfos: {
    label: string;
    value: string;
    color: string;
  }[]
) => {
  return (
    <Grid container>
      {serviceInfos.map(({ label, value, color }, i) => {
        if (label === "Status") {
          return (
            <Grid item xs={6} marginBottom={2} key={i}>
              <Typography variant="subtitle2">{label}</Typography>
              <Typography variant="subtitle2" color={color}>
                {value}
              </Typography>
            </Grid>
          );
        }
        return (
          <Grid item xs={6} marginBottom={2} key={i}>
            <Typography variant="subtitle2">{label}</Typography>
            <Typography variant="body2" color={color}>
              {value}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

const PatientTreatmentCardAuthDetail: React.FC<{
  auths: (Authorization | null)[];
  patient: Patient;
  enhanced: boolean;
}> = ({ auths, patient, enhanced = false }) => {
  const theme = useTheme();
  const config = useConfig();
  const brandName = useSamaCareBrandName();

  const latestAuth = _.compact(_.orderBy(auths, "updatedAt", "desc"))[0];
  const expiring =
    latestAuth.latestCorrespondence?.endDate != null &&
    isWithinInterval(new Date(latestAuth.latestCorrespondence.endDate), {
      start: subDays(new Date(), 30),
      end: addDays(new Date(), 30),
    });

  const renderGrid = (auth: Authorization) => {
    const defaultColor = theme.palette.grey.A400;

    const renderDefaultViews = (statusColor: string) => {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(auth.status.toLowerCase()),
          color: statusColor,
        },
        {
          label: "Last Updated",
          value: renderDate(auth.updatedAt),
          color: defaultColor,
        },
        {
          label: "Created At",
          value: renderDate(auth.createdAt),
          color: defaultColor,
        },
        {
          label: "Service Date",
          value: renderDate(auth.dateOfService),
          color: defaultColor,
        },
      ]);
    };

    const lastUpdatedInfo = [
      {
        label: "Last Updated",
        value: renderDate(auth.updatedAt),
        color: defaultColor,
      },
    ];

    if (auth.status === config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION) {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(auth.status.toLowerCase()),
          color: theme.palette.grey.A400,
        },
        ...lastUpdatedInfo,
        {
          label: "Created At",
          value: renderDate(auth.createdAt),
          color: defaultColor,
        },
        {
          label: "Service Date",
          value: renderDate(auth.dateOfService),
          color: defaultColor,
        },
      ]);
    } else if (config.CONSTANTS.APPROVED_STATUSES.includes(auth.status)) {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(auth.status.toLowerCase()),
          color: theme.palette.success.main,
        },
        ...lastUpdatedInfo,
        {
          label: "Approved Start Date",
          value: auth.latestCorrespondence
            ? renderDate(auth.latestCorrespondence?.startDate)
            : "N/A",
          color: theme.palette.text.primary,
        },
        {
          label: "Approved End Date",
          value: auth.latestCorrespondence
            ? renderDate(auth.latestCorrespondence.endDate)
            : "N/A",
          color: theme.palette.text.primary,
        },
      ]);
    } else if (
      config.CONSTANTS.PROGRESS_AUTHORIZATION_STATUSES.includes(auth.status)
    ) {
      return renderDefaultViews(theme.palette.primary.main);
    } else if (config.CONSTANTS.AUTHORIZATION_STATUSES.DENIED === auth.status) {
      return renderDefaultViews(theme.palette.error.main);
    } else if (
      config.CONSTANTS.AUTHORIZATION_STATUSES.ACTION_REQUIRED === auth.status
    ) {
      return renderDefaultViews(theme.palette.warning.main);
    }

    return renderDefaultViews(defaultColor);
  };

  if (latestAuth == null) {
    return <Stack />;
  }

  return (
    <Stack direction="column" spacing={2} justifyContent="start">
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6">Prior Authorization</Typography>
            {enhanced && (
              <EnhancedServicesIcon sx={{ marginLeft: "0 !important" }} />
            )}
            <Chip
              label={_.capitalize(latestAuth.type?.toLowerCase())}
              size="small"
              sx={{ cursor: "pointer" }}
              color="default"
              variant="outlined"
            />
          </Stack>

          <LoadingButton
            onClick={() => {
              window.open(
                `${window.location.origin}/#${getAuthorizationRoute(
                  latestAuth
                )}?step=${
                  (
                    latestAuth.formDetails as {
                      currentStep: number;
                    } | null
                  )?.currentStep?.toString() ?? "1"
                }&id=${latestAuth.id}`,
                "_blank"
              );
            }}
            endIcon={<OpenInNewIcon />}
            data-cy="actionViewAuthorizationFromTreatment"
          >
            <u>View</u>
          </LoadingButton>
        </Stack>

        {enhanced && (
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {`${brandName} provides enhanced support and proactive interventions`}
          </Typography>
        )}
      </Stack>

      <Grid container>{renderGrid(latestAuth)}</Grid>

      {expiring && (
        <NewPriorAuthButton patientId={patient.id}>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{ maxWidth: 250 }}
            data-cy="actionRenewAuthorizationFromTreatment"
          >
            Renew Authorization
          </LoadingButton>
        </NewPriorAuthButton>
      )}

      {auths.length > 1 && (
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(
              `${window.location.origin}/#${generatePath(
                `${ROUTE_PATHS.AUTHORIZATION_LIST_FILTERED.path}?firstName=${patient?.firstName}&lastName=${patient?.lastName}`
              )}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          sx={{ maxWidth: "fit-content", padding: 0 }}
          data-cy="actionViewAuthorizationsListFromTreatment"
        >
          <u>{`View ${auths.length - 1} more authorizations `}</u>
        </LoadingButton>
      )}
    </Stack>
  );
};

const PatientTreatmentCardBvDeteail: React.FC<{
  bvs: (BenefitsVerificationBasic | null)[];
  patient: Patient;
  enhanced?: boolean;
}> = ({ bvs, patient, enhanced = false }) => {
  const theme = useTheme();
  const brandName = useSamaCareBrandName();

  const latestBv = _.compact(_.orderBy(bvs, "updatedAt", "desc"))[0];
  if (latestBv == null) {
    return <Stack />;
  }

  const renderGrid = (bv: BenefitsVerificationBasic) => {
    const defaultColor = theme.palette.grey.A400;
    const renderDefault = (statusColor: string) => {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase((bv.status ?? "Draft")?.toLowerCase()),
          color: statusColor,
        },
        {
          label: "Last Updated",
          value: renderDate(bv.updatedAt),
          color: defaultColor,
        },
        {
          label: "Created At",
          value: renderDate(bv.createdAt),
          color: defaultColor,
        },
        {
          label: "Payor",
          value: bv.insuranceCompanyName ?? "N/A",
          color: defaultColor,
        },
      ]);
    };

    switch (bv.status) {
      case BenefitsVerificationOutcomeStatus.Pending:
        return renderDefault(theme.palette.primary.main);
      case BenefitsVerificationOutcomeStatus.Success:
        return renderDefault(theme.palette.success.main);
      case BenefitsVerificationOutcomeStatus.Error:
        return renderDefault(theme.palette.error.main);
      default:
        return renderDefault(defaultColor);
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6">Drug Verification</Typography>
            {enhanced && (
              <EnhancedServicesIcon sx={{ marginLeft: "0 !important" }} />
            )}
          </Stack>
          <LoadingButton
            onClick={() => {
              if (latestBv?.status && !latestBv?.isDraft) {
                window.open(
                  `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${latestBv?.id}`,
                  "_blank"
                );
              } else {
                window.open(
                  `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/edit/${latestBv?.id}`,
                  "_blank"
                );
              }
            }}
            endIcon={<OpenInNewIcon />}
            data-cy="actionViewBenefitsVerificationFromTreatment"
          >
            <u>View</u>
          </LoadingButton>
        </Stack>

        {enhanced && (
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {`${brandName} investigates coverage, requirements and financials`}
          </Typography>
        )}
      </Stack>

      <Grid container>{renderGrid(latestBv)}</Grid>

      {bvs.length > 1 && (
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(
              `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}?quickFilters=${patient?.firstName},${patient?.lastName}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          sx={{ maxWidth: "fit-content", padding: 0 }}
          data-cy="actionViewBenefitsVerificationsListFromTreatment"
        >
          <u>{`View ${bvs.length - 1} more benefit verifications`}</u>
        </LoadingButton>
      )}
    </Stack>
  );
};

const PatientTreatmentCardEnrollmentDetail: React.FC<{
  enrollments: (NewEnrollmentObjectType | null)[];
  patient: Patient;
  enhanced: boolean;
}> = ({ enrollments, patient, enhanced = false }) => {
  const theme = useTheme();
  const brandName = useSamaCareBrandName();

  const latestEnrollment = _.compact(
    _.orderBy(enrollments, "updatedAt", "desc")
  )[0];

  if (latestEnrollment == null) {
    return <Stack />;
  }

  const renderGrid = (enrollment: NewEnrollmentObjectType) => {
    const defaultColor = theme.palette.grey.A400;
    const renderDefault = (statusColor: string) => {
      return serviceDetails([
        {
          label: "Status",
          value: _.startCase(enrollment.status?.toLowerCase()),
          color: statusColor,
        },
        {
          label: "Last Updated",
          value: renderDate(enrollment.updatedAt),
          color: defaultColor,
        },
        {
          label: "Created At",
          value: renderDate(enrollment.createdAt),
          color: defaultColor,
        },
        {
          label: "Payor",
          value: enrollment.InsuranceCompany?.name ?? "N/A",
          color: defaultColor,
        },
      ]);
    };

    switch (enrollment.status) {
      case NewEnrollmentStatusEnumType.Completed:
        return renderDefault(theme.palette.success.main);
      case NewEnrollmentStatusEnumType.Submitted:
        return renderDefault(theme.palette.primary.main);
      default:
        return renderDefault(defaultColor);
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6">Enrollment</Typography>
            {enhanced && (
              <EnhancedServicesIcon sx={{ marginLeft: "0 !important" }} />
            )}
          </Stack>
          <LoadingButton
            onClick={() => {
              window.open(
                `${window.location.origin}/#${ROUTE_PATHS.ENROLLMENTS_CREATE.path}/details/${latestEnrollment?.id}`,
                "_blank"
              );
            }}
            endIcon={<OpenInNewIcon />}
            data-cy="actionViewEnrollmentFromTreatment"
          >
            <u>View</u>
          </LoadingButton>
        </Stack>

        {enhanced && (
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
          >{`${brandName} can assist with the enrollment process`}</Typography>
        )}
      </Stack>

      <Grid container>{renderGrid(latestEnrollment)}</Grid>

      {enrollments.length > 1 && (
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(
              `${window.location.origin}/#${ROUTE_PATHS.ENROLLMENTS.path}?quickFilters=${patient?.firstName},${patient?.lastName}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon />}
          sx={{ maxWidth: "fit-content", padding: 0 }}
          data-cy="actionViewEnrollmentsListFromTreatment"
        >
          <u>{`View ${enrollments.length - 1} more enrollments`}</u>
        </LoadingButton>
      )}
    </Stack>
  );
};

const PatientTreatmentCardEmptyDetail: React.FC<{
  service: Treatment;
  patient: Patient;
}> = ({ service, patient }) => {
  const theme = useTheme();

  return (
    <Stack
      spacing={2}
      direction="column"
      alignItems="center"
      sx={{
        padding: "40px",
        bgcolor: alpha(theme.palette.primary.main, 0.04),
      }}
    >
      <Typography variant="body2" sx={{ color: theme.palette.grey[800] }}>
        No PAs have been started for this treatment
      </Typography>
      <NewPriorAuthButton patientId={patient.id}>
        <LoadingButton
          variant="outlined"
          startIcon={<Add />}
          sx={{
            whiteSpace: "nowrap",
            bgcolor: theme.palette.common.white,
          }}
        >
          New Auth
        </LoadingButton>
      </NewPriorAuthButton>
      {service.availableEnhancedServices &&
        service.availableEnhancedServices.length > 0 && (
          <Typography
            variant="caption"
            sx={{ maxWidth: "300px", textAlign: "center" }}
          >
            {`SamaCare provides enhanced support and proactive interventions for
            ${service.drugName} PAs`}
            <EnhancedServicesIcon />
          </Typography>
        )}
    </Stack>
  );
};

export const PatientTreatmentCard: React.FC<{ patient: Patient }> = ({
  patient,
}) => {
  const [showAddTreatmentForm, setShowAddTreatmentForm] = useState(
    (patient.allTreatments ?? []).length < 1
  );
  const brandName = useSamaCareBrandName();
  useEffect(() => {
    setShowAddTreatmentForm((patient.allTreatments ?? []).length < 1);
  }, [patient, setShowAddTreatmentForm]);

  const theme = useTheme();

  const mountingRef = useRef<boolean>(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mountingRef.current === false) {
      scrollRef?.current?.scrollIntoView();
    } else {
      mountingRef.current = false;
    }
  }, [patient?.allTreatments]);

  const [createBenefitsVerification] =
    useMutation<CreateBenefitsVerificationMutation>(
      CreateBenefitsVerificationMutationGql,
      {
        refetchQueries: [
          ALL_BENEFITS_VERIFICATIONS_QUERY_NAME,
          "getTreatmentsByPatient",
        ],
      }
    );

  const config = useConfig();

  const enableAddTreatmentEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableAddTreatment
  );
  return (
    <Stack spacing={2} ref={scrollRef}>
      {patient?.allTreatments?.map((service: Treatment) => {
        return (
          <Paper
            elevation={1}
            sx={{ padding: 0, marginY: 1 }}
            key={service.DrugOptionId}
          >
            <Stack direction="column" padding={4} spacing={3} minWidth={600}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="start"
              >
                <Stack direction="column" spacing={1}>
                  <Typography variant="h5">{service.drugName}</Typography>
                  {service.availableEnhancedServices &&
                    service.availableEnhancedServices?.length > 0 && (
                      <EnhancedServicesIndicatorFull />
                    )}
                </Stack>

                <NewPriorAuthButton
                  patientId={patient.id}
                  drugOptionId={String(service.DrugOptionId)}
                  enhancedServices={_.compact(
                    service.availableEnhancedServices
                  )}
                >
                  <IconButton>
                    <MoreHoriz />
                  </IconButton>
                </NewPriorAuthButton>
              </Stack>
              {_.includes(
                service.availableEnhancedServices,
                EnhancedServiecesEnum.BvEase
              ) &&
                service.bvs?.length === 0 && (
                  <StartEnhancedServiceCard
                    drugName={service.drugName!}
                    onClick={async () => {
                      const res = await createBenefitsVerification({
                        variables: {
                          patientId: Number(patient.id),
                          drugOptionId: Number(service.DrugOptionId),
                        },
                      });
                      openBvEditPage(
                        res?.data?.createBenefitsVerification?.id ?? undefined
                      );
                    }}
                    bodyText={`${brandName} can investigate coverage, requirements and financials for ${service.drugName}`}
                    buttonText="Check Drug Coverage"
                  />
                )}
              {_.includes(
                service.availableEnhancedServices,
                EnhancedServiecesEnum.EnrollSmart
              ) &&
                service.enrollments?.length === 0 && (
                  <StartEnhancedServiceCard
                    drugName={service.drugName!}
                    onClick={async () => {
                      window.open(
                        `${window.location.origin}/#${ROUTE_PATHS.ENROLLMENTS_CREATE.path}/program`,
                        "_blank"
                      );
                    }}
                    bodyText={`${brandName} can assist with the enrollment process for ${service.drugName}`}
                    buttonText="Start Patient Enrollment"
                  />
                )}

              {_.isEmpty(service.authorizations) &&
              _.isEmpty(service?.bvs) &&
              _.isEmpty(service?.enrollments) ? (
                <PatientTreatmentCardEmptyDetail
                  patient={patient}
                  service={service}
                />
              ) : (
                <>
                  {_.some(service.bvs, { isDraft: true }) && (
                    <Alert
                      sx={{
                        color: theme.palette.warning.main,
                      }}
                      severity="warning"
                    >
                      Complete Benefits Verification
                    </Alert>
                  )}
                  {service.authorizations &&
                    service.authorizations.length > 0 && (
                      <PatientTreatmentCardAuthDetail
                        auths={service.authorizations}
                        patient={patient}
                        enhanced={_.includes(
                          service.availableEnhancedServices,
                          EnhancedServiecesEnum.PaBoost
                        )}
                      />
                    )}

                  {service.bvs && service.bvs.length > 0 && (
                    <PatientTreatmentCardBvDeteail
                      bvs={service.bvs}
                      patient={patient}
                      enhanced={_.includes(
                        service.availableEnhancedServices,
                        EnhancedServiecesEnum.BvEase
                      )}
                    />
                  )}

                  {service.enrollments && service.enrollments.length > 0 && (
                    <PatientTreatmentCardEnrollmentDetail
                      enrollments={service.enrollments}
                      patient={patient}
                      enhanced={_.includes(
                        service.availableEnhancedServices,
                        EnhancedServiecesEnum.EnrollSmart
                      )}
                    />
                  )}
                </>
              )}
            </Stack>
          </Paper>
        );
      })}
      {enableAddTreatmentEnabled ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {showAddTreatmentForm && patient?.id != null ? (
            <AddTreatmentForm patientId={patient?.id} />
          ) : (
            <Button
              variant="outlined"
              onClick={() => setShowAddTreatmentForm(true)}
              sx={{ width: "fit-content" }}
            >
              Add Treatment
            </Button>
          )}
        </>
      ) : (
        <NewPriorAuthButton patientId={patient.id}>
          <LoadingButton
            variant="outlined"
            startIcon={<Add />}
            sx={{ whiteSpace: "nowrap" }}
          >
            New Auth
          </LoadingButton>
        </NewPriorAuthButton>
      )}
    </Stack>
  );
};
