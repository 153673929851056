import { Button } from "@samacare/design";

interface UnderlineButtonProps {
  disabled?: boolean;
  color?: "warning";
  onClick: () => void | Promise<void>;
}
export const UnderlineButton: React.FC<UnderlineButtonProps> = (props) => (
  <Button
    disabled={props.disabled}
    onClick={props.onClick}
    color={props.color}
    sx={{
      textDecoration: "underline",
    }}
  >
    {props.children}
  </Button>
);
