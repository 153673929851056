import gql from "graphql-tag";

import { useLazyQuery } from "@apollo/client";
import { Prescriber } from "@samacare/graphql";
import { useCallback, useState } from "react";

export const findPrescriberByNpiQuery = gql`
  query FindPrescriberByNpi($npi: String!) {
    findPrescriberByNpi(npi: $npi) {
      success
      message
      alreadyExists
      prescriberFromNpiRegistry {
        firstName
        lastName
        specialtyDescription
        licenseNumber
        specialtyCode
        NPI
        source
      }
    }
  }
`;

export const useFindPrescriberByNpi = () => {
  const [prescriber, setPrescriber] = useState<Prescriber | null>(null);
  const [alreadyExists, setAlreadyExists] = useState<boolean>(false);
  const [find, { data, loading }] = useLazyQuery(findPrescriberByNpiQuery, {
    onCompleted: (d) => {
      if (d.findPrescriberByNpi?.success) {
        setAlreadyExists(d.findPrescriberByNpi.alreadyExists);
        setPrescriber(d.findPrescriberByNpi.prescriberFromNpiRegistry);
      } else {
        setAlreadyExists(false);
        setPrescriber(null);
      }
    },
    fetchPolicy: "network-only",
  });

  const findPrescriberByNpi = useCallback(
    (npi: string) => {
      find({ variables: { npi } });
    },
    [find],
  );

  return {
    findPrescriberByNpi,
    prescriber,
    alreadyExists,
    findPrescriberSuccess: data?.findPrescriberByNpi?.success,
    errorMessage: data?.findPrescriberByNpi?.message,
    loading,
  };
};
