import gql from "graphql-tag";

export default gql`
  fragment portalInformation on Portal {
    id
    title
    loginUrl
    deepLink
    isTop
    isLegacy
    isHidden
    enableFollowUps
    hideCredentialsFromSupport
    requiresPhoneMfa
    isFallback
    formFieldConfigKey
    autoFillAndDetectKey
    hosts
    showAutoDetectNotification
  }
`;
