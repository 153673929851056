import * as React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useTheme, Theme } from "@samacare/design/core/styles";
import Box from "@samacare/design/core/Box";
import MuiDrawer from "@samacare/design/core/Drawer";
import Typography from "@samacare/design/core/Typography";
import Divider from "@samacare/design/core/Divider";
import ChevronLeftIcon from "@samacare/design/core/icons/ChevronLeft";
import ChevronRightIcon from "@samacare/design/core/icons/ChevronRight";
import ListItem from "@samacare/design/core/ListItem";
import ListItemButton from "@samacare/design/core/ListItemButton";
import ListItemIcon from "@samacare/design/core/ListItemIcon";
import LogoutIcon from "@samacare/design/core/icons/Logout";
import { withCurrentAccount } from "../../graphql/Account";
import { Account } from "@samacare/graphql";
import { logout } from "../../reducers/session";
import Avatar from "@samacare/design/core/Avatar";
import { useApplicationContext } from "../../providers/ApplicationProvider";
import { Fade, Slide } from "@samacare/design/core";
import { MainPageOptions } from "./MainPageOptions";
import { Settings } from "./Settings";
import { AdminFunctions } from "./AdminFunctions";
import { useConfig, useSamaCareBrandName } from "app/hooks";
import Tooltip from "@samacare/design/core/Tooltip/Tooltip";

import ROUTE_PATHS from "../../routes/ROUTE_PATHS";
import { useAuth } from "../../providers/AuthProvider";

const openDrawerWidth = 280;
const closedDrawerWidth = 80;

const openedMixin = (theme: Theme) => ({
  width: openDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  padding: `0px ${theme.spacing(2)} ${theme.spacing(2)}`,
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: closedDrawerWidth,
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  padding: `0px ${theme.spacing(2)} ${theme.spacing(2)}`,
});

export type BaseAppHeaderProps = React.ComponentProps<
  typeof BaseAppNavigationMenu
>;

const BaseAppNavigationMenu: React.VoidFunctionComponent<{
  account: Account;
}> = ({ account }) => {
  const theme = useTheme();
  const config = useConfig();
  const location = useLocation();
  const { onSignOut } = useAuth();
  const brandName = useSamaCareBrandName();

  const { isNavigationMenuOpen, setIsNavigationMenuOpen } =
    useApplicationContext();

  const [hoverOnLotus, setHoverOnLotus] = React.useState(false);
  const onHover = () => {
    setHoverOnLotus(true);
  };

  const onLeave = () => {
    setHoverOnLotus(false);
  };

  const isNonSamaUserInstitutionDropdownEnabled =
    !_.get(account, "isSamaUser") &&
    !_.isEmpty(_.get(account, "allowedInstitutionIds")) &&
    _.get(account, "allowedInstitutionIds.length") > 1;

  const isInternalUserToggleEnabled =
    config.NODE_ENV !== "production" &&
    (account?.email ?? "").toLowerCase().includes("@samacare.com");

  let accountName;
  let title;
  if (account != null) {
    accountName = `${account.firstName} ${account.lastName}`;
    if (account.isAdmin) {
      title = "Admin";
    } else {
      title = "User";
    }

    // We need to initialize pendo
    if (window.pendo) {
      if (window.pendo.isReady && window.pendo.isReady() === false) {
        window.pendo.initialize({
          visitor: {
            id: account.id,
            email: account.email!,
            full_name: `${account.firstName} ${account.lastName}`,
            isAdminUser: account.isAdmin,
          },
          account: {
            id: account?.institution?.id,
            name: `${account.firstName} ${account.lastName}`,
            InstitutionName: account?.institution?.name,
            PracticingSpecialty: account?.institution?.practicingSpecialty,
            TopPractice: account?.institution?.isTop,
          },
        });
      }
    }
  }

  if (
    _.includes(
      [ROUTE_PATHS.RESET_PASSWORD.path, ROUTE_PATHS.LOGIN.path],
      location.pathname
    )
  ) {
    return null;
  }

  return (
    <MuiDrawer
      sx={{
        width: openDrawerWidth,
        zIndex: 2,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(isNavigationMenuOpen && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!isNavigationMenuOpen && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        }),
      }}
      variant="permanent"
      open={isNavigationMenuOpen}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ...theme.mixins.toolbar,
          // necessary for content to be below app bar
          color: theme.palette.common.black,
          backgroundColor: theme.palette.common.white,
        }}
      >
        <ListItem
          sx={{
            borderRadius: theme.spacing(1),
            display: "block",
          }}
          disablePadding
          disableGutters
        >
          <Tooltip
            title={isNavigationMenuOpen ? "" : "Expand Menu"}
            placement="right"
          >
            <ListItemButton
              onClick={() =>
                setIsNavigationMenuOpen &&
                setIsNavigationMenuOpen(!isNavigationMenuOpen)
              }
              data-cy="controlShowNavigationMenu"
              sx={{
                justifyContent: isNavigationMenuOpen ? "initial" : "center",
                minHeight: 48,
                borderRadius: theme.spacing(1),
              }}
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
              disableGutters
            >
              <ListItemIcon
                sx={{
                  mx: 1.5,
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                {!isNavigationMenuOpen && hoverOnLotus ? (
                  <ChevronRightIcon
                    sx={{
                      height: theme.spacing(3),
                      width: theme.spacing(3),
                    }}
                  />
                ) : (
                  <img
                    style={{
                      height: theme.spacing(3),
                      width: theme.spacing(3),
                    }}
                    src="../../assets/lotus-icon.svg"
                  />
                )}
              </ListItemIcon>

              {isNavigationMenuOpen && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="h5">{brandName}</Typography>
                  <ChevronLeftIcon
                    sx={{
                      color: "text.secondary",
                      height: theme.spacing(3),
                      width: theme.spacing(3),
                      pr: 1,
                    }}
                  />
                </Box>
              )}
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </Box>
      {_.get(account, "isReadOnly") && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2">READ</Typography>
          <Typography variant="subtitle2">ONLY</Typography>
        </Box>
      )}
      <MainPageOptions account={account} />
      <Box>
        <Divider />
        <Slide in={isNavigationMenuOpen} unmountOnExit>
          <Fade in={isNavigationMenuOpen}>
            <div>
              <Typography
                variant="caption"
                sx={{
                  pl: 2,
                  transition: "ease-in-out .3s ",
                }}
              >
                Settings
              </Typography>
            </div>
          </Fade>
        </Slide>
      </Box>
      <Settings account={account} />
      {(isInternalUserToggleEnabled ||
        isNonSamaUserInstitutionDropdownEnabled ||
        account?.isSamaUser) && (
        <>
          <Box>
            <Divider />
            <Slide in={isNavigationMenuOpen} unmountOnExit>
              <Fade in={isNavigationMenuOpen}>
                <div>
                  <Typography
                    variant="caption"
                    sx={{
                      pl: 2,
                      transition: "ease-in-out .3s ",
                    }}
                  >
                    Admin Functions
                  </Typography>
                </div>
              </Fade>
            </Slide>
          </Box>
          <AdminFunctions
            isInternalUserToggleEnabled={isInternalUserToggleEnabled}
            isInstitutionDropdownEnabled={
              isNonSamaUserInstitutionDropdownEnabled || account.isSamaUser
            }
            account={account}
          />
        </>
      )}

      <Box
        sx={{
          display: "flex",
          height: "100%",
          alignItems: isNavigationMenuOpen ? "flex-end" : "center",
          flexDirection: isNavigationMenuOpen ? "row" : "column",
          justifyContent: isNavigationMenuOpen ? "space-between" : "flex-end",
        }}
      >
        {accountName && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                textTransform: "uppercase",
                bgcolor: "grey",
                color: theme.palette.common.white,
                mr: isNavigationMenuOpen ? 1 : "auto",
              }}
              alt={accountName}
            >
              {accountName
                .split(" ")
                .reduce<string>(
                  (acc, str) => (acc.length > 2 ? acc : acc + str[0]),
                  ""
                )}
            </Avatar>
            {isNavigationMenuOpen && (
              <Typography variant="subtitle2" color="text.secondary">
                {title}
              </Typography>
            )}
          </Box>
        )}
        <Tooltip title="Logout" placement="right">
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 1,
              justifyContent: "center",
              flexGrow: 0,
              borderRadius: theme.spacing(1),
            }}
          >
            <ListItemIcon
              sx={{
                mr: isNavigationMenuOpen ? 0 : "auto",
                mx: 1.5,
                minWidth: 0,
                justifyContent: "center",
              }}
              data-cy="actionLogout"
              onClick={
                window.CONFIG.COGNITO_ENABLED ? async () => onSignOut() : logout
              }
            >
              <LogoutIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>
      </Box>
    </MuiDrawer>
  );
};

/**
 * @deprecated Use a MUI component instead
 */
export default withCurrentAccount(BaseAppNavigationMenu);
