import { useTheme, alpha } from "@samacare/design/core/styles";
import { EnhancedServicesIcon, PaBvTriggerCard } from "@samacare/component";
import {
  CardContent,
  colors,
  LoadingButton,
  Stack,
  Typography,
} from "@samacare/design";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";

export const StartEnhancedServiceCard = ({
  drugName,
  onClick,
  buttonText,
  bodyText,
}: {
  drugName: string;
  onClick: () => void;
  buttonText: string;
  bodyText: string;
}) => {
  const theme = useTheme();

  return (
    <PaBvTriggerCard
      drugName={drugName ?? ""}
      displayHeader={false}
      styleOverrides={{
        boxShadow: "none",
      }}
    >
      <CardContent
        sx={{
          backgroundColor: alpha(colors.C500, 0.08),
          maxWidth: "600px",
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingButton
            data-cy="actionCreateBenefitsVerificationFromPa"
            size="small"
            variant="contained"
            onClick={onClick}
            sx={{
              minWidth: "fit-content",
              marginTop: ({ spacing }) => spacing(1),
              background: theme.palette.secondary.dark,
              "&:hover": {
                backgroundColor: alpha(theme.palette.secondary.dark, 0.8),
                boxShadow: "none",
              },
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "22px",
            }}
            endIcon={<OpenInNewIcon />}
          >
            {buttonText}
          </LoadingButton>
          <Typography variant="body2" color="textSecondary">
            {bodyText}
            <EnhancedServicesIcon sx={{ fontSize: 18 }} />
          </Typography>
        </Stack>
      </CardContent>
    </PaBvTriggerCard>
  );
};
