import { Location, Query, QueryLocationsArgs } from "@samacare/graphql";
import { ApolloQueryResult, gql, useQuery } from "@apollo/client";
import { locationInfo } from "../graphql/fragments/locationInfo";

const getLocationsQuery = gql`
  query Locations($where: LocationWhere) {
    locations(where: $where) {
      ...locationInfo
    }
  }
  ${locationInfo}
`;

export const useLocations = (): {
  locations: Location[];
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<Query>>;
} => {
  const { data, loading, refetch } = useQuery<Query, QueryLocationsArgs>(
    getLocationsQuery,
    {
      // tried adding this as a refetch query on a mutation but it didn't always work
      fetchPolicy: "network-only",
    },
  );

  return { locations: data?.locations ?? [], loading, refetch };
};
