import { useEffect, useState } from "react";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { NpiCaptureSource, Location } from "@samacare/graphql";
import { SamaDialog } from "@samacare/component";
import { useLocation } from "@samacare/hooks-data";
import { Stack, SxProps } from "@samacare/design";
import { LocationEditPane } from "./LocationEditPane";
import { SearchLocationByNpiPane } from "./SearchLocationByNpiPane";
import { SearchErrorPane } from "../SearchByNpiPane";
import { DuplicateNpiPane } from "../DuplicateNpiPane/DuplicateNpiPane";

const enum DialogPane {
  SearchByNpi = "SearchByNpi",
  SearchError = "SearchError",
  DuplicateNpi = "DuplicateNpi",
  Edit = "Edit",
}

export interface UpsertLocationDialogProps {
  open: boolean;
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
  LocationId: number | string | undefined | null;
  onClose: (location?: Location | null) => void;
  onAlert?: (message: string) => void;
  tinRequired?: boolean;
  sx?: SxProps;
  isWebExtension?: boolean;
}
export const UpsertLocationDialog: React.FC<UpsertLocationDialogProps> = (
  props,
) => {
  const [pane, setPane] = useState(DialogPane.Edit);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(
    null,
  );
  const [unfoundNpi, setUnfoundNpi] = useState<string | null>(null);
  const [location, setLocation] = useState<Location | null>(null);

  const { location: existingLocation } = useLocation(props.LocationId);
  useEffect(() => {
    if (existingLocation) {
      setLocation(existingLocation);
    }
  }, [existingLocation]);

  useEffect(() => {
    if (props.open) {
      if (props.LocationId) {
        setPane(DialogPane.Edit);
        if (existingLocation && existingLocation.id === props.LocationId) {
          setLocation(existingLocation);
        }
      } else {
        setLocation({} as Location);
        setPane(DialogPane.SearchByNpi);
      }
    }
  }, [props.LocationId, props.open, existingLocation]);

  const handleFound = (p: Location, alreadyExists: boolean) => {
    setUnfoundNpi(null);
    setLocation({ ...p, source: NpiCaptureSource.Api });
    setPane(alreadyExists ? DialogPane.DuplicateNpi : DialogPane.Edit);
  };

  const handleSearchError = (errorMessage: string, NPI: string) => {
    setSearchErrorMessage(errorMessage);
    setUnfoundNpi(NPI);
    setPane(DialogPane.SearchError);
  };

  const handleManualAdd = () => {
    setLocation({
      source: NpiCaptureSource.Manual,
      NPI: unfoundNpi,
    } as Location);
    setPane(DialogPane.Edit);
  };

  const handleBack = () => {
    setPane(DialogPane.SearchByNpi);
  };

  const handleClose = (loc?: Location | null) => {
    props.onClose(loc);
    setLocation(null);
  };

  return (
    <ApolloProvider client={props.apolloClient as ApolloClient<object>}>
      <SamaDialog
        open={props.open}
        onClose={() => handleClose()}
        color={pane === DialogPane.DuplicateNpi ? "warning" : undefined}
      >
        <Stack sx={{ minHeight: "625px" }}>
          {pane === DialogPane.SearchByNpi && (
            <SearchLocationByNpiPane
              isWebExtension={props.isWebExtension}
              onClose={handleClose}
              onFound={handleFound}
              onError={handleSearchError}
            />
          )}
          {pane === DialogPane.SearchError && (
            <SearchErrorPane
              objectName="location"
              onClose={handleClose}
              onBack={() => setPane(DialogPane.SearchByNpi)}
              onAddManually={handleManualAdd}
              errorMessage={searchErrorMessage}
            />
          )}
          {pane === DialogPane.DuplicateNpi && (
            <DuplicateNpiPane
              objectName="location"
              dupedName={location?.facilityName ?? ""}
              onClose={handleClose}
              onBack={() => setPane(DialogPane.SearchByNpi)}
              onProceed={() => setPane(DialogPane.Edit)}
            />
          )}
          {pane === DialogPane.Edit && (
            <LocationEditPane
              isWebExtension={props.isWebExtension}
              location={location}
              onBack={handleBack}
              onClose={handleClose}
              onAlert={props.onAlert}
              tinRequired={props.tinRequired}
            />
          )}
        </Stack>
      </SamaDialog>
    </ApolloProvider>
  );
};
