import { SearchResultItem } from "../types";
import { formatDrug } from "../gridUtils";
import { Box } from "@samacare/design";
import { EnhancedServicesIcon } from "@samacare/component";

export const GridCellDrug: React.FC<{ drug: SearchResultItem["drug"] }> = ({
  drug,
}) => {
  const displayText = formatDrug(drug);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {displayText}
      </span>
      <EnhancedServicesIcon sx={{ fontSize: 20 }} />
    </Box>
  );
};
