import { Alert } from "@samacare/design/core";
import { useTheme, alpha } from "@samacare/design/core/styles";

export const LiteBvNotificationAlert = () => {
  const theme = useTheme();

  return (
    <Alert
      severity="info"
      sx={{
        width: "600px",
        background: alpha(theme.palette.secondary.dark, 0.08),
        "& .MuiAlert-icon": {
          color: theme.palette.secondary.dark,
        },
      }}
    >
      This check includes patient coverage at the health plan level only.
    </Alert>
  );
};
