import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@samacare/design";
import {
  FormProvider,
  PatientBlock,
  useForm,
  EdiInsuranceBlock,
  ServiceCodeDataWrapper,
  PaProviderBlock,
  PaLocationBlock,
} from "@samacare/form";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { PortalAuth } from "../../../types/PortalAuth";
import { useCurrentAccount } from "../../../graphql/Account";
import { useConfig } from "@@hooks/config";
import { doCreate, mapHcpcsCodesToAuthConfig } from "../../../util/authUtils";
import { useAlert } from "react-alert";
import { LeftRightCenterAll } from "@samacare/component";
import {
  AuthorizationStatusEnum,
  HcpcsCode,
  PaOriginType,
  PatientSourceType,
  SubscriberRelationshipCode,
} from "@samacare/graphql";
import { PortalContext } from "app/contexts/portalContext";
import { useFeatureFlag } from "@@hooks/useFeatureFlag";
import { useAuthorization } from "@@hooks/useAuthorization";
import { launchPortalAuth } from "app/util/bgScriptMsgUtils";
import {
  useCreatePatient,
  useRemovePatientOnAuth,
  useSetPatientOnAuth,
  useWriteAuthorizationResultsToPatient,
} from "app/graphql/Patient";
import {
  useUpdateAuthorization,
  useCreateAuthorization,
} from "app/graphql/Authorization";
import { useContext, useCallback, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { ExtensionAlertBanner } from "@@components/ExtensionAlertBanner";
import { HeaderTitle } from "../../../constants/HeaderTitle";
import { WebExtensionPubSub } from "../../../services/WebExtensionPubSub";
import { CYPRESS_MOCK_EXTENSION } from "../../../constants/AuthorizationMock";

type FormValues = {
  PatientId?: string;
  patient: {
    firstName: string;
    lastName: string;
    dob: string;
    primaryInsurance: {
      memberId: string;
      InsuranceCompanyId: string;
      relationshipToSubscriber?: SubscriberRelationshipCode;
    };
  };
  InstitutionId: string;
  PrescriberId: string;
  LocationId: string;
  insuranceCompanyName: string;
  services: HcpcsCode[];
  prescriber: {
    id: string;
    DEA: string;
    NPI: string;
    TIN: string;
    firstName: string;
    lastName: string;
    label: string;
    licenseNumber: string;
    PrescriberId: string;
    specialtyCode: string;
    specialtyDescription: string;
  };
  location: {
    facilityName: string;
    nickname: string;
    NPI: string;
    taxId: string;
    fax: string;
    address: string;
    city: string;
    state: string;
    zip: string;
  };
};

const defaultFormValues: FormValues = {
  PatientId: undefined,
  patient: {
    firstName: "",
    lastName: "",
    dob: "",
    primaryInsurance: {
      memberId: "",
      InsuranceCompanyId: "",
      relationshipToSubscriber: undefined,
    },
  },
  InstitutionId: "",
  PrescriberId: "",
  LocationId: "",
  insuranceCompanyName: "",
  services: [],
  prescriber: {
    id: "",
    DEA: "",
    NPI: "",
    TIN: "",
    firstName: "",
    lastName: "",
    label: "",
    licenseNumber: "",
    PrescriberId: "",
    specialtyCode: "",
    specialtyDescription: "",
  },
  location: {
    facilityName: "",
    nickname: "",
    NPI: "",
    taxId: "",
    fax: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  },
};

export const PortalAuthorizationCreate = () => {
  const { authorization } = useAuthorization();
  const config = useConfig();
  const [currentAccount] = useCurrentAccount();
  const apolloClient = useApolloClient();
  const [cypressMockExtension] = useState<string | null>(() =>
    localStorage.getItem(CYPRESS_MOCK_EXTENSION)
  );

  const methods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  const alert = useAlert();
  const [createAuthorization] = useCreateAuthorization();
  const [createPatient] = useCreatePatient();
  const [writeAuthResToPatient] = useWriteAuthorizationResultsToPatient();

  const emailUserOnChange = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EmailOnAuthStatusChange
  );

  const { portalData } = useContext(PortalContext);
  const history = useHistory();

  const [updateAuthorization] = useUpdateAuthorization();
  const [setPatient] = useSetPatientOnAuth();
  const [removePatient] = useRemovePatientOnAuth();

  const handleSubmit = useCallback(
    _.debounce(
      methods.handleSubmit(async (formData) => {
        let finalPatientId = formData.PatientId;
        if (!currentAccount?.InstitutionId)
          return alert.error("InstitutionId is missing");

        const portalAuth: PortalAuth = {
          InstitutionId: currentAccount?.InstitutionId,
          HCPCSCodes: formData.services,
        };

        if (!finalPatientId) {
          const { data } = await createPatient({
            variables: {
              object: {
                firstName: formData.patient.firstName,
                lastName: formData.patient.lastName,
                dob: moment(formData.patient.dob).format("YYYY-MM-DD"),
                InstitutionId: currentAccount?.InstitutionId,
                source: PatientSourceType.ManualWeb,
              },
            },
          });
          finalPatientId = data?.createPatient?.id;
        }
        if (finalPatientId == null) return;

        let currentAuthId = authorization?.id;

        if (!currentAuthId) {
          const resDraftedPA = await doCreate({
            type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
            paOrigin: PaOriginType.WebApp,
            portal: portalData?.portal,
            onClickConfigOverride: portalData?.onClickConfigOverride,
            otherDefaultFields: {
              status: AuthorizationStatusEnum.Draft,
              HCPCSCodes: portalAuth.HCPCSCodes,
            },
            account: currentAccount,
            config,
            patientId: finalPatientId,
            createAuthorization,
            alert,
            portalAuth,
            emailUserOnChange: !!emailUserOnChange,
          });

          if (!resDraftedPA?.id || !finalPatientId)
            return alert.error("Failed to create authorization");

          currentAuthId = resDraftedPA.id;
        }

        if (authorization?.id) {
          await removePatient({
            variables: {
              authorizationId: +currentAuthId,
            },
          });

          await setPatient({
            variables: {
              patientId: +finalPatientId,
              authorizationId: +currentAuthId,
            },
          });
        }

        const hcpcsCodesFormatted = mapHcpcsCodesToAuthConfig(
          config,
          portalAuth.HCPCSCodes
        );

        const authBundle = await updateAuthorization({
          variables: {
            id: parseInt(currentAuthId),
            patch: {
              HCPCSCodes: portalAuth.HCPCSCodes.map((hcpcsCode) => ({
                code: hcpcsCode.code,
                drugName: hcpcsCode.drugName,
              })),
              InsuranceCompanyId:
                +formData.patient.primaryInsurance.InsuranceCompanyId,
              formDetails: {
                locationId: formData.LocationId,
                prescriberId: formData.PrescriberId,
              },
              config: {
                ...hcpcsCodesFormatted,
                [config.DEFAULT_FIELDS.PATIENT_MEMBER_ID.key]:
                  formData.patient.primaryInsurance.memberId,
                [config.DEFAULT_FIELDS.INSURANCE_COMPANY.key]:
                  formData.insuranceCompanyName,
                [config.DEFAULT_FIELDS.SUBSCRIBER_RELATIONSHIP.key]:
                  formData.patient.primaryInsurance.relationshipToSubscriber,
                // Prescriber
                [config.DEFAULT_FIELDS.PRESCRIBER_SPECIALTY.key]:
                  formData.prescriber.specialtyDescription,
                [config.DEFAULT_FIELDS.PRESCRIBER_NPI.key]:
                  formData.prescriber.NPI,
                [config.DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key]:
                  formData.prescriber.firstName,
                [config.DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key]:
                  formData.prescriber.lastName,
                [config.DEFAULT_FIELDS.PRESCRIBER_LICENSE_NUMBER.key]:
                  formData.prescriber.licenseNumber,
                [config.DEFAULT_FIELDS.PRESCRIBER_DEA_NUMBER.key]:
                  formData.prescriber.DEA,
                [config.DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE.key]:
                  formData.prescriber.specialtyCode,
                [config.DEFAULT_FIELDS.PRESCRIBER_LABEL.key]:
                  formData.prescriber.label,
                // Location
                [config.DEFAULT_FIELDS.FACILITY_NAME.key]:
                  formData.location?.facilityName,
                [config.DEFAULT_FIELDS.LOCATION_NAME.key]:
                  formData.location?.nickname,
                [config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]:
                  formData.location?.NPI,
                [config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]:
                  formData.location?.taxId,
                [config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_FAX.key]:
                  formData.location?.fax,
                [config.DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key]:
                  formData.location?.address,
                [config.DEFAULT_FIELDS.PRESCRIBER_CITY.key]:
                  formData.location?.city,
                [config.DEFAULT_FIELDS.PRESCRIBER_STATE.key]:
                  formData.location?.state,
                [config.DEFAULT_FIELDS.PRESCRIBER_ZIP.key]:
                  formData.location?.zip,
              },
            },
          },
        });

        const draftedPA = authBundle?.data?.updateAuthorizationById;

        await writeAuthResToPatient({
          variables: {
            id: +currentAuthId,
            patientId: +finalPatientId,
          },
        });

        if (
          ((authorization && !authorization.portal?.isLegacy) ||
            (portalData && !portalData.portal?.isLegacy)) &&
          draftedPA
        ) {
          if (!cypressMockExtension) {
            await launchPortalAuth({
              authorization: draftedPA,
              alert,
              backupUrl: portalData?.portal?.loginUrl,
            });
          } else {
            history.push("/");
          }
        }
      }),
      500
    ),
    [
      methods,
      currentAccount,
      createAuthorization,
      updateAuthorization,
      authorization,
    ]
  );

  useEffect(() => {
    if (authorization) {
      const newValues = {
        PatientId: authorization.patient?.id,
        patient: {
          firstName: authorization.patient?.firstName || "",
          lastName: authorization.patient?.lastName || "",
          dob: authorization.patient?.dob
            ? moment(authorization.patient.dob).format("YYYY-MM-DD")
            : "",
          primaryInsurance: {
            memberId: authorization.patient?.primaryInsurance?.memberId || "",
            InsuranceCompanyId:
              authorization.InsuranceCompanyId?.toString() || "",
            relationshipToSubscriber:
              authorization.config?.[
                config.DEFAULT_FIELDS.SUBSCRIBER_RELATIONSHIP.key
              ] || SubscriberRelationshipCode.Self,
          },
        },
        InstitutionId: authorization.InstitutionId?.toString() || "",
        PrescriberId: authorization.PrescriberId || "",
        LocationId: authorization.LocationId || "",
        insuranceCompanyName: authorization.insuranceCompany?.name || "",
        services: authorization.HCPCSCodes || [],
        prescriber: {
          id: authorization.PrescriberId || "",
          DEA:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_DEA_NUMBER.key
            ] || "",
          NPI:
            authorization.config?.[config.DEFAULT_FIELDS.PRESCRIBER_NPI.key] ||
            "",
          firstName:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key
            ] || "",
          lastName:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key
            ] || "",
          label:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_LABEL.key
            ] || "",
          licenseNumber:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_LICENSE_NUMBER.key
            ] || "",
          PrescriberId: authorization.PrescriberId || "",
          specialtyCode:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE.key
            ] || "",
          specialtyDescription:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_SPECIALTY.key
            ] || "",
        },
        location: {
          facilityName:
            authorization.config?.[config.DEFAULT_FIELDS.FACILITY_NAME.key] ||
            "",
          nickname:
            authorization.config?.[config.DEFAULT_FIELDS.LOCATION_NAME.key] ||
            "",
          NPI:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key
            ] || "",
          taxId:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key
            ] || "",
          fax:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_FAX.key
            ] || "",
          address:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key
            ] || "",
          city:
            authorization.config?.[config.DEFAULT_FIELDS.PRESCRIBER_CITY.key] ||
            "",
          state:
            authorization.config?.[
              config.DEFAULT_FIELDS.PRESCRIBER_STATE.key
            ] || "",
          zip:
            authorization.config?.[config.DEFAULT_FIELDS.PRESCRIBER_ZIP.key] ||
            "",
        },
      };

      methods.reset(newValues);
    }
  }, [authorization?.id, methods, config]);

  useEffect(() => {
    const webExtensionPubSub = new WebExtensionPubSub();
    webExtensionPubSub.addListener((msg) => {
      switch (msg.data?.type) {
        case "PortalLaunchSuccess":
          window.location.reload();
          history.push("/");
          break;
        default:
          break;
      }
    });
    return () => webExtensionPubSub.removeListeners();
  }, [history]);

  return (
    <Stack>
      <Box p={2} pb={4}>
        <Typography variant="h5" sx={{ pb: 1 }}>
          Portal Prior Authorization
        </Typography>
        {!portalData?.portal?.isLegacy && portalData?.portal?.title && (
          <ExtensionAlertBanner
            isDownloadExtensionBanner={false}
            supportsAutofill={false}
            portalName={portalData?.portal?.title}
          />
        )}
        <FormProvider {...methods}>
          <EnrollmentSection title="Patient">
            <PatientBlock required />
          </EnrollmentSection>
          <EnrollmentSection title="Insurance">
            <EdiInsuranceBlock required />
          </EnrollmentSection>
          <EnrollmentSection title={HeaderTitle.ServicingProvider}>
            <PaProviderBlock
              required
              label="Servicing Provider"
              onAlert={(message) => alert.error(message)}
            />
          </EnrollmentSection>
          <EnrollmentSection title={HeaderTitle.ServicingLocation}>
            <PaLocationBlock
              required
              label="Servicing Location"
              onAlert={(message) => alert.error(message)}
              tinRequired
            />
          </EnrollmentSection>

          <EnrollmentSection title="Service">
            <ServiceCodeDataWrapper
              name="services"
              apolloClient={apolloClient}
              disabled={false}
            />
          </EnrollmentSection>
          <LeftRightCenterAll sx={{ maxWidth: 600 }}>
            <Button
              data-cy="actionPortalSubmit"
              variant="contained"
              onClick={handleSubmit}
            >
              Continue To Portal
            </Button>
          </LeftRightCenterAll>
        </FormProvider>
      </Box>
    </Stack>
  );
};
