import _ from "lodash";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import styled from "styled-components";
import { ConfirmationModalContent } from "../../ConfirmationModalContent";
import Tabs from "@samacare/design/core/Tabs";
import Tab from "@samacare/design/core/Tab";
import HistoryTab from "./HistoryTab";
import LoadingButton from "../../LoadingButton";
import AssociateCorrespondenceModal from "../../AssociateCorrespondenceModal";
import FollowUpModal from "../../FollowUpModal";

import { Path } from "history";
import { createReviewNoteMutation } from "../../../graphql/Note";
import hasActionableFollowUp from "../../../util/hasActionableFollowUp";
import { Modal } from "../../Modal";
import {
  AppealLetterNote,
  displayLetterOfMedicaNecessityForAuth,
} from "../LomnSection/LomnShared";
import { Button, Box } from "@samacare/design";
import ROUTE_PATHS from "../../../routes/ROUTE_PATHS";
import {
  Authorization,
  Mutation,
  MutationCreateReviewNoteArgs,
} from "@samacare/graphql";
import { useConfig } from "@@hooks/config";
import { useCurrentAccount } from "../../../graphql/Account";
import { useMutation } from "@apollo/client";
import FilesTab from "./FilesTab";
import { AttachFileBtn } from "./AttachFileBtn";
import { useAuthorizationLogs } from "@@hooks/useAuthorizationLogs";
import { SponsoredBvCreationForSummaryPage } from "../../../BenefitsVerifications/SponsoredBvCreationSection";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  justify-content: space-between;
`;

const RestrictedNote = styled.div`
  color: ${(props) => props.theme.purple};
  font-weight: 700;
  font-size: 18px;
  margin: 20px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ResponseSection: React.VFC<{
  authorization: Authorization;
  restrictCorrespondences: boolean;
  goToLocation: (path: Path) => void;
}> = ({ authorization, restrictCorrespondences, goToLocation }) => {
  const [showReviewAssociationModal, setShowReviewAssociationModal] = useState(
    authorization.requiresAssociationReview
  );
  const [showPayerQuestionsModal, setShowPayerQuestionsModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const HISTORY = "History";
  const FILES = "Files";
  const TAB_LABELS = [FILES, HISTORY];
  const defaultTab = FILES;

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const { CONSTANTS } = useConfig();
  const [account] = useCurrentAccount();
  const alert = useAlert();
  const { authorizationLogs } = useAuthorizationLogs(
    parseInt(authorization.id)
  );

  const {
    FOLLOW_UP_TYPES,
    AUTHORIZATION_TYPES,
    AUTHORIZATION_STATUSES_DISALLOWED_FOR_USER_UPLOADS,
  } = CONSTANTS;

  const [createReviewNote] = useMutation<
    Mutation,
    MutationCreateReviewNoteArgs
  >(createReviewNoteMutation);

  useEffect(() => {
    if (authorization.displayReviewWarning && !restrictCorrespondences) {
      const handleCreateReviewNote = async () => {
        await createReviewNote({
          variables: { authorizationId: parseInt(authorization.id) },
        });
      };

      handleCreateReviewNote().catch(() =>
        alert.info("Unable to create review note")
      );
    }

    setShowPayerQuestionsModal(
      hasActionableFollowUp(authorization) &&
        _.get(authorization, "followUp.type") ===
          FOLLOW_UP_TYPES.GENERAL_QUESTIONS
    );
  }, []);

  const isFormAuth = authorization.type === AUTHORIZATION_TYPES.FORM.key;

  const isSamaUser = _.get(account, "isSamaUser", false);
  return (
    <FormContainer>
      {displayLetterOfMedicaNecessityForAuth(authorization) && (
        <AppealLetterNote authorization={authorization} />
      )}
      {isFormAuth && <SponsoredBvCreationForSummaryPage auth={authorization} />}

      <div>
        {restrictCorrespondences && !isSamaUser && (
          <RestrictedNote>
            Viewing submissions and payer responses has been restricted for this
            user.
          </RestrictedNote>
        )}
        {!restrictCorrespondences && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                marginBottom: "20px",
              }}
            >
              <Tabs
                onChange={(e, value: string) => {
                  e.stopPropagation();
                  setSelectedTab(value);
                }}
                value={selectedTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {TAB_LABELS.map((label) => (
                  <Tab
                    label={label}
                    value={label}
                    key={`summaryTab_${label}`}
                  />
                ))}
              </Tabs>
              {!_.includes(
                AUTHORIZATION_STATUSES_DISALLOWED_FOR_USER_UPLOADS,
                authorization.status
              ) && <AttachFileBtn authorization={authorization} />}
            </Box>
            {selectedTab === HISTORY && (
              <HistoryTab
                authorization={authorization}
                authorizationLogs={authorizationLogs}
                authForm
              />
            )}
            {selectedTab === FILES && (
              <FilesTab
                authorization={authorization}
                restrictCorrespondences={restrictCorrespondences}
              />
            )}
          </>
        )}
      </div>
      <Box display="flex">
        <Button
          variant="outlined"
          onClick={() => goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path)}
        >
          Back
        </Button>
      </Box>

      {showConfirmationModal && (
        <Modal open>
          <ConfirmationModalContent
            messages={[
              "Thank you for making our system smarter!",
              "We will update this shortly from our end.",
            ]}
          />

          <ButtonContainer>
            <LoadingButton
              style={{ padding: "10px 25px" }}
              key="disassociateCorrespondence"
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              Done
            </LoadingButton>
          </ButtonContainer>
        </Modal>
      )}

      {showReviewAssociationModal && !restrictCorrespondences && (
        <AssociateCorrespondenceModal
          allowDisassociation
          allowChanges
          authorization={authorization}
          closeModal={() => {
            setShowReviewAssociationModal(false);
          }}
        />
      )}
      {showPayerQuestionsModal && (
        <FollowUpModal
          redirect={() => {
            setShowPayerQuestionsModal(false);
          }}
          followUpId={authorization?.followUp?.id}
          correspondence={authorization.latestCorrespondence}
          onClose={() => {
            setShowPayerQuestionsModal(false);
          }}
        />
      )}
    </FormContainer>
  );
};

export default ResponseSection;
