import { useEffect } from "react";
import { capitalCase, noCase } from "change-case";
import { Box, DialogContent, Stack, Typography } from "@samacare/design";
import {
  DialogFooterWithButtons,
  DialogHeader,
  TopBottomCenterAll,
} from "@samacare/component";
import { FormProvider, TextField, useForm } from "@samacare/form2";

interface NpiSchema {
  npi: string;
}

export type SearchByNpiPaneProps = {
  objectName: string;
  errorMessage?: string;
  onFind: (npi: string) => void;
  onError: (error: string, NPI: string) => void;
  onClose: () => void;
  isWebExtension?: boolean;
};
export const SearchByNpiPane: React.FC<SearchByNpiPaneProps> = (props) => {
  const { errorMessage, onError, isWebExtension = false } = props;
  const methods = useForm<NpiSchema>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      npi: "",
    },
  });
  const NPI = methods.watch("npi");

  const find = ({ npi }: NpiSchema) => {
    props.onFind(npi);
  };

  useEffect(() => {
    if (errorMessage) {
      onError(errorMessage, NPI);
    }
  }, [errorMessage, NPI, onError]);

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <DialogHeader
        title={`Add ${capitalCase(props.objectName)}`}
        onClose={props.onClose}
      />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(find)}
          style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        >
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            <TopBottomCenterAll
              sx={{
                gap: 2,
                paddingBottom: 2,
                paddingX: 6,
                flexGrow: 1,
              }}
            >
              <Typography
                variant="body"
                fontWeight="600"
                sx={{ textAlign: "center" }}
              >
                {`Search for ${capitalCase(props.objectName)} by NPI`}
              </Typography>
              <Box sx={{ height: "64px", width: "100%" }}>
                <TextField
                  fullWidth
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={!isWebExtension}
                  label="NPI"
                  name="npi"
                  required
                  rules={{
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Npi must be exactly 10 digits",
                    },
                  }}
                />
              </Box>

              <Typography variant="caption" sx={{ textAlign: "center" }}>
                {`Key ${noCase(
                  props.objectName,
                )} information, such as NPI number, is sourced
                directly from National Provider Identifier (NPI) database.`}
              </Typography>
            </TopBottomCenterAll>
          </DialogContent>
          <DialogFooterWithButtons
            cancelText="Close"
            onCancel={props.onClose}
            confirmText="Search"
            onConfirm={methods.handleSubmit(find)}
          />
        </form>
      </FormProvider>
    </Stack>
  );
};
