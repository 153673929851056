import React, { FunctionComponent, useState } from "react";
import { UnderlinedButton } from "@@components/SubmitButtons";
import { Flex } from "@@ui-kit";
import { Typography } from "@samacare/design";
import styled from "styled-components";
import { Account, Authorization } from "@samacare/graphql";
import FormList from "../SelectFormModal/FormList";
import MissingFormModal from "./MissingFormModal";

interface FaxFormProps {
  onSubmit: ({ formId }: { formId: string }) => void;
  account: Account;
  authorization: Authorization;
  authorizationId: string;
  isMultiPartyAuth: boolean;
  benefitType?: string;
  NDC?: string;
  jCode?: string;
  insuranceType?: string;
  state?: string;
}

const Step1Container = styled(Flex)`
  flex-direction: column;
  padding-top: 32px;
  max-width: 1280px;
  justify-content: center;
  min-height: 400px;
`;

const StyledUnderlinedButton = styled(UnderlinedButton)`
  width: auto;
  align-self: center;
`;

const FaxForm: FunctionComponent<FaxFormProps> = ({
  authorization,
  insuranceType,
  jCode,
  onSubmit,
  state,
  NDC,
  benefitType,
  isMultiPartyAuth,
}) => {
  const [formId, setFormId] = useState<number | undefined | null>();
  const [showMemo, setShowMemo] = useState<boolean>(false);

  return (
    <div style={{ marginTop: "16px" }}>
      <div style={{ marginBottom: "24px" }}>
        <Typography variant="h6">Select Form</Typography>
        <Typography variant="body2">
          Select the fax form for your Prior Authorization Request.
        </Typography>
        <Step1Container
          style={{
            minHeight: "80vh",
            width: "100%",
          }}
        >
          <FormList
            insuranceType={insuranceType}
            authorizationId={authorization.id}
            insuranceName={authorization.insuranceCompany?.name}
            onSelect={(selectedFormId?: number) => {
              return setFormId(selectedFormId);
            }}
            isSimplifiedUx
            insuranceCompanyId={authorization.InsuranceCompanyId}
            selectedFormId={formId}
            jCode={jCode}
            state={state}
            missingAuthSelected={null}
            institutionId={authorization.InstitutionId}
            NDC={NDC}
            benefitType={benefitType}
            isMultiPartyAuth={isMultiPartyAuth}
            onSubmit={onSubmit}
            openReportMissingForm={() => setShowMemo(true)}
          />
        </Step1Container>
        <Flex justifyContent="center" padding="8px" marginTop="16px">
          <StyledUnderlinedButton
            onClick={() => {
              setShowMemo(true);
            }}
          >
            Report Missing Form
          </StyledUnderlinedButton>
        </Flex>
      </div>
      <MissingFormModal
        setFormId={(newFormId) => setFormId(newFormId)}
        setShowMemo={setShowMemo}
        showMemo={showMemo}
        authorization={authorization}
        state={state}
        isMultiPartyAuth={isMultiPartyAuth}
        insuranceCompanyId={authorization.InsuranceCompanyId as string}
      />
    </div>
  );
};

export default FaxForm;
