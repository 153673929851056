import { useEffect } from "react";
import _ from "lodash";
import { Location } from "@samacare/graphql";
import { useUpsertLocation } from "@samacare/hooks-data";
import { DialogContent, Stack, Tooltip, Typography } from "@samacare/design";
import InfoOutlined from "@samacare/design/core/icons/InfoOutlined";
import { DialogFooterWithButtons, DialogHeader } from "@samacare/component";
import {
  FieldError,
  FormProvider,
  StateField,
  TextField,
  TinField,
  ZipField,
  useForm,
} from "@samacare/form2";
import { useTheme } from "@samacare/design/core/styles";
import IntlPhoneField from "../IntlPhoneField";

const HelperLabel: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="caption"
      color={theme.palette.grey[600]}
      sx={{ textAlign: "left", pl: "14px" }}
    >
      {children}
    </Typography>
  );
};

interface LocationEditPaneProps {
  location: Location | null;
  onClose: (location?: Location | null) => void;
  onBack: () => void;
  onAlert?: (message: string) => void;
  tinRequired?: boolean;
  isWebExtension?: boolean;
}
export const LocationEditPane: React.FC<LocationEditPaneProps> = (props) => {
  const { location, tinRequired, isWebExtension } = props;

  const theme = useTheme();

  const upsertLocation = useUpsertLocation();

  const methods = useForm<Location>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      zip: location?.zip ?? "",
      taxId: location?.taxId ?? "",
    },
  });

  const { errors } = methods.formState;
  const tinError = _.get(errors, "taxId") as FieldError;

  const onSave = async (l: Location) => {
    try {
      const loc = await upsertLocation(l);
      props.onClose(loc);
    } catch (error) {
      if (error instanceof Error) {
        props.onAlert?.(error.message);
      }
    }
  };

  useEffect(() => {
    methods.reset(location ?? {});
  }, [location, methods]);

  const isEdit = !!location?.id;
  const showBack = !isEdit;

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <DialogHeader
        title={`${isEdit ? "Edit" : "Add"} Location`}
        onClose={() => props.onClose()}
      />
      <FormProvider {...methods}>
        <form style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            <Stack direction="column" spacing={2} p={2}>
              <Stack spacing={0.5}>
                <TextField
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={!isWebExtension}
                  fullWidth
                  required
                  label="Location Name"
                  name="facilityName"
                />
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <HelperLabel>
                    Location name is used on all prior authorizations
                  </HelperLabel>
                  <Tooltip title="Location name is pre-filled from the NPI registry. You can edit it; the name here will appear on all prior authorizations.">
                    <InfoOutlined fontSize="small" sx={{ ml: 1, color: theme.palette.primary.main }}/>
                  </Tooltip>
                </Stack>
              </Stack>

              <Stack spacing={0.5}>
                <TextField fullWidth label="Nickname" name="nickname" />
                <HelperLabel>
                  Use nicknames to easily find locations later
                </HelperLabel>
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField fullWidth required label="NPI" name="NPI" disabled />
                <TextField fullWidth label="PTAN" name="PTAN" />
              </Stack>
              <TextField fullWidth label="Address" name="address" />
              <Stack direction="row" spacing={2}>
                <TextField fullWidth label="City" name="city" />
                <StateField fullWidth label="State" name="state" />
                <ZipField fullWidth label="Zip Code" name="zip" />
              </Stack>
              <IntlPhoneField fullWidth label="Fax" name="fax" />
              <Stack spacing={0.5}>
                <TinField name="taxId" required={tinRequired} />
                {!tinError && (
                  <HelperLabel>
                    {tinRequired
                      ? "TIN required to track Prior Authorizations"
                      : "Enter Tax Identification Number (TIN) for seamless tracking of Prior Authorizations"}
                  </HelperLabel>
                )}
              </Stack>
            </Stack>
          </DialogContent>
          <DialogFooterWithButtons
            cancelText={showBack ? "Back" : "Close"}
            onCancel={showBack ? props.onBack : () => props.onClose(location)}
            confirmText={isEdit ? `Update Location` : `Add Location`}
            onConfirm={methods.handleSubmit(onSave)}
          />
        </form>
      </FormProvider>
    </Stack>
  );
};
