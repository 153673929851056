import { PureComponent } from "react";
import styled from "styled-components";
import { MdClose as CloseIcon } from "@react-icons/all-files/md/MdClose";
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft";
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight";
import { Typography } from "@samacare/design";
import SubmitButtons from "@@components/SubmitButtons";

import FrozenBackdropHoc from "./FrozenBackdropHoc";

const ModalContainer = styled.div`
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  z-index: ${(props) => props.theme.zTop + props.zIndexOffset};
  background-color: white;
  border-radius: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: ${(props) => props.minWidth ?? "auto"};
`;

const ModalHeader = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  padding: 24px;
  font-feature-settings: "liga" off, "clig" off;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #6363f280;
`;

const CloseModal = styled(CloseIcon).attrs({
  "aria-label": "Close",
  role: "button",
})`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  width: 100%;
  padding: ${(props) => (props.extraPadding ? "24px 71px" : "24px")};
  display: flex;
  gap: 8px;
  flex-direction: ${(props) => props.direction ?? "column"};
  align-items: center;
`;

const ModalFooter = styled.div`
  padding: 8px;
  background-color: rgba(99, 99, 242, 0.08);
`;

const Arrow = ({ onClick, Icon, isDisabled }) => {
  const color = isDisabled ? "#00000061" : "#6363F280";

  return (
    <Icon
      onClick={() => {
        if (isDisabled) return;
        onClick();
      }}
      style={{
        cursor: "pointer",
        border: `1px solid ${color}`,
        borderRadius: "20px",
        color,
        width: "45px",
        height: "40px",
      }}
    />
  );
};

class BaseModal extends PureComponent {
  render() {
    const {
      id,
      children,
      header,
      onClick,
      styleOverride,
      headerStyleOverride,
      submit,
      submitDisabled,
      submitText,
      minWidth,
      backText = "Close",
      back,
      bodyFlexDirection,
      bodyExtraPadding,
      onPrev,
      onNext,
      counter,
    } = this.props;
    const zIndexOffset = this.props.zIndexOffset ?? 0;

    return (
      <ModalContainer
        id={id}
        aria-labelledby="modal-header"
        role="dialog"
        style={styleOverride}
        zIndexOffset={zIndexOffset}
        minWidth={minWidth}
      >
        <ModalHeader id="modal-header" style={headerStyleOverride}>
          <Typography variant="h6">{header}</Typography>
          {counter && (
            <div
              style={{
                color: "#00000061",
                marginLeft: "auto",
                marginRight: "30px",
                fontSize: "12px",
              }}
            >
              {counter.currentPosition} of {counter.totalAmount}
            </div>
          )}
          {onClick && (
            <CloseModal
              data-cy="actionCloseModal"
              onClick={(e) => {
                e.stopPropagation();
                onClick(e);
              }}
            />
          )}
        </ModalHeader>
        <ModalBody
          direction={bodyFlexDirection}
          extraPadding={bodyExtraPadding}
        >
          {onPrev && (
            <Arrow
              onClick={onPrev}
              Icon={MdKeyboardArrowLeft}
              isDisabled={counter?.totalAmount < 2}
            />
          )}
          {children}
          {onNext && (
            <Arrow
              onClick={onNext}
              Icon={MdKeyboardArrowRight}
              isDisabled={counter?.totalAmount < 2}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <SubmitButtons
            back={back}
            backText={backText}
            submit={submit}
            submitText={submitText}
            roundBorders={true}
            withoutMargin={true}
            submitDisabled={submitDisabled}
          />
        </ModalFooter>
      </ModalContainer>
    );
  }
}

export const Modal = FrozenBackdropHoc(BaseModal);

export default Modal;
