import {
  FormProvider,
  Resolver,
  ServiceCodeDataWrapper,
  SubmitHandler,
  useForm,
} from "@samacare/form2";
import React, { useEffect } from "react";
import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EnhancedServiecesEnum, ServiceCode } from "@samacare/graphql";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { LoadingButton, Stack, Typography } from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";
import { ServiceCodeSelectorDataSources } from "@samacare/form2/core/ServiceCodeSelector/ServiceCodeSelector";
import { getPatientWithTreatmentsByIdQuery } from "../../hooks/usePatientWithTreatments";

const createPatientTreatmentQuery = gql`
  mutation createPatientTreatment($patientId: Int!, $drugOptionId: Int!) {
    createPatientTreatment(patientId: $patientId, drugOptionId: $drugOptionId) {
      PatientId
      DrugOptionId
      treatmentUpdatedAt
    }
  }
`;

type AddTreatment = {
  hcpcs: ServiceCode[];
};

const addTreatmentSchema = object<AddTreatment>({
  hcpcs: array().of(
    object({
      code: string().required(),
    })
  ),
});

export const AddTreatmentForm: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const theme = useTheme();
  const apolloClient = useApolloClient();
  const form = useForm<AddTreatment>({
    resolver: yupResolver(addTreatmentSchema) as Resolver<AddTreatment>,
    mode: "onBlur",
  });
  const { handleSubmit, setError, formState } = form;

  const [createPatientTreatment, { loading }] = useMutation(
    createPatientTreatmentQuery,
    {
      refetchQueries: [getPatientWithTreatmentsByIdQuery],
    }
  );

  useEffect(() => {
    setError("hcpcs.0", { type: "custom" }, { shouldFocus: true });
  }, [setError]);

  const onSubmit: SubmitHandler<AddTreatment> = async (data) => {
    await createPatientTreatment({
      variables: {
        patientId: Number(patientId),
        drugOptionId: data?.hcpcs?.[0]?.drugOptionId,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form id="addPatientTreatmentForm" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography variant="h6" color={theme.palette.primary.main}>
            Add a Treatment
          </Typography>
          <ServiceCodeDataWrapper
            name="hcpcs"
            apolloClient={apolloClient}
            limit={1}
            dataSource={ServiceCodeSelectorDataSources.DrugOptionsOnly}
            highlightedEnhancedServices={[
              EnhancedServiecesEnum.BvEase,
              EnhancedServiecesEnum.PaBoost,
              EnhancedServiecesEnum.AuthInform,
            ]}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ width: "fit-content" }}
            disabled={!formState.isValid}
            loading={loading}
          >
            Continue
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  );
};
