import { Dialog, useTheme } from "@samacare/design";

interface ISamaDialogProps {
  open: boolean;
  onClose: () => void;
  color?: "warning";
}
export const SamaDialog: React.FC<ISamaDialogProps> = (props) => {
  const theme = useTheme();
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": {
          borderTop: `4px solid ${
            props.color === "warning" ? "#fbbd80" : theme.palette.primary.light
          }`,
          borderRadius: "8px",
          width: "600px",
        },
      }}
    >
      {props.children}
    </Dialog>
  );
};
