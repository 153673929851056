import _ from "lodash";
import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  Typography,
  Button,
  Box,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  Paper,
  Popper,
  PaperProps,
  PopperProps,
} from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import SearchIcon from "@samacare/design/core/icons/Search";
import CancelIcon from "@samacare/design/core/icons/Cancel";

import { DialogModal } from "@@components/DialogModal";
import GetPortals from "../../../graphql/GetPortals.gql";
import { GetPortalsQuery } from "@@generated/graphql";
import { Portal } from "@samacare/graphql";
import renderOptionsAutoSuggest from "@@components/RenderOptionsAutoSuggest";
import PortalMessage from "./PortalMessage";

interface PortalWithGroup extends Portal {
  group: string;
}

const CustomPaper = (props: PaperProps) => (
  <Paper
    {...props}
    sx={{
      boxShadow: "none",
      border: "none",
      overflow: "hidden",
    }}
  />
);

const CustomPopper = (props: PopperProps) => (
  <Popper
    {...props}
    sx={{
      boxShadow: "none",
      border: "none",
    }}
  />
);

export const PortalsDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  handlePortalSelect: (portal: Partial<Portal>) => Promise<void>;
  isCypressMockExtension?: boolean | null;
}> = ({ open, onClose, handlePortalSelect, isCypressMockExtension }) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(null);

  const { data, loading } = useQuery<GetPortalsQuery>(GetPortals);
  const fullPortalList = useMemo(() => {
    if (!data) return [];

    const topPortals = _.sortBy(
      data.portals.filter((portal) => portal.isTop && !portal.isHidden),
      "title"
    );
    const allPortals = _.sortBy(
      data.portals.filter((portal) => !portal.isTop && !portal.isHidden),
      "title"
    );

    return [
      ...topPortals.map((portal) => ({ ...portal, group: "Top Portals" })),
      ...allPortals.map((portal) => ({ ...portal, group: "All Portals" })),
    ];
  }, [data]);

  const portalList = React.useMemo(() => {
    if (!isCypressMockExtension) return fullPortalList;

    return fullPortalList.map((p) =>
      p.title === "Carelon" ? { ...p, isLegacy: false } : p
    );
  }, [fullPortalList, isCypressMockExtension]);

  const noOptionsDummy: PortalWithGroup = {
    id: "no-options",
    title: "No Options",
    group: "",
  } as PortalWithGroup;

  const openPortalInNewTab = async (portal?: Partial<Portal>) => {
    if (portal && portal.id !== "no-options") {
      await handlePortalSelect(portal);
    } else {
      window.open("about:blank", "_blank");
      onClose();
    }
  };

  const content = (
    <Box>
      <InputLabel
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          pb: 0.5,
          color: "black",
          mt: 1,
        }}
      >
        Search for a portal
      </InputLabel>
      <Autocomplete
        id="portals-autocomplete"
        data-cy="portalsAutocomplete"
        open
        loading={loading}
        options={portalList}
        groupBy={(option) => option.group}
        getOptionLabel={(option) =>
          option.id === "no-options" ? "" : option.title
        }
        inputValue={searchValue}
        onInputChange={(_e, value) => setSearchValue(value)}
        value={selectedValue}
        onChange={(_event, value) => {
          if (value && value.id !== "no-options") {
            void openPortalInNewTab(value);
            setSelectedValue(null);
            setSearchValue("");
          }
        }}
        noOptionsText=""
        filterOptions={(options, { inputValue }) => {
          const filtered = options.filter((option) =>
            option.title.toLowerCase().includes(inputValue.toLowerCase())
          );
          if (filtered.length === 0) {
            return [noOptionsDummy];
          }
          return filtered;
        }}
        renderInput={(params) => (
          <OutlinedInput
            {...params.InputProps}
            ref={params.InputProps.ref}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              searchValue && (
                <CancelIcon
                  sx={{
                    position: "absolute",
                    right: 11,
                    opacity: "0.54",
                    cursor: "pointer",
                  }}
                  onClick={() => setSearchValue("")}
                />
              )
            }
            placeholder="Search"
            inputProps={{
              ...params.inputProps,
              sx: {
                "&::placeholder": {
                  fontSize: "0.975rem",
                },
              },
            }}
            sx={{
              width: "100%",
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          if (option.id === "no-options") {
            return (
              <PortalMessage
                prefixText="Portal not found - Open a portal in a"
                suffixText="and launch CoPilot"
                onButtonClick={async () => openPortalInNewTab()}
                sx={{ pl: 4, fontWeight: "semi-bold" }}
              />
            );
          }

          return renderOptionsAutoSuggest({
            props,
            option: { id: option.id, title: option.title },
            state: { inputValue },
            sx: {
              "& .MuiTypography-root": {
                fontSize: "14px",
              },
            },
          });
        }}
        ListboxProps={{
          style: {
            maxHeight: "328px",
            overflowY: "auto",
            border: "none",
            boxShadow: "none",
          },
        }}
        PaperComponent={CustomPaper}
        PopperComponent={CustomPopper}
        renderGroup={(params) => (
          <div key={params.key}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
                backgroundColor: "transparent",
                lineHeight: "24px",
                padding: "8px 16px",
                fontSize: "14px",
              }}
            >
              {params.group}
            </Typography>
            {params.children}
          </div>
        )}
        sx={{
          width: "100%",
          height: "345px",
        }}
      />
    </Box>
  );

  const actions = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <PortalMessage
        prefixText="CoPilot works in all portals - Open a portal in a"
        suffixText="and launch CoPilot."
        fontSize="12px"
        color={theme.palette.grey[700]}
        onButtonClick={async () => openPortalInNewTab()}
      />
      <Button
        onClick={onClose}
        sx={{
          color: theme.palette.primary.light,
          textDecoration: "underline",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        Close
      </Button>
    </Box>
  );

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title="Submit Portal PAs"
      content={content}
      actions={actions}
      divider
    />
  );
};
