import { gql, useQuery } from "@apollo/client";
import { Query } from "@samacare/graphql";

export const getBenefitsVerificationByPatientAndDrugQuery = gql`
  query getBenefitsVerificationByPatientAndDrugQuery(
    $patientId: Int!
    $DrugOptionId: Int
  ) {
    getBenefitsVerificationsByPatientAndDrug(
      patientId: $patientId
      DrugOptionId: $DrugOptionId
    ) {
      isBvSupported
      bvs {
        id
        createdAt
        patient {
          firstName
          lastName
        }
        request {
          drugDetails {
            drugName
          }
        }
        outcome {
          createdAt
          state
          data {
            isDrugCoveredByPlan
            isStepTherapyRequired
            drugBuyBillStatus
            isAuthRequired
            updatedAt
            insuranceCoverageStatus
          }
        }
      }
    }
  }
`;

export const useGetBenefitsVerificationsByPatientAndDrug = ({
  patientId,
  drugOptionId,
}: {
  patientId: string | null;
  drugOptionId: string | null;
}): {
  data:
    | {
        getBenefitsVerificationsByPatientAndDrug: Query["getBenefitsVerificationsByPatientAndDrug"];
      }
    | undefined;
  loading: boolean;
} => {
  const { data, loading } = useQuery<{
    getBenefitsVerificationsByPatientAndDrug: Query["getBenefitsVerificationsByPatientAndDrug"];
  }>(getBenefitsVerificationByPatientAndDrugQuery, {
    skip: !patientId || !drugOptionId,
    variables: {
      patientId: Number(patientId),
      DrugOptionId: Number(drugOptionId),
    },
  });

  return { data, loading };
};
