import { useQuery, useMutation } from "@apollo/client";
import { Box, LoadingButton, CircularProgress } from "@samacare/design/core";
import { Authorization, BenefitsVerification } from "@samacare/graphql";
import {
  GetBenefitsVerificationByPatientAndDrugQueryQuery,
  GetBenefitsVerificationByPatientAndDrugQueryQueryVariables,
  CreateABvFromAnAuthMutation,
  CreateABvFromAnAuthMutationVariables,
} from "@@generated/graphql";
import { useConfig, useSamaCareBrandName } from "../../hooks/config";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import ROUTES from "../../routes/ROUTE_PATHS";
import GetBenefitsVerificationByPatientAndDrugQuery from "./GetBenefitsVerificationByPatientAndDrugQuery.gql";
import CreateABvFromAnAuth from "./CreateBvFromAuthMutation.gql";
import { PaBvDetails } from "@samacare/component";
import { cleanDrugName } from "../../../../server/src/shared_code";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";
import { StartEnhancedServiceCard } from "../../components/StartEnhancedServiceCard";

export interface SponsoredBvCreationSectionProps {
  auth: Authorization;
}

export const openBvEditPage = (bvId?: string) => {
  window.open(
    `${window.location.origin}/#${ROUTES.BENEFITS_VERIFICATIONS.path}/edit/${bvId}`,
    "_blank"
  );
};

export const openBvSummaryPage = (bvId?: string) => {
  window.open(
    `${window.location.origin}/#${ROUTES.BENEFITS_VERIFICATIONS.path}/view/${bvId}`,
    "_blank"
  );
};

export const SponsoredBvCreationSection = ({
  auth,
}: SponsoredBvCreationSectionProps) => {
  const { patient, HCPCSCodes, DrugOptionId } = auth;

  const config = useConfig();
  const brandName = useSamaCareBrandName();
  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBenefitsVerification
  );

  const { data, loading } = useQuery<
    GetBenefitsVerificationByPatientAndDrugQueryQuery,
    GetBenefitsVerificationByPatientAndDrugQueryQueryVariables
  >(GetBenefitsVerificationByPatientAndDrugQuery, {
    skip: !patient?.id || !DrugOptionId,
    variables: {
      patientId: Number(patient?.id),
      DrugOptionId,
    },
  });

  const [createBvFromAuth] = useMutation<
    CreateABvFromAnAuthMutation,
    CreateABvFromAnAuthMutationVariables
  >(CreateABvFromAnAuth, {
    refetchQueries: [
      GetBenefitsVerificationByPatientAndDrugQuery,
      "allBenefitsVerifications",
      "GetAuthorization",
    ],
  });

  const mostRecentBv = data?.getBenefitsVerificationsByPatientAndDrug?.bvs?.[0];
  const bvDrugName = mostRecentBv?.request.drugDetails.drugName
    ? cleanDrugName(mostRecentBv?.request?.drugDetails?.drugName)
    : cleanDrugName((HCPCSCodes ?? [])[0]?.drugName);
  const bvNotSupported =
    DrugOptionId == null ||
    data?.getBenefitsVerificationsByPatientAndDrug?.isBvSupported === false;

  if (!isEnabled || !HCPCSCodes || bvNotSupported) {
    return <Box />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!bvNotSupported && mostRecentBv == null) {
    return (
      <StartEnhancedServiceCard
        drugName={bvDrugName ?? ""}
        onClick={async () => {
          const res = await createBvFromAuth({
            variables: {
              authId: Number(auth.id),
            },
          });
          openBvEditPage(res?.data?.createBvFromAuth ?? undefined);
        }}
        bodyText={`${brandName} can investigate coverage, requirements and financials for ${bvDrugName}`}
        buttonText="Check Drug Coverage"
      />
    );
  } else if (!bvNotSupported && mostRecentBv?.outcome != null) {
    return (
      <PaBvDetails
        bv={mostRecentBv as BenefitsVerification}
        drugName={bvDrugName ?? ""}
        bvDataChipDireaction="row"
      >
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(
              `${
                window.location.origin
              }/#${`${ROUTES.BENEFITS_VERIFICATIONS.path}/view/${mostRecentBv?.id}`}`,
              "_blank"
            );
          }}
          endIcon={<OpenInNewIcon sx={{ fontSize: 18 }} />}
          sx={{ maxWidth: "fit-content", padding: 0 }}
        >
          View Details
        </LoadingButton>
      </PaBvDetails>
    );
  }

  return null;
};
