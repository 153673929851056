import { Stack, Tooltip, Typography, TypographyProps } from "@samacare/design";
import colors from "@samacare/design/core/colors";
import Bolt from "@samacare/design/core/icons/Bolt";

export const EnhancedServicesIcon = ({
  sx,
  hover = true,
}: {
  sx?: object;
  hover?: boolean;
}) => {
  if (hover) {
    return (
      <Tooltip title="Enhanced Services Available">
        <Bolt sx={{ color: colors.C800, ...sx }} />
      </Tooltip>
    );
  }
  return <Bolt sx={{ color: colors.C800, ...sx }} />;
};

export const EnhancedServicesIndicatorFull = ({
  flexDirection = "row",
}: {
  flexDirection?: "row" | "row-reverse";
  
}) => (
  <Stack direction={flexDirection} alignItems="center">
    <EnhancedServicesIcon hover={false} />
    <Typography variant="body2" sx={{ color: colors.C800 }}>
      Enhanced Services Available
    </Typography>
  </Stack>
);

export const EnahanceServiceIndicatorSingular = ({
  styleOverride,
}: {
  styleOverride?: { textVariant?: TypographyProps["variant"] };
}) => (
  <Typography
    variant={styleOverride?.textVariant ?? "body2"}
    sx={{ color: colors.C800 }}
  >
    Enhanced Service
  </Typography>
);
