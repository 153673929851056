import { useEffect } from "react";
import { NpiCaptureSource, Prescriber } from "@samacare/graphql";
import { useUpsertPrescriber } from "@samacare/hooks-data";
import { DialogContent, Stack, Typography } from "@samacare/design";
import { DialogFooterWithButtons, DialogHeader } from "@samacare/component";
import { FormProvider, TextField, useForm } from "@samacare/form2";
import { useTheme } from "@samacare/design/core/styles";

interface PrescriberEditPaneProps {
  prescriber: Prescriber | null;
  onClose: (prescriber?: Prescriber | null) => void;
  onBack: () => void;
  onAlert?: (message: string) => void;
}
export const PrescriberEditPane: React.FC<PrescriberEditPaneProps> = (
  props,
) => {
  const theme = useTheme();

  const upsertPrescriber = useUpsertPrescriber();

  const methods = useForm<Prescriber>({
    reValidateMode: "onChange",
  });

  const onSave = async (prescriber: Prescriber) => {
    try {
      const p = await upsertPrescriber(prescriber);
      props.onClose(p);
    } catch (error) {
      if (error instanceof Error) {
        props.onAlert?.(error.message);
      }
    }
  };

  useEffect(() => {
    methods.reset(props.prescriber ?? {});
  }, [props.prescriber, methods]);

  const isEdit = !!props.prescriber?.id;
  const showBack = !isEdit;

  const disabledSourceIsRegistry =
    props.prescriber?.source === NpiCaptureSource.Api;

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <DialogHeader
        title={`${isEdit ? "Edit" : "Add"} Provider`}
        onClose={() => props.onClose()}
      />
      <FormProvider {...methods}>
        <form style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            <Stack direction="column" spacing={2} p={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  fullWidth
                  required
                  label="First Name"
                  name="firstName"
                  disabled={disabledSourceIsRegistry}
                />
                <TextField
                  fullWidth
                  required
                  label="Last Name"
                  name="lastName"
                  disabled={disabledSourceIsRegistry}
                />
              </Stack>
              <Stack spacing={0.5}>
                <TextField fullWidth label="Nickname" name="label" />
                <Typography
                  variant="caption"
                  color={theme.palette.grey[600]}
                  sx={{ textAlign: "left", pl: 2 }}
                >
                  Use nicknames to easily find providers later
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField fullWidth required label="NPI" name="NPI" disabled />
                <TextField
                  fullWidth
                  label="License Number"
                  name="licenseNumber"
                />
                <TextField
                  fullWidth
                  label="DEA Registration Number"
                  name="DEA"
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="Taxonomy"
                  name="specialtyDescription"
                  disabled={disabledSourceIsRegistry}
                />
                <TextField
                  fullWidth
                  label="Taxonomy Code"
                  name="specialtyCode"
                  disabled={disabledSourceIsRegistry}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogFooterWithButtons
            cancelText={showBack ? "Back" : "Close"}
            onCancel={
              showBack ? props.onBack : () => props.onClose(props.prescriber)
            }
            confirmText={isEdit ? `Update Provider` : `Add Provider`}
            onConfirm={methods.handleSubmit(onSave)}
          />
        </form>
      </FormProvider>
    </Stack>
  );
};
