import React from "react";
import PdfViewer from "@@components/PdfViewer";
import { Flex } from "@@ui-kit";
import SimplifiedModal from "@@components/SimplifiedModal";

interface ZoomFormModal {
  pdfURL: string | undefined;
  title: string | undefined;
  onClose: () => void;
  onFinish: () => void;
  onPrev: () => void;
  onNext: () => void;
  counter: {
    totalAmount: number;
    currentPosition: number;
  };
}

const ZoomFormModal = ({
  pdfURL,
  title,
  onClose,
  onFinish,
  onPrev,
  onNext,
  counter,
}: ZoomFormModal) => {
  return (
    <SimplifiedModal
      styleOverride={{
        maxWidth: "100%",
        minWidth: "80vw",
      }}
      header={title}
      onClick={onClose}
      back={onClose}
      submit={onFinish}
      open={!!pdfURL}
      submitText="Use Form"
      onPrev={onPrev}
      onNext={onNext}
      bodyFlexDirection="row"
      counter={counter}
    >
      <Flex justifyContent="center" padding="16px" width="100%">
        <PdfViewer fileURL={pdfURL || ""} />
      </Flex>
    </SimplifiedModal>
  );
};

export default ZoomFormModal;
