import { PureComponent } from "react";
import styled from "styled-components";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import { BaseText } from "Segment/StyledComponents";

import Modal from "../../Modal";
import BaseButton from "../../BaseButton";
import LoadingButton from "../../LoadingButton";
import {
  ModalBodyLarge,
  ModalFooter,
  ModalContent,
} from "../../ModalStyledComponents";
import { withValidateCredentialsOncoEMR } from "../../../graphql/Emr";

const CustomModalContent = styled(ModalContent)`
  margin: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
`;

const GrayButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.lightGray};
  border-color: ${(props) => props.theme.lightGray};
  color: white;
  width: 120px;
  margin-left: 10px;
  padding: 5px 10px;
`;

const SectionTitle = styled.div`
  color: ${(props) => props.theme.gray};
  font-weight: 700;
  margin-top: 10px;
  margin-right: auto;
  text-align: left;
`;

class LoginEmr extends PureComponent {
  state = {
    username: "",
    password: "",
    loginURL: "",
    hasLoginError: false,
    isValidatingCredentials: false,
  };

  submit = async () => {
    const {
      handleSubmit,
      documentsToUpload,
      closeModal,
      alert,
      patientIdentifier,
      validateCredentialsOncoEMR,
      authorization: { InstitutionId: institutionId },
    } = this.props;
    const { username, password, loginURL } = this.state;

    if (!username || !password || !loginURL) {
      alert.error("Please input a valid username, password + login URL!");
    } else {
      try {
        this.setState({ isValidatingCredentials: true });
        await validateCredentialsOncoEMR({
          variables: {
            institutionId,
            emrCreds: {
              username,
              password,
              loginURL: `https://secure${loginURL}.oncoemr.com`,
            },
          },
        });
        this.setState({ isValidatingCredentials: false });
        handleSubmit(patientIdentifier, documentsToUpload);
        closeModal();
      } catch {
        alert.error(
          "The OncoEMR credentials you provided were invalid - please reenter valid credentials!"
        );
        this.setState({ hasLoginError: true, isValidatingCredentials: false });
      }
    }
  };

  render() {
    const { closeModal, open } = this.props;
    const {
      username,
      password,
      loginURL,
      hasLoginError,
      isValidatingCredentials,
    } = this.state;

    return (
      <Modal
        header="OncoEMR Credentials"
        onClick={this.toggleModal}
        open={open}
      >
        <ModalBodyLarge>
          <CustomModalContent>
            <div>
              {`${
                hasLoginError ? "Reinput" : "Add"
              } your login credentials for`}
              <b>&nbsp;OncoEMR</b>. We will use your credentials to upload
              documents on your behalf.
            </div>
            <SectionTitle>Username</SectionTitle>
            <BaseText
              onChange={(e) => {
                this.setState({ username: e.target.value });
              }}
              value={username || ""}
            />
            <SectionTitle>Password</SectionTitle>
            <BaseText
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              value={password || ""}
            />
            <SectionTitle>Login URL</SectionTitle>
            <div style={{ display: "flex", margin: "5px" }}>
              <div>https://secure</div>
              <BaseText
                onChange={(e) => {
                  this.setState({ loginURL: e.target.value });
                }}
                value={loginURL || ""}
                style={{ width: "40px", height: "10px" }}
              />
              <div>.oncoemr.com</div>
            </div>
            All credentials are protected with 256-bit encryption
          </CustomModalContent>
          <CustomModalFooter>
            <GrayButton onClick={closeModal}>Close</GrayButton>
            <LoadingButton
              style={{ marginLeft: 10 }}
              onClick={this.submit}
              loading={isValidatingCredentials}
            >
              Submit
            </LoadingButton>
          </CustomModalFooter>
        </ModalBodyLarge>
      </Modal>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withValidateCredentialsOncoEMR)(withAlert()(LoginEmr));
