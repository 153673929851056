import { capitalCase, noCase } from "change-case";
import { DialogContent, Stack, Typography } from "@samacare/design";
import { DialogFooterWithButtons, DialogHeader } from "@samacare/component";

export interface DuplicateNpiPaneProps {
  objectName: string;
  dupedName: string;
  onBack: () => void;
  onClose: () => void;
  onProceed: () => void;
}
export const DuplicateNpiPane: React.FC<DuplicateNpiPaneProps> = (props) => (
  <Stack sx={{ flexGrow: 1 }}>
    <DialogHeader
      title={`Duplicate ${capitalCase(props.objectName)} Alert`}
      onClose={props.onClose}
    />
    <DialogContent>
      <Stack spacing={1}>
        <Typography variant="body">
          {`This NPI for ${props.dupedName} is already in your ${noCase(
            props.objectName,
          )} list.`}
        </Typography>
        <Typography variant="body">
          {`By proceeding, you will create another ${noCase(
            props.objectName,
          )} with the same NPI.`}
        </Typography>
      </Stack>
    </DialogContent>
    <DialogFooterWithButtons
      cancelText="Back"
      onCancel={props.onBack}
      confirmText="Proceed"
      onConfirm={props.onProceed}
      color="warning"
    />
  </Stack>
);
