import React, { FC } from "react";
import { Box } from "@samacare/design/core";
import BaseButton from "@@components/BaseButton";
import { Institution, FormNumber } from "@samacare/graphql";
import { formatPhoneForDisplay } from "@samacare/utils";

const formatPhoneLabel = ({
  number,
  description,
  forbiddenReason,
}: FormNumber) => {
  const prefix = forbiddenReason ? "[BLACKLISTED] " : "";
  return `${prefix}${formatPhoneForDisplay(number ?? "")} - ${description}`;
};

const formatTag = ({
  institutions,
  number,
}: {
  institutions: Institution[] | null;
  number: FormNumber;
}): string | null => {
  if (!institutions || !number) return null;
  if (!number.InstitutionId) return "SamaCare";
  return institutions.find((i) => i.id === number.InstitutionId)?.name || "";
};

interface FormNumberItemProps {
  buttonText: string;
  number: FormNumber;
  institutions: Institution[];
  onRemove: ({ number }: { number: FormNumber }) => void;
}

const FormNumberItem: FC<FormNumberItemProps> = ({
  buttonText,
  number,
  institutions,
  onRemove,
}) => {
  const extraTagStyles = number.InstitutionId
    ? {
        backgroundColor: "#F5F5F5",
        border: "1px solid #00000099",
        color: "#00000099",
      }
    : {
        backgroundColor: "#EDE7F6",
        border: "1px solid #5E35B1",
        color: "#5E35B1",
      };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: "transparent",
        "&:hover": {
          // @ts-ignore
          backgroundColor: (theme) => theme.lightPurple,
        },
        color: number.forbiddenReason ? "red" : "inherit",
      }}
    >
      <Box
        sx={{
          margin: "0 10px",
          gap: "15px",
          display: "flex",
        }}
      >
        <Box>{formatPhoneLabel(number)}</Box>

        <Box
          sx={{
            fontSize: "12px",
            borderRadius: "15px",
            padding: "3px 10px",
            ...extraTagStyles,
          }}
        >
          {formatTag({ institutions, number })}
        </Box>
      </Box>

      <BaseButton onClick={() => onRemove({ number })}>{buttonText}</BaseButton>
    </Box>
  );
};

export default FormNumberItem;
