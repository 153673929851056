import _ from "lodash";
import { formatDistance } from "date-fns";
import { Stack, CardContent, Typography } from "@samacare/design";
import Chip from "@samacare/design/core/Chip";
import { alpha, useTheme } from "@samacare/design/core/styles";
import {
  BenefitsVerification,
  BenefitsVerificationOutcomeDrugBuyBillStatus,
  BenefitsVerificationOutcomeInsuranceCoverageStatus,
} from "@samacare/graphql";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";
import { PaBvTriggerCard } from "./PaBvTriggerCard";

const BvDataChip = ({
  serviceName,
  value,
}: {
  serviceName: string;
  value: boolean | BenefitsVerificationOutcomeDrugBuyBillStatus;
}) => {
  let label = null;
  const theme = useTheme();
  if (value === null) return null;
  switch (serviceName) {
    case "isDrugCoveredByPlan": {
      label = value ? "Drug Covered" : "Drug NOT Covered";
      break;
    }
    case "isAuthRequired": {
      label = value ? "Auth Required" : "Auth NOT Required";
      break;
    }
    case "isStepTherapyRequired": {
      label = value ? "Step Therapy Required" : "Step Therapy NOT Required";
      break;
    }
    case "drugBuyBillStatus": {
      if (value === BenefitsVerificationOutcomeDrugBuyBillStatus.Medical) {
        label = "Buy and Bill";
      } else if (
        value === BenefitsVerificationOutcomeDrugBuyBillStatus.Pharmacy
      ) {
        label = "Pharmacy";
      } else {
        label = null;
      }
      break;
    }

    default: {
      break;
    }
  }

  if (!label) return null;

  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: value ? alpha(theme.palette.colors.C500, 0.3) : "",
        width: "fit-content",
      }}
      size="small"
    />
  );
};

export const PaBvDetails = ({
  bv,
  drugName,
  bvDataChipDireaction = "column",
  children,
}: {
  bv: BenefitsVerification;
  drugName: string;
  bvDataChipDireaction?: "row" | "column";
  children?: React.ReactNode;
}) => {
  const theme = useTheme();
  const outcomeData = bv.outcome?.data;
  const allValuesNull = _.every(
    _.pick(outcomeData, [
      "isDrugCoveredByPlan",
      "isAuthRequired",
      "isStepTherapyRequired",
      "drugBuyBillStatus",
    ]),
    _.isNull,
  );

  if (allValuesNull) return null;

  return (
    <PaBvTriggerCard drugName={drugName ?? ""}>
      <CardContent>
        <Stack direction="column" spacing={2}>
          {outcomeData?.insuranceCoverageStatus ===
            BenefitsVerificationOutcomeInsuranceCoverageStatus.Active && (
            <Typography variant="body2" display="flex" alignItems="center">
              <CheckCircleIcon
                sx={{
                  marginRight: theme.spacing(0.75),
                  color: theme.palette.success.main,
                  fontSize: 16,
                }}
              />
              Active insurance
            </Typography>
          )}
          <Stack direction={bvDataChipDireaction} spacing={0.5}>
            {_.map(outcomeData, (value, key) => (
              <BvDataChip key={key} serviceName={key} value={value} />
            ))}
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            mt={1}
            alignItems="center"
            paddingX={1}
          >
            {children}
            <Stack direction="column" alignItems="flex-end" mt={1}>
              {bv.outcome?.data?.updatedAt && (
                <Typography variant="caption" color="text.secondary">
                  {`Checked ${formatDistance(
                    new Date(bv.outcome?.data?.updatedAt),
                    new Date(),
                    {
                      addSuffix: true,
                    },
                  )}`}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </PaBvTriggerCard>
  );
};
