import gql from "graphql-tag";

import { useLazyQuery } from "@apollo/client";
import { Location } from "@samacare/graphql";
import { useCallback, useState } from "react";

export const findLocationByNpiQuery = gql`
  query FindLocationByNpi($npi: String!) {
    findLocationByNpi(npi: $npi) {
      success
      message
      alreadyExists
      locationFromNpiRegistry {
        facilityName
        address
        city
        state
        zip
        fax
        NPI
        source
      }
    }
  }
`;

export const useFindLocationByNpi = () => {
  const [location, setLocation] = useState<Location | null>(null);
  const [alreadyExists, setAlreadyExists] = useState<boolean>(false);
  const [find, { data, loading }] = useLazyQuery(findLocationByNpiQuery, {
    onCompleted: (d) => {
      if (d.findLocationByNpi?.success) {
        setAlreadyExists(d.findLocationByNpi.alreadyExists);
        setLocation(d.findLocationByNpi.locationFromNpiRegistry);
      } else {
        setAlreadyExists(false);
        setLocation(null);
      }
    },
    fetchPolicy: "network-only",
  });

  const findLocationByNpi = useCallback(
    (npi: string) => {
      find({ variables: { npi } });
    },
    [find],
  );

  return {
    findLocationByNpi,
    location,
    alreadyExists,
    findLocationSuccess: data?.findLocationByNpi?.success,
    errorMessage: data?.findLocationByNpi?.message,
    loading,
  };
};
