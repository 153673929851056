import { IconType } from "@react-icons/all-files";
import { useMemo, useState } from "react";
import styled from "styled-components";
import colors from "../../../../resources/colors";
import { Bell, BellSlash } from "../../../../ui-kit/Icons";
import { useSelector } from "../../../configureStore";
import { useSet } from "../../../hooks/useSet";
import { LeftRightCenterV } from "../../LeftRight";
import { Tooltip } from "@samacare/design/core";

const df = window.CONFIG.DEFAULT_FIELDS;

const WatchButton = styled(LeftRightCenterV)`
  border-radius: 18px;
  height: 36px;
  border: 1px solid ${colors.purple};
  background: white;
  padding: 0 18px;
  cursor: pointer;

  & div {
    color: ${colors.purple};
  }

  &:hover {
    background: ${colors.purple};
    & div {
      color: white;
    }
  }
`;

interface IEmailToggleProps {
  save: () => void | null;
}
export const EmailToggle: React.FC<IEmailToggleProps> = ({ save }) => {
  const set = useSet();
  const [hover, setHover] = useState<boolean>(false);
  const results = useSelector((state) => state.form.results);

  const isOn = useMemo<boolean>(
    () => results && results[df.EMAIL_NOTIFICATION_TYPE.key] === "On",
    [results]
  );
  const Icon = useMemo<IconType>(() => (isOn ? Bell : BellSlash), [isOn]);

  if (!results) return null;

  return (
    <Tooltip title="Use this to toggle getting email updates when the status of this authorization changes">
      <WatchButton
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          set({ [df.EMAIL_NOTIFICATION_TYPE.key]: !isOn ? "On" : null });
          //small race condition with the useSet above, so we require a small wait before the save
          setTimeout(() => {
            save();
          }, 10);
        }}
      >
        <div style={{ marginRight: "6px" }}>Email Notifications</div>
        <Icon
          style={{
            width: "24px",
            height: "24px",
            color: hover ? colors.white : colors.purple,
          }}
        />
      </WatchButton>
    </Tooltip>
  );
};
