import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import _ from "lodash";

import {
  Account,
  Maybe,
  MutationUpsertAccountForSamaUsersArgs,
  Query,
} from "@samacare/graphql";

import {
  CurrentAccountQueryVariables,
  UpsertAccountForSamaUsersMutation,
} from "@@generated/graphql";

import accountInfo from "./fragments/accountInfo";
import supportAccountInfo from "./fragments/supportAccountInfo";
import { withDefaultProps } from "./utils";

export const CURRENT_ACCOUNT_QUERY_NAME = "currentAccount";
export const currentAccountQuery = gql`
  query currentAccount {
    currentAccount {
      ...accountInfo
      allowedInstitutionIds
    }
  }
  ${accountInfo}
`;

export const useCurrentAccount = (): [
  Maybe<Account>,
  {
    data: Query | undefined;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const { data, error, loading } = useQuery<
    Query,
    CurrentAccountQueryVariables
  >(currentAccountQuery);
  return [
    (data && data.currentAccount) as Maybe<Account>,
    { data, error, loading },
  ];
};

export const ACCOUNTS_FIND_ALL_QUERY_NAME = "accountsFindAll";
export const withAccountsQuery = gql`
  query accountsFindAll($institutionId: Int) {
    accountsFindAll(institutionId: $institutionId) {
      ...supportAccountInfo
    }
  }
  ${supportAccountInfo}
`;

export const userCredentialByFollowUpQuery = gql`
  query userCredentialByFollowUp($followUpId: Int) {
    userCredentialByFollowUp(followUpId: $followUpId) {
      PortalId
      username
      portalMfaPhoneNumber
    }
  }
`;

export const withCurrentAccount = graphql(currentAccountQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    account: _.get(data, "currentAccount"),
  })),
});

export const withAccounts = graphql(withAccountsQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    accounts: _.get(data, "accountsFindAll"),
  })),
  options: (props: { institutionId: string }) => ({
    fetchPolicy: "network-only",
    variables: { institutionId: props.institutionId },
  }),
});

const withResetPasswordMutation = gql`
  mutation resetPassword($id: Int, $email: String) {
    resetPassword(id: $id, email: $email)
  }
`;
export const withResetPassword = graphql(withResetPasswordMutation, {
  name: "resetPassword",
});

const withUpdateShowTutorialMutation = gql`
  mutation updateShowTutorial {
    updateShowTutorial {
      ...accountInfo
    }
  }
  ${accountInfo}
`;

export const withUpdateShowTutorial = graphql(withUpdateShowTutorialMutation, {
  name: "updateShowTutorial",
  options: { refetchQueries: () => [CURRENT_ACCOUNT_QUERY_NAME] },
});

const withGeneratePasswordTokenMutation = gql`
  mutation generatePasswordToken($id: Int!) {
    generatePasswordToken(id: $id)
  }
`;
export const withGeneratePasswordToken = graphql(
  withGeneratePasswordTokenMutation,
  { name: "generatePasswordToken" }
);

const withUpdatePasswordMutation = gql`
  mutation updatePassword($nonce: String!, $password: String!) {
    updatePassword(nonce: $nonce, password: $password)
  }
`;
export const withUpdatePassword = graphql(withUpdatePasswordMutation, {
  name: "updatePassword",
});

const withUpdateAccountInstitutionMutation = gql`
  mutation updateAccountInstitution($InstitutionId: Int!) {
    updateAccountInstitution(InstitutionId: $InstitutionId) {
      ...accountInfo
    }
  }
  ${accountInfo}
`;
export const withUpdateAccountInstitution = graphql(
  withUpdateAccountInstitutionMutation,
  { name: "updateAccountInstitution" }
);

export const updateAccountMutation = gql`
  mutation updateAccount($patch: UpdateAccountPatch) {
    updateAccount(patch: $patch) {
      ...supportAccountInfo
    }
  }
  ${supportAccountInfo}
`;

export const upsertAccountForSamaUsersMutation = gql`
  mutation upsertAccountForSamaUsers($patch: UpsertAccountForSamaUsersPatch) {
    upsertAccountForSamaUsers(patch: $patch) {
      ...supportAccountInfo
    }
  }
  ${supportAccountInfo}
`;

export const withUpsertAccountForSamaUsers = graphql(
  upsertAccountForSamaUsersMutation,
  {
    name: "upsertAccountForSamaUsers",
    options: { refetchQueries: () => [ACCOUNTS_FIND_ALL_QUERY_NAME] },
  }
);

export const useUpsertAccountForSamaUsersMutation = () =>
  useMutation<
    UpsertAccountForSamaUsersMutation,
    MutationUpsertAccountForSamaUsersArgs
  >(upsertAccountForSamaUsersMutation, {
    refetchQueries: () => [ACCOUNTS_FIND_ALL_QUERY_NAME],
  });

export const withUpsertPortalCredentialsMutation = gql`
  mutation upsertPortalCredentials(
    $credentials: PortalCredential!
    $isDelete: Boolean
  ) {
    upsertPortalCredentials(credentials: $credentials, isDelete: $isDelete) {
      ...accountInfo
    }
  }
  ${accountInfo}
`;

export const withUpsertPortalCredentials = graphql(
  withUpsertPortalCredentialsMutation,
  {
    name: "upsertPortalCredentials",
    options: { refetchQueries: () => [CURRENT_ACCOUNT_QUERY_NAME] },
  }
);

export const withUnlockAccount = graphql(
  gql`
    mutation unlockAccount($id: Int!) {
      unlockAccount(id: $id) {
        ...accountInfo
      }
    }
    ${accountInfo}
  `,
  {
    name: "unlockAccount",
  }
);
