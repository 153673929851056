import { PatientBlock } from "@samacare/form";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { BenefitsCheckOptionsField } from "./BenefitsCheckOptionsField";
import { Stack } from "@samacare/design/core";

export const BenefitsVerificationServiceAndPatientBlock = ({
  children,
  showServiceSelection = true,
}: {
  children?: React.ReactNode;
  showServiceSelection?: boolean;
}) => {
  return (
    <>
      <EnrollmentSection title="Patient">
        <PatientBlock required disabled />
      </EnrollmentSection>
      <EnrollmentSection title="Service Selection">
        <Stack spacing={2} direction="column">
          {showServiceSelection && (
            <BenefitsCheckOptionsField
              typeFieldName="benefitsCheckType"
              drugOptionIdFieldName="DrugOptionId"
              disabled
            />
          )}
          {children}
        </Stack>
      </EnrollmentSection>
    </>
  );
};
