import _ from "lodash";
import moment from "moment";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";

import {
  LoadingButton,
  Box,
  Drawer,
  Stack,
  Typography,
  Tab,
  TabPanel,
  TabContext,
  TabList,
} from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import CloseIcon from "@samacare/design/core/icons/Close";
import { usePatientTreatmentsContext } from "./PatientTreatmentsProvider";
import { PatientTreatmentCard } from "./PatientTreatmentCard";
import { InsuranceCoverageCheckWithAlert } from "@@components/InsuranceCoverageCheck";
import { formatPhoneNumber } from "../../components/PDFEditor/InputTypes/PhoneInput";
import { useRunEligibilityCheck } from "../../hooks/useRunEligibilityCheck";
import CircularProgress from "@samacare/design/core/CircularProgress";
import { segmentTypewriter } from "../../../analytics/analytics";
import { InitiateBvLiteSource } from "../../../analytics/generated/segment";
import { useAccount } from "../../providers/AccountProvider";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../analytics/util";
import { PatientTreatmentInsuranceInfoSection } from "./PatientTreatmentInsuranceInfoSection";
import { usePatientWithTreatments } from "../../hooks/usePatientWithTreatments";

export const PatientTreatmentDetailPage: React.FC = () => {
  const theme = useTheme();
  const { open, toggleDrawer, activeRow } = usePatientTreatmentsContext();
  const alert = useAlert();
  const [activeTab, setActiveTab] = useState<string>("1");
  const [refreshBenefitsCheckLite] = useRunEligibilityCheck();
  const [refreshingEligibilityCheck, setRefreshingEligibilityCheck] =
    useState(false);
  const [eligibilityCheckError, setEligibilityCheckError] = useState(false);
  const patientResult = usePatientWithTreatments(Number(activeRow?.id));
  const account = useAccount();

  useEffect(() => {
    setEligibilityCheckError(false);
  }, [activeRow]);

  const { patient } = patientResult;

  const runEligibilityCheck = async () => {
    try {
      setRefreshingEligibilityCheck(true);
      segmentTypewriter.initiateBvLite({
        accountId: +account!.id,
        email: account!.email!,
        isAdmin: account!.isAdmin,
        institutionIsTop: account!.institution!.isTop,
        institutionName: account!.institution!.name,
        institutionSpecialty:
          account!.institution!.practicingSpecialty ?? undefined,
        insuranceName: patient?.primaryInsurance?.insuranceCompany?.name ?? "",
        insuranceState: patient?.primaryInsurance?.insuranceState ?? undefined,
        insuranceType: getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
          patient?.primaryInsurance?.planType ?? undefined
        ),
        initiateBvLiteSource: InitiateBvLiteSource.Treatment,
        //Patient treatment page does not have drug and icd info
        DrugOptionId: "",
        icdCodes: [],
      });
      const bvLiteResponse = await refreshBenefitsCheckLite({
        variables: { PatientId: patient?.id },
        refetchQueries: ["getPatientWithTreatmentsById"],
        awaitRefetchQueries: true,
      });

      if (
        bvLiteResponse.data?.refreshPrimaryInsuranceCoverageCheck.success ===
        false
      ) {
        setEligibilityCheckError(true);
      }
    } catch (e) {
      const error = e as Error;
      if (error.message.includes("not supported for availity")) {
        alert.info("This insurance is not supported for eligibility check");
      }
      setEligibilityCheckError(true);
    } finally {
      setRefreshingEligibilityCheck(false);
    }
  };

  return (
    <Drawer
      open={open}
      sx={{ zIndex: 5 }}
      anchor="right"
      onClose={() => {
        if (toggleDrawer) toggleDrawer(false);
      }}
    >
      <Stack direction="column" padding={4} width={750}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" color={theme.palette.text.primary}>
            {_.compact([
              patient?.firstName,
              patient?.lastName,
              patient?.dob && `(${moment(patient?.dob).format("MM/DD/YYYY")})`,
            ]).join("  ")}
          </Typography>
          <LoadingButton
            type="button"
            size="small"
            variant="text"
            onClick={() => {
              if (toggleDrawer) toggleDrawer(false);
            }}
            sx={{ color: theme.palette.grey.A400 }}
          >
            <CloseIcon />
          </LoadingButton>
        </Stack>

        {patientResult.loading && <CircularProgress />}

        {!patientResult.loading && patient != null && (
          <>
            <Stack direction="column" spacing={0.5}>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {patient.address}
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {_.compact([patient.city, patient.state, patient.zip]).join(
                  ", "
                )}
              </Typography>
            </Stack>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {patient.phone ? formatPhoneNumber(patient.phone) : "-"}
            </Typography>
            <PatientTreatmentInsuranceInfoSection
              patientDetails={patient}
              refreshingEligibilityCheck={refreshingEligibilityCheck}
              runEligibilityCheck={runEligibilityCheck}
            />
            <InsuranceCoverageCheckWithAlert
              patient={patient}
              isLoading={refreshingEligibilityCheck}
              onRunClick={runEligibilityCheck}
              eligibilityCheckError={eligibilityCheckError}
              autoRun
            />
          </>
        )}
        {patient?.primaryInsurance?.id && (
          <TabContext value={activeTab}>
            <Box>
              <TabList
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
                onChange={(_event, value: string) => setActiveTab(value)}
              >
                <Tab label="Treatments" value="1" />
              </TabList>
            </Box>
            <Box>
              <TabPanel value="1" sx={{ padding: "24px 0" }}>
                <PatientTreatmentCard patient={patient} />
              </TabPanel>
            </Box>
          </TabContext>
        )}
      </Stack>
    </Drawer>
  );
};
