import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import { ContextHeader } from "AuthorizationSharedComponents/ContextHeader";
import { Stack, Box } from "@samacare/design";
import Tabs from "@samacare/design/core/Tabs";
import Tab from "@samacare/design/core/Tab";
import HistoryTab from "@@components/AuthorizationSharedSteps/ResponseSection/HistoryTab";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { useAuthorization } from "@@hooks/useAuthorization";
import { useAuthorizationLogs } from "@@hooks/useAuthorizationLogs";
import { useAccount } from "../../../providers/AccountProvider";
import { AttachFileBtn } from "@@components/AuthorizationSharedSteps/ResponseSection/AttachFileBtn";
import { DetailsTab } from "./DetailsTabSection";
import { InsuranceCoverageCheckWithAlert } from "@@components/InsuranceCoverageCheck";
import { useConfig } from "@@hooks/config";
import { useRunEligibilityCheck } from "../../../hooks/useRunEligibilityCheck";
import { usePatient } from "@@hooks/usePatient";
import CircularProgress from "@samacare/design/core/CircularProgress";
import { useQueryParams } from "@@hooks/useQueryParams";
import { segmentTypewriter } from "../../../../analytics/analytics";
import { InitiateBvLiteSource } from "../../../../analytics/generated/segment";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../../analytics/util";

export const PortalAuthorizationCopilot = () => {
  const alert = useAlert();
  const [refreshBenefitsCheckLite] = useRunEligibilityCheck();
  const { getQueryParam, setQueryParam } = useQueryParams();

  const [refreshingEligibilityCheck, setRefreshingEligibilityCheck] =
    useState(false);
  const [eligibilityCheckError, setEligibilityCheckError] = useState(false);
  const config = useConfig();

  const history = useHistory();
  const { authorization } = useAuthorization();
  const { authorizationLogs } = useAuthorizationLogs(
    parseInt(authorization?.id ?? "-1")
  );
  const account = useAccount();

  const patientResult = usePatient(Number(authorization?.patient?.id ?? null));

  const HISTORY = "history";
  const DETAILS = "details";
  const TAB_LABELS = [HISTORY, DETAILS];

  const initialTab = getQueryParam("tab") || "history";
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    TAB_LABELS.indexOf(initialTab)
  );

  useEffect(() => {
    const tab = TAB_LABELS[selectedTabIndex];
    setQueryParam("tab", tab);
  }, [selectedTabIndex]);

  if (patientResult.loading) {
    return <CircularProgress />;
  }

  const runEligibilityCheck = async () => {
    try {
      setRefreshingEligibilityCheck(true);
      segmentTypewriter.initiateBvLite({
        accountId: +account!.id,
        email: account!.email!,
        isAdmin: account!.isAdmin,
        institutionIsTop: account!.institution!.isTop,
        institutionName: account!.institution!.name,
        institutionSpecialty:
          account!.institution!.practicingSpecialty ?? undefined,
        insuranceName: authorization?.insuranceCompany?.name ?? "",
        insuranceState:
          authorization?.patient?.primaryInsurance?.insuranceState ?? undefined,
        insuranceType: getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
          authorization?.patient?.primaryInsurance?.planType ?? undefined
        ),
        DrugOptionId: authorization?.DrugOptionId ?? "",
        icdCodes: authorization?.ICDs,
        initiateBvLiteSource: InitiateBvLiteSource.Pa,
      });
      const bvLiteResponse = await refreshBenefitsCheckLite({
        variables: { PatientId: authorization?.patient?.id },
        refetchQueries: ["getPatientById"],
        awaitRefetchQueries: true,
      });

      if (
        bvLiteResponse.data?.refreshPrimaryInsuranceCoverageCheck.success ===
        false
      ) {
        setEligibilityCheckError(true);
      } else {
        alert.success("Eligibility check updated!");
      }
    } catch (e) {
      alert.error("Unable to run eligibility check");
      setEligibilityCheckError(true);
    } finally {
      setRefreshingEligibilityCheck(false);
    }
  };

  return (
    <Stack sx={{ padding: "25px 50px 0 50px" }}>
      {authorization && account && (
        <>
          <ContextHeader
            authorization={authorization}
            account={account}
            backToAuthorizations={() => {
              history.push(ROUTE_PATHS.AUTHORIZATION_LIST.path);
            }}
            triggerReinputLogin={undefined}
            isDisabled={false}
            hideDownloadButton
          />
          <InsuranceCoverageCheckWithAlert
            patient={patientResult.patient}
            isLoading={refreshingEligibilityCheck}
            onRunClick={runEligibilityCheck}
            disabled={config.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES.includes(
              authorization.status
            )}
            eligibilityCheckError={eligibilityCheckError}
          />
          <Stack marginTop={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                marginBottom: "20px",
              }}
            >
              <Tabs
                onChange={(e, value: number) => {
                  e.stopPropagation();
                  setSelectedTabIndex(value);
                }}
                value={selectedTabIndex}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {TAB_LABELS.map((label) => (
                  <Tab
                    label={label.charAt(0).toUpperCase() + label.slice(1)}
                    key={`tab_${label}`}
                  />
                ))}
              </Tabs>
            </Box>

            {TAB_LABELS[selectedTabIndex] === HISTORY && (
              <Box>
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <AttachFileBtn authorization={authorization} />
                </Box>

                <HistoryTab
                  authorization={authorization}
                  authorizationLogs={authorizationLogs}
                />
              </Box>
            )}
            {TAB_LABELS[selectedTabIndex] === DETAILS && (
              <DetailsTab authorization={authorization} />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};
