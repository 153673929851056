import React, { FC } from "react";
import _ from "lodash";
import { Box, CircularProgress } from "@samacare/design/core";
import { useAllInstitutions } from "@@hooks/useAllInstitutions";
import { Institution, FormNumber } from "@samacare/graphql";
import FormNumberItem from "./FormNumberItem";

interface FormNumbersProps {
  buttonText: string;
  labelText: string;
  formNumbers: FormNumber[];
  onRemove: ({ number }: { number: FormNumber }) => void;
  isLoading: boolean;
}

const FormNumbers: FC<FormNumbersProps> = ({
  formNumbers,
  onRemove,
  buttonText,
  labelText,
  isLoading,
}) => {
  const institutions = useAllInstitutions() as Institution[];

  return (
    <>
      <Box sx={{ width: "100%", fontWeight: 700, margin: "10px 0" }}>
        {labelText}
      </Box>

      {isLoading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: "400px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              WebkitAppearance: "none",
              width: "7px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          {_.map(formNumbers, (number) => (
            <FormNumberItem
              key={number.id}
              number={number}
              onRemove={onRemove}
              buttonText={buttonText}
              institutions={institutions}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default FormNumbers;
