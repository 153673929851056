import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import * as React from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Box, Flex } from "@@ui-kit";
import { Switch } from "@@ui-kit/forms";
import NewPriorAuthButton from "@@components/NewPriorAuthButton";
import PatientSearchFields from "@@components/PatientSearchFields";
import PracticeReportDownloader from "@@components/PracticeReportDownloader";
import {
  Account,
  Prescriber,
  AuthorizationConnectionSortByAttribute,
} from "@samacare/graphql";
import {
  AuthorizationListDataQuery,
  AuthorizationListDataQueryVariables,
} from "@@generated/graphql";
import { IconPopper } from "../../../ui-kit/IconPopper";
import { Eye, Sort } from "../../../ui-kit/Icons";
import { TwoButtonToggle } from "../../../ui-kit/TwoButtonToggle";
import ROUTE_PATHS from "../ROUTE_PATHS";
import { useLocation, generatePath, useHistory } from "react-router-dom";
import { isNovartisAuth } from "../../util/isNovartisAuth";
import AuthorizationsFilters from "./AuthorizationsFilters";
import { PatientsAuthorizationList } from "./PatientsAuthorizationList";
import { Account as AccountType } from "./PatientTile/interfaces";
import { defaultStatuses, useFilterState } from "./reducer";
import Tooltip from "@samacare/design/core/Tooltip";
import { ExtensionAlertBanner } from "@@components/ExtensionAlertBanner";
import { useFeatureFlag } from "@@hooks/useFeatureFlag";
import { useConfig } from "@@hooks/config";
import { WebExtensionContext } from "../../contexts/webExtension";
import { Button, IconButton } from "@samacare/design";
import Download from "@samacare/design/core/icons/Download";
import {
  LeftRightCenterAll,
  LeftRightCenterV,
  LeftRightSpreadCenterV,
} from "@samacare/component";
import Add from "@samacare/design/core/icons/Add";
import { usePortals } from "../../hooks/usePortals";

export const ACCOUNT_ID_FILTER_KEY = "accountIdFilter";

const SortLabel = styled.div`
  margin-right: 4px;
  padding: 7px;
`;

const FadeInPane = styled.div`
  transition: opacity 162ms ease-out;
`;

export const authorizationListDataQuery = gql`
  query authorizationListData {
    currentAccount {
      id
      isAdmin
      isSamaUser
      institution {
        id
        locations {
          id
          facilityName
          nickname
        }
        featureFlags {
          Enrollments
          Novartis
          BenefitsVerification
        }
        practicingSpecialty
      }
    }

    insuranceCompanies: insuranceCompaniesFindAll {
      id
      name
      isArchived
    }

    accounts: accountsFindAll {
      id
      firstName
      lastName
      isSystemUser
      isSamaUser
    }

    prescribers: prescribersFindAll {
      id
      firstName
      lastName
    }
  }
`;

export type AuthorizationListProps = React.ComponentProps<
  typeof AuthorizationList
>;

const Loading = () => (
  <LeftRightCenterAll sx={{ paddingTop: "100px", width: "100%" }}>
    <div data-cy="componentLoadingSpinner">
      <img
        width="92"
        src="../../../../assets/SamaCare_Loader_184px.gif"
        alt="loader"
      />
      <div style={{ opacity: 0 }} aria-label="Loading">
        Loading...
      </div>
    </div>
  </LeftRightCenterAll>
);

export const AuthorizationList: React.VoidFunctionComponent = () => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [state, setState] = useFilterState();
  const [isAllLoadingDone, setIsAllLoadingDone] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const config = useConfig();
  const { isWebExtensionConnected } = React.useContext(WebExtensionContext);

  const enableBlendedPortalWorkflow = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBlendedPortalWorkflow
  );

  const { portals } = usePortals();

  const { data, error, loading } = useQuery<
    AuthorizationListDataQuery,
    AuthorizationListDataQueryVariables
  >(authorizationListDataQuery);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    // listen for AccountId Filter trigger

    const paramsId = params.get("id");
    // Trigger user selection dropdown
    if (paramsId != null) {
      setState({
        type: "accountId",
        value: paramsId,
      });
    }

    const paramsFirstName = params.get("firstName");
    // Trigger user selection dropdown
    if (paramsFirstName != null) {
      setState({
        type: "firstName",
        value: paramsFirstName,
      });
    }

    const paramsLastName = params.get("lastName");
    // Trigger user selection dropdown
    if (paramsLastName != null) {
      setState({
        type: "lastName",
        value: paramsLastName,
      });
    }

    const paramsJcode = params.get("jCode");
    // Trigger user selection dropdown
    if (paramsJcode != null) {
      setState({
        type: "jCode",
        value: paramsJcode,
      });
    }
  }, []);

  let selectedId = state.filters.accountId;

  React.useEffect(() => {
    const currentAccount = data?.currentAccount;
    if (selectedId == null || selectedId === "") {
      if (window.localStorage.getItem(ACCOUNT_ID_FILTER_KEY) != null) {
        selectedId = window.localStorage.getItem(ACCOUNT_ID_FILTER_KEY);
      } else if (currentAccount) {
        selectedId = currentAccount.id;
      }
      setState({ type: "accountId", value: selectedId });
    }
    // if accountId filter is already triggered, update params
    if (selectedId != null && selectedId !== "") {
      window.localStorage.setItem(ACCOUNT_ID_FILTER_KEY, selectedId);
      history.push(
        generatePath(
          `${ROUTE_PATHS.AUTHORIZATION_LIST_FILTERED.path}?id=${selectedId}`
        )
      );
    }
    // otherwise, reset url to homepage url
    else {
      history.push(generatePath(ROUTE_PATHS.AUTHORIZATION_LIST.path));
    }
  }, [state.filters.accountId, data]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div aria-label="Error">Failed to load patients.</div>;
  }

  const accounts = [...((data?.accounts ?? []) as Account[])];
  const prescribers = [...((data?.prescribers ?? []) as Prescriber[])];
  const insuranceCompanyData = data?.insuranceCompanies ?? [];

  const insuranceCompanies = insuranceCompanyData.filter(
    (insuranceCompany) => insuranceCompany.isArchived === false
  );
  const currentAccount: Account | null =
    (data?.currentAccount as Account) ?? null;

  const isNovartis: boolean = isNovartisAuth(currentAccount as AccountType);

  if (currentAccount == null) {
    return <div>Not authorized</div>;
  }

  const patientSearchTypes = [
    "firstName",
    "lastName",
    "AuthorizationId",
    "MRN",
    "jCode",
    "drugName",
  ] as const;
  const setFilterState = (
    type: (typeof patientSearchTypes)[number],
    value: string
  ) => setState({ type, value });

  return (
    <>
      {!isAllLoadingDone && <Loading />}
      <Box
        paddingX={(theme) => (isAllLoadingDone ? theme.spacing(4) : "")}
        paddingBottom={(theme) => (isAllLoadingDone ? theme.spacing(4) : "")}
        width={isAllLoadingDone ? "100%" : ""}
        id="pendo_authDashboard"
        data-cy="componentAuthDashboard"
      >
        <FadeInPane style={{ opacity: isAllLoadingDone ? 1 : 0 }}>
          <LeftRightSpreadCenterV
            sx={{ marginTop: 2, marginBottom: 2, width: "100%" }}
          >
            <PatientSearchFields
              currentAccount={currentAccount}
              clearName={() =>
                patientSearchTypes.forEach((type) => setFilterState(type, ""))
              }
              firstName={state.filters.firstName ?? ""}
              lastName={state.filters.lastName ?? ""}
              AuthorizationId={state.filters.AuthorizationId ?? ""}
              MRN={state.filters.MRN ?? ""}
              setFilterState={setFilterState}
              jCode={state.filters.jCode ?? ""}
              drugName={state.filters.drugName ?? ""}
            />
            <LeftRightCenterV>
              <IconPopper
                targetAriaLabel="View Button"
                target={<Eye data-cy="controlViewOptions" />}
              >
                <Flex marginRight="20px">
                  <Switch
                    data-cy="controlShowArchived"
                    name="isArchived"
                    checked={(() => {
                      switch (true) {
                        case _.isEqual(state.filters.isArchived.in, [false]):
                          return false;
                        case state.filters.isArchived.in == null:
                        case _.isEqual(state.filters.isArchived.in, [
                          false,
                          true,
                        ]):
                          return true;
                        default:
                          throw new Error("Unknown isArchived state!");
                      }
                    })()}
                    label="Show Archived"
                    onChange={({ currentTarget }) =>
                      setState({
                        type: "isArchived",
                        // This is a controlled component, so when `onChange` is called,
                        // we haven't actually re-rendered the component yet; here,
                        // the value of `checked` is the old value.
                        value: {
                          in: !currentTarget.checked ? undefined : [false],
                        },
                      })
                    }
                  />
                </Flex>
                <Flex marginRight="20px">
                  <Tooltip
                    placement="bottom"
                    title="This allows you to filter by Expiration Date and see which auths are expiring with a certain time frame"
                  >
                    <Switch
                      data-cy="controlShowExpiringOnly"
                      name="filterByExpired"
                      checked={state.filters.filterByExpired}
                      label="Expiring Only"
                      labelAlign="right"
                      fitted
                      onChange={({ currentTarget }) => {
                        setState({
                          type: "filterByExpired",
                          value: !currentTarget.checked,
                        });
                      }}
                    />
                  </Tooltip>
                </Flex>
              </IconPopper>
              {!isNovartis && (
                <IconPopper
                  targetAriaLabel="Sort Button"
                  target={<Sort data-cy="controlSortOptions" />}
                >
                  <SortLabel>Sort By:</SortLabel>
                  <TwoButtonToggle
                    cypressTag="controlToggleSortBy"
                    leftLabel="Service Date"
                    rightLabel="Last Updated"
                    onLeftClick={() => {
                      setState({
                        type: "sortBy",
                        value:
                          AuthorizationConnectionSortByAttribute.ServiceDate,
                      });
                    }}
                    onRightClick={() => {
                      setState({
                        type: "sortBy",
                        value:
                          AuthorizationConnectionSortByAttribute.LastUpdated,
                      });
                    }}
                    isRightSelected={
                      state.sortBy ===
                      AuthorizationConnectionSortByAttribute.LastUpdated
                    }
                  />
                </IconPopper>
              )}
              {currentAccount?.isAdmin &&
                !currentAccount?.institution?.featureFlags?.Novartis && (
                  <>
                    <IconButton
                      sx={{ marginRight: 1 }}
                      onClick={() => setIsReportModalOpen(true)}
                      data-testid="download-practice-report-button"
                    >
                      <Download />
                    </IconButton>
                    {isReportModalOpen && (
                      <PracticeReportDownloader
                        close={() => setIsReportModalOpen(false)}
                      />
                    )}
                  </>
                )}
              <NewPriorAuthButton>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Auth
                </Button>
              </NewPriorAuthButton>
            </LeftRightCenterV>
          </LeftRightSpreadCenterV>

          <LeftRightCenterV sx={{ marginBottom: "24px" }}>
            <AuthorizationsFilters
              currentAccount={currentAccount}
              expirationDate={state.filters.expirationDate}
              filterByUpcomingDateOfServiceDays={
                state.filters.filterByUpcomingDateOfServiceDays
              }
              filterByExpired={state.filters.filterByExpired}
              insuranceCompanies={insuranceCompanies}
              insuranceCompany={state.filters.insuranceCompanyId}
              location={state.filters.locationId}
              locations={currentAccount.institution?.locations ?? []}
              onChange={setState}
              portalId={state.filters.portalId}
              portals={portals}
              prescriber={state.filters.prescriberId}
              prescribers={prescribers}
              accounts={accounts}
              status={state.filters.status}
              statuses={defaultStatuses}
              accountId={state.filters.accountId ?? null}
            />
          </LeftRightCenterV>
        </FadeInPane>

        <LeftRightCenterAll>{/* <Spinner /> */}</LeftRightCenterAll>

        {!isWebExtensionConnected && enableBlendedPortalWorkflow ? (
          <ExtensionAlertBanner isDownloadExtensionBanner={true} />
        ) : null}
        <PatientsAuthorizationList
          setLimit={(newLimit) => setState({ type: "limit", value: newLimit })}
          variables={{
            filters: {
              filterByExpired: state.filters.filterByExpired,
              expirationDate:
                state.filters.expirationDate == null
                  ? null
                  : moment()
                      .add(
                        state.filters.expirationDate.offset,
                        state.filters.expirationDate.unit
                      )
                      .toISOString(),
              filterByUpcomingDateOfServiceDays:
                state.filters.filterByUpcomingDateOfServiceDays,
              firstName: state.filters.firstName,
              mrn: state.filters.MRN,
              insuranceCompanyId: state.filters.insuranceCompanyId,
              isArchived: state.filters.isArchived,
              lastName: state.filters.lastName,
              locationId: state.filters.locationId,
              portalId: state.filters.portalId,
              prescriberId: state.filters.prescriberId,
              AuthorizationId: state.filters.AuthorizationId,
              accountId:
                state.filters.accountId === "all"
                  ? null
                  : state.filters.accountId,
              statuses: state.filters.status
                ? [state.filters.status]
                : state.filters.statuses,
              jCode: state.filters.jCode,
              drugName: state.filters.drugName,
            },
            limit: state.limit,
            sortBy: state.sortBy,
          }}
          filterByExpired={state.filters.filterByExpired}
          accounts={accounts}
          setIsAllLoadingDone={setIsAllLoadingDone}
        />
      </Box>
    </>
  );
};
