import {
  Box,
  Card,
  CardContent,
  LoadingButton,
  Stack,
  Typography,
} from "@samacare/design";
import { alpha, useTheme } from "@samacare/design/core/styles";
import {
  EnahanceServiceIndicatorSingular,
  EnhancedServicesIcon,
} from "@samacare/component";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";
import { cleanDrugName } from "../../../../server/src/shared_code";
import { Authorization } from "@samacare/graphql";

export const PayerPolicyCard: React.FC<{ authorization: Authorization }> = ({
  authorization,
}) => {
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const drugName = (authorization.config?.PRIMARY_DRUG_NAME ?? "") as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const insuranceCompanyName = (authorization.config?.INSURANCE_COMPANY ??
    "") as string;

  if (!drugName || !insuranceCompanyName) {
    return <Box />;
  }

  const cleanedDrugName = cleanDrugName(drugName);
  const payerPolicyLink = authorization.drugConfiguration?.payerPolicyLink;

  if (!payerPolicyLink) {
    return <Box />;
  }

  return (
    <Card
      sx={{
        backgroundColor: alpha(theme.palette.secondary.main, 0.08),
        width: "100%",
        maxWidth: "600px",
        marginTop: 2,
      }}
    >
      <CardContent>
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" mr={1}>
            {`${cleanedDrugName} Payer Policy`}
          </Typography>
          <EnahanceServiceIndicatorSingular />
          <EnhancedServicesIcon sx={{ fontSize: 18 }} />
        </Stack>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Check the payer&apos;s requirements for authorization, step therapies,
          and diagnoses
        </Typography>
        <LoadingButton
          size="small"
          onClick={() => {
            window.open(`${payerPolicyLink}`, "_blank");
          }}
          endIcon={<OpenInNewIcon />}
          sx={{
            maxWidth: "fit-content",
            padding: 0,
            marginTop: "4px",
          }}
        >
          {`Review ${insuranceCompanyName}'s Policy For ${cleanedDrugName}`}
        </LoadingButton>
      </CardContent>
    </Card>
  );
};
