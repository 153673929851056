import { Theme, Components } from "@mui/material";

import colors, { type Colors } from "./colors";
import { createTheme, responsiveFontSizes } from "./styles";
import TextFieldTheme from "./TextField/TextField.theme";
import FormLabelTheme from "./FormLabel/FormLabel.theme";
import AlertTheme from "./Alert/Alert.theme";
import SelectTheme from "./Select/Select.theme";
import FormControlLabelTheme from "./FormControlLabel/FormControlLabel.theme";
import DataGridProTheme from "./DataGrid/DataGrid.theme";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

// https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
declare module "@mui/material/styles" {
  interface Gradients {
    primary: string;
    secondary: string;
  }

  interface Palette {
    colors: Colors;
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    colors?: Colors;
    neutral?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    veryLight?: string;
    mediumLight?: string;
    superLight?: string;
    ultraLight?: string;
    veryDark?: string;
  }
  interface SimplePaletteColorOptions {
    veryLight?: string;
    mediumLight?: string;
    superLight?: string;
    ultraLight?: string;
    veryDark?: string;
  }

  // eslint-disable-next-line no-shadow
  interface Theme {
    gradients: Gradients;
  }

  interface ThemeOptions {
    gradients: Gradients;
  }
}

// See: https://mui.com/material-ui/customization/theming/
const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    mode: "light",
    colors,
    common: {
      white: colors.N0,
      black: colors.N1000,
    },
    primary: {
      ultraLight: "#E8EAF6",
      superLight: "#C5CAE9",
      veryLight: "#9FA8DA",
      light: colors.P400,
      main: colors.P500,
      dark: colors.P700,
      contrastText: colors.N0,
    },
    secondary: {
      light: colors.C400,
      main: colors.C500,
      dark: colors.C800,
      contrastText: colors.N0,
    },
    neutral: {
      light: colors.N300,
      main: colors.N400,
      dark: colors.N500,
      contrastText: colors.N0,
    },
    info: {
      light: colors.LB500,
      main: colors.LB700,
      dark: colors.LB900,
      contrastText: colors.N0,
    },
    success: {
      veryLight: colors.G50,
      light: colors.G500,
      main: colors.G800,
      dark: colors.G900,
      contrastText: colors.N0,
    },
    warning: {
      veryLight: "#FFF4E5",
      mediumLight: "#ffecb3",
      light: colors.O600,
      main: colors.O800,
      dark: colors.O900,
      veryDark: colors.BR900,
      contrastText: colors.N0,
    },
    error: {
      ultraLight: "#FBEFEE",
      light: colors.R400,
      main: colors.R700,
      dark: colors.R800,
      contrastText: colors.N0,
    },
    grey: {
      "50": colors.N50,
      "100": colors.N100,
      "200": colors.N200,
      "300": colors.N300,
      "400": colors.N400,
      "500": colors.N500,
      "600": colors.N600,
      "700": colors.N700,
      "800": colors.N800,
      "900": colors.N900,
      A100: colors.NA100,
      A200: colors.NA200,
      A400: colors.NA400,
      A700: colors.NA700,
    },
    text: {
      primary: "rgba(0,0,0, 0.87)",
      secondary: "rgba(0,0,0, 0.6)",
      disabled: "rgba(0,0,0, 0.38)",
    },
    divider: "rgba(0,0,0,0.12)",
  },
  gradients: {
    primary: `linear-gradient(225deg, ${colors.P50} 33%, ${colors.P200} 100%)`,
    secondary: `linear-gradient(180deg, ${colors.P50} 0%, ${colors.P200} 100%)`,
  },
});

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 700;
const fontWeightBold = 900;

function getLetterSpacing(size = 16, scale = 1) {
  const value = (scale / 100) * size;
  return `${value.toFixed(2)}px`;
}

const typography: Partial<Theme["typography"]> = {
  fontFamily: "'Lato', 'Avenir', 'Helvetica', sans-serif",
  fontSize: 14,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  h1: {
    fontWeight: fontWeightLight,
    fontSize: theme.typography.pxToRem(78),
    letterSpacing: getLetterSpacing(78, -1.5),
  },
  h2: {
    fontWeight: fontWeightLight,
    fontSize: theme.typography.pxToRem(60),
    letterSpacing: getLetterSpacing(60, -0.5),
  },
  h3: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(48),
    letterSpacing: getLetterSpacing(48, 0),
  },
  h4: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(34),
    letterSpacing: getLetterSpacing(34, 0.25),
  },
  h5: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(24),
    letterSpacing: getLetterSpacing(24, 0),
  },
  h6: {
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: getLetterSpacing(20, 0.15),
  },
  subtitle1: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: getLetterSpacing(16, 0.15),
  },
  subtitle2: {
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: getLetterSpacing(14, 0.1),
  },
  body1: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: getLetterSpacing(16, 0.15),
  },
  body2: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: getLetterSpacing(16, 0.17),
  },
  caption: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: getLetterSpacing(12, 0.4),
  },
  overline: {
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: getLetterSpacing(12, 1),
  },
  button: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: getLetterSpacing(14, 0.46),
    lineHeight: 24 / 14,
    fontWeight: fontWeightMedium,
    textTransform: "none",
  },
};

// This imports the theme overrides for the components from the Component.theme.ts files
const components: Components = {
  // MuiOutlinedInput: OutlinedInputTheme(theme),
  MuiTextField: TextFieldTheme(),
  MuiFormLabel: FormLabelTheme(),
  MuiFormControlLabel: FormControlLabelTheme(theme),
  MuiSelect: SelectTheme(),
  MuiAlert: AlertTheme(theme),
  MuiDataGrid: DataGridProTheme(),
};

export default responsiveFontSizes(
  createTheme(theme, { components, typography }),
);

export type { Theme };
