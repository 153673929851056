import { push } from "connected-react-router";
import moment from "moment";
import { useState } from "react";
import * as React from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { Box, Flex, Tooltip } from "@@ui-kit";
import styled from "styled-components";
import { darken } from "polished";
import colors from "Resources/colors";
import _ from "lodash";

import { MdArchive as ArchiveIcon } from "@react-icons/all-files/md/MdArchive";
import { MdDelete as DeleteIcon } from "@react-icons/all-files/md/MdDelete";
import { MdVerticalAlignBottom as DownloadIcon } from "@react-icons/all-files/md/MdVerticalAlignBottom";
import { MdContentCopy as DuplicateIcon } from "@react-icons/all-files/md/MdContentCopy";
import { MdEdit as EditIcon } from "@react-icons/all-files/md/MdEdit";
import { MdRemove as RemoveFromExpiredIcon } from "@react-icons/all-files/md/MdRemove";
import { MdCached as ResendIcon } from "@react-icons/all-files/md/MdCached";
import { MdPlaylistAddCheck as MockResponseIcon } from "@react-icons/all-files/md/MdPlaylistAddCheck";
import { MdWarning as WarningIcon } from "@react-icons/all-files/md/MdWarning";
import { HiHashtag as TagIcon } from "@react-icons/all-files/hi/HiHashtag";
import ToggleArchiveButton from "@@components/ToggleArchiveButton";
import ToggleHideButton from "@@components/ToggleHideButton";
import DeleteAuthorizationButton from "@@components/DeleteAuthorizationButton";
import DownloadFormsButton from "@@components/DownloadFormsButton";
import { DuplicateAuthorizationButton } from "@@components/DuplicateAuthorizationButton";
import RequestResendButton from "@@components/RequestResendButton";
import ResubmitPortalAuthButton from "@@components/ResubmitPortalAuthButton";
import EditAndResubmitButton from "@@components/EditAndResubmitButton";
import { AssociateCorrespondenceModal } from "@@components/AssociateCorrespondenceModal";
import AuthorizationDetails from "@@components/MainList/AuthorizationDetails";
import { TextButton } from "@@components/TextButton";
import {
  Account,
  Authorization,
  Patient as PatientType,
  EnhancedServiecesEnum,
  PaOriginType,
} from "@samacare/graphql";
import {
  CurrentAccountQuery,
  CurrentAccountQueryVariables,
} from "@@generated/graphql";
import { SendingFailedRow } from "./SendingFailedRow";
import { FollowUpRow } from "./FollowUpRow";
import { AutomatedRow } from "./AutomatedRow";
import { RequestStatusButton } from "./RequestStatusButton";
import { Config, useConfig, useSamaCareBrandName } from "../../../hooks/config";
import { parseAsNonNull } from "../../../util/parsers";
import { isNovartisAuth } from "../../../util/isNovartisAuth";
import getAuthorizationRoute from "../../../util/getAuthorizationRoute";
import hasActionableFollowUp from "../../../util/hasActionableFollowUp";
import { Patient, Account as AccountInterface } from "./interfaces";

import { ConfirmationModalContent } from "../../../components/ConfirmationModalContent";
import LoadingButton from "../../../components/LoadingButton";
import Modal from "../../../components/Modal";
import { getAuthDescription, getInsuranceLabel } from "../../../util/authUtils";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { Stack } from "@samacare/design/core";
import { currentAccountQuery } from "../../../graphql/Account";
import { TagsList } from "../../../components/AuthorizationSharedComponents/TagsList";
import { TagsModal } from "./TagsModal";
import { AuthorizationAssigneeSelect } from "../../../components/AuthorizationSharedComponents/AssigneeSelect";
import { ActionBanners } from "../../../components/AuthorizationSharedComponents/ActionBanners";
import { LastChecked } from "../../../components/AuthorizationSharedComponents/LastChecked";
import ViewOnPortalLink from "../../../components/ViewOnPortalLink";
import { EnhancedServicesIcon } from "@samacare/component";

import { useEmulateFakeFaxResponse } from "@@hooks/useEmulateFakeFaxResponse";

const ActionButton = styled.button<{
  supported: boolean;
  disabled: boolean;
  color: string;
}>`
  display: flex;
  padding: 3px 5px;
  font-size: 13px;
  flex-direction: row;
  color: ${({ supported, theme }) => (supported ? "white" : theme.darkGray)};
  background-color: ${({ supported, theme, color }) =>
    supported ? theme.purple : color ?? "white"};
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ supported, theme }) => (supported ? theme.purple : theme.darkGray)};
  border-radius: 3px;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
    color: ${({ disabled, theme }) => (disabled ? theme.darkGray : "white")};
    background: ${({ disabled, theme, color }) =>
      disabled ? color ?? "white" : theme.darkGray};
    border: 1px solid ${({ theme }) => theme.darkGray};
  }

  &:focus {
    outline: 0;
  }
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  font-size: 18px;
  margin-bottom: 4px;
`;

export const ActionRequiredBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  white-space: normal;
  padding: 15px;
  margin-left: 5px;

  b {
    margin: 0 5px;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const HeaderContainer = styled.span`
  margin-right: 5px;
  font-size: 20px;
  font-weight: 700;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const canAuthorizationBeDeleted = (
  {
    createdById,
    createdAt,
  }: { createdById?: string | null; createdAt: string },
  accountId: string
): boolean =>
  createdById !== accountId &&
  moment(createdAt).isAfter(moment().subtract(10, "minutes"));

const ButtonWrap: React.VoidFunctionComponent<{
  Icon: React.ComponentType;
  text: string;
  cypressTag?: string;
  props?: React.ComponentProps<typeof ActionButton>;
}> = ({ Icon, text, props = {}, cypressTag }) => (
  <ActionButton data-cy={cypressTag} {...props}>
    <IconWrapper>
      <Icon />
    </IconWrapper>
    <TextWrapper>{text}</TextWrapper>
  </ActionButton>
);

const shouldShowMockResponseButton = (
  config: Config,
  currentAccount: Account,
  authorization: Authorization,
  isPortalAuth: boolean,
  isFormAuth: boolean
): boolean =>
  config.NODE_ENV !== "production" &&
  currentAccount.institution?.isTest &&
  currentAccount.isSamaUser &&
  authorization.submittedAt &&
  (isPortalAuth || isFormAuth) &&
  [
    config.CONSTANTS.AUTHORIZATION_STATUSES.SENDING,
    config.CONSTANTS.AUTHORIZATION_STATUSES.PENDING,
  ].includes(authorization.status);

const getActionButtons = ({
  authorization,
  config,
  currentAccount,
  filterByExpired,
  setIsShowTagsModal,
  emulateFakeFaxResponse,
}: {
  authorization: Authorization;
  config: Config;
  currentAccount: Account;
  filterByExpired: boolean;
  setIsShowTagsModal: (value: boolean) => void;
  emulateFakeFaxResponse: (value: object) => void;
}): React.ReactNode[] => {
  const isInPresubmissionState =
    authorization.status ===
    config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION;
  const isMultiparty =
    authorization.type ===
    config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.value;
  const isArchivable =
    config.CONSTANTS.AUTHORIZATION_STATUSES_ARCHIVABLE.includes(
      authorization.status
    );
  const isExternalAuth =
    authorization.type === config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.value;
  const buttons = [];
  const isNotPortalCopilotAuth =
    authorization?.paOrigin !== PaOriginType.Copilot ||
    authorization?.portal?.isLegacy;

  const isPortalAuth =
    authorization.type === config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.value;

  const isFormAuth =
    authorization.type === config.CONSTANTS.AUTHORIZATION_TYPES.FORM.value;

  const handleFakeResponseSubmit = () => {
    emulateFakeFaxResponse({
      variables: { authorizationId: authorization.id },
    });
  };

  if (
    isInPresubmissionState &&
    !canAuthorizationBeDeleted(authorization, currentAccount.id)
  ) {
    buttons.push(
      <DeleteAuthorizationButton
        key={`authDeleteButton-id-${authorization.id}`}
        id={authorization.id}
        step={authorization.formDetails?.currentStep ?? null}
        isReferral={authorization.isReferral}
        authorization={authorization}
      >
        <ButtonWrap
          cypressTag="actionDeleteAuth"
          Icon={DeleteIcon}
          text="Delete"
        />
      </DeleteAuthorizationButton>
    );
  }

  if (isArchivable) {
    buttons.push(
      <ToggleArchiveButton
        key={`authArchiveButton-id-${authorization.id}`}
        id={authorization.id}
        isArchived={authorization.isArchived}
      >
        <ButtonWrap
          Icon={ArchiveIcon}
          text={authorization.isArchived ? "Unarchive" : "Archive"}
          cypressTag="archive"
        />
      </ToggleArchiveButton>
    );
  }

  if (filterByExpired) {
    buttons.push(
      <ToggleHideButton
        key={`authHideButton-id-${authorization.id}`}
        id={authorization.id}
        hideFromExpiring={authorization.hideFromExpiring}
      >
        <ButtonWrap
          Icon={RemoveFromExpiredIcon}
          text={
            authorization.hideFromExpiring
              ? "Unhide from Expiring"
              : "Hide from Expiring"
          }
        />
      </ToggleHideButton>
    );
  }

  if (
    !isInPresubmissionState &&
    authorization.type !==
      config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL_EXTERNAL.value &&
    isNotPortalCopilotAuth
  ) {
    buttons.push(
      <DuplicateAuthorizationButton
        key={`duplicateAuthorizationButton-id-${authorization.id}`}
        authorization={authorization}
      >
        <ButtonWrap
          cypressTag="actionDuplicateAuthorization"
          Icon={DuplicateIcon}
          text="Duplicate"
        />
      </DuplicateAuthorizationButton>
    );
  }

  if (
    !_.isNil(authorization.submittedAt) &&
    !isMultiparty &&
    authorization.correspondences.filter(({ fileURL }) => !_.isNil(fileURL))
      .length > 0
  ) {
    buttons.push(
      <DownloadFormsButton
        key={`authDownloadFormsButton-id-${authorization.id}`}
        authorization={authorization}
      >
        <ButtonWrap
          Icon={DownloadIcon}
          text="Download Files"
          cypressTag="downloadFiles"
        />
      </DownloadFormsButton>
    );
  }

  const resendToHCP = isMultiparty && authorization.resendToHCP;
  const resendToPayer = isMultiparty && !authorization.resendToHCP;
  const resendText = resendToHCP
    ? "Resend to HCP"
    : resendToPayer
    ? "Resend to Payer"
    : "Resend";
  // we want to allow re-editability if denied
  if (
    !(
      [
        config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
        config.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT,
        config.CONSTANTS.AUTHORIZATION_STATUSES.MODIFIED_APPROVAL,
        config.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED,
        config.CONSTANTS.AUTHORIZATION_STATUSES.DUPLICATE,
        config.CONSTANTS.AUTHORIZATION_STATUSES.NO_AUTH_REQUIRED,
        config.CONSTANTS.AUTHORIZATION_STATUSES.VOIDED,
      ] as string[]
    ).includes(authorization.status) &&
    !isExternalAuth &&
    !isPortalAuth &&
    isNotPortalCopilotAuth
  ) {
    if (!isMultiparty) {
      buttons.push(
        <EditAndResubmitButton
          key={`editAndResubmitButton-id-${authorization.id}`}
          authorizationId={authorization.id}
        >
          <ButtonWrap
            cypressTag="actionEditResubmitAuthorization"
            Icon={EditIcon}
            text="Edit and Resend"
          />
        </EditAndResubmitButton>
      );
    }

    buttons.push(
      <RequestResendButton
        key={`authRequestResendButton-id-${authorization.id}`}
        authorizationId={authorization.id}
        insuranceCompanyId={authorization.insuranceCompany?.id}
        authorizationFormId={authorization.formId}
        isAdministrativeResend={
          authorization.status !==
          config.CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED
        }
      >
        <ButtonWrap
          cypressTag="actionResendAuthorization"
          Icon={ResendIcon}
          text={resendText}
        />
      </RequestResendButton>
    );
  }

  if (
    ![
      config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
      config.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT,
      ...config.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES,
    ].includes(authorization.status) &&
    !_.isNil(authorization.PortalId) &&
    authorization.type !==
      config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL_EXTERNAL.key &&
    authorization.paOrigin !== PaOriginType.Copilot
  ) {
    buttons.push(
      <ResubmitPortalAuthButton
        key={`ResubmitPortalAuthButton-id-${authorization.id}`}
        authorizationId={authorization.id}
      >
        <ButtonWrap
          cypressTag="actionResendAuthorization"
          Icon={ResendIcon}
          text="Resend"
        />
      </ResubmitPortalAuthButton>
    );
  }

  buttons.push(
    <div onClick={() => setIsShowTagsModal(true)}>
      <ButtonWrap Icon={TagIcon} text="Tags" cypressTag="tags" />
    </div>
  );

  if (
    shouldShowMockResponseButton(
      config,
      currentAccount,
      authorization,
      isPortalAuth,
      isFormAuth
    )
  ) {
    buttons.push(
      <ButtonWrap
        props={{ onClick: handleFakeResponseSubmit }}
        Icon={MockResponseIcon}
        text="Mock response"
      />
    );
  }
  return buttons;
};

const HighlightOnMouseover = styled(Flex)`
  cursor: pointer;
  padding: 5px;

  :hover {
    background-color: ${({ theme: { tileBackground } }) =>
      darken(0.1, tileBackground)};
    border-radius: 5px;
  }
`;

const SelectionAndAuthIdContainer = styled(Flex)`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  width: 280px;
`;

const Lotus = styled.img`
  height: auto;
  width: 25px;
  position: absolute;
  top: 0;
  left: -30px;
`;

const AuthorizationRow: React.FunctionComponent<{
  authorization: Authorization;
  currentAccount: Account;
  onClick: (authorization: Authorization) => void;
  filterByExpired: boolean;
  accounts: Account[];
}> = ({ authorization, currentAccount, onClick, filterByExpired }) => {
  const config = useConfig();
  const brandName = useSamaCareBrandName();
  const isMultiparty =
    authorization.type ===
    config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.value;
  const resendToHCP = isMultiparty && authorization.resendToHCP;
  const resendToPayer = isMultiparty && !authorization.resendToHCP;
  const resendText = resendToHCP
    ? "Resend to HCP"
    : resendToPayer
    ? "Resend to Payer"
    : "Resend";
  const [isAssociationModalOpen, setIsAssociationModalOpen] = useState(false);
  const [isShowTagsModal, setIsShowTagsModal] = useState(false);

  const isExternalAuth =
    authorization.type === config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.value;
  const isNovartis = isNovartisAuth(currentAccount as AccountInterface);

  const { emulateFakeFaxResponse } = useEmulateFakeFaxResponse();

  let content = null;
  if (authorization.requiresAssociationReview) {
    content = (
      <AutomatedRow
        authorization={authorization}
        onClick={() => setIsAssociationModalOpen(true)}
      />
    );
  } else if (hasActionableFollowUp(authorization)) {
    content = <FollowUpRow authorization={authorization} />;
  } else if (
    authorization.status ===
    config.CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED
  ) {
    content = (
      <SendingFailedRow
        authorizationId={authorization.id}
        patient={parseAsNonNull(authorization.patient as PatientType)}
        isNovartis={isNovartis}
        resendText={resendText}
        insuranceCompanyId={authorization.insuranceCompany?.id}
        authorizationFormId={authorization.formId}
      />
    );
  } else {
    const actionButtons = getActionButtons({
      authorization,
      config,
      currentAccount,
      filterByExpired,
      setIsShowTagsModal,
      emulateFakeFaxResponse,
    });

    const warningMessages = [];
    if (isExternalAuth) {
      warningMessages.push(
        <ButtonWrap
          Icon={WarningIcon}
          text="Unable to check status for external authorizations. Please update status manually."
          props={{ disabled: true, color: colors.pastelYellow }}
          cypressTag="unableToCheckStatusForExternalAuthWarning"
        />
      );
    }

    const insuranceLabel = getInsuranceLabel(authorization);

    const hasStaticWarnings = warningMessages.length > 0;
    const hasTags = authorization.tags.length > 0;
    const isShowSecondRow = hasTags || hasStaticWarnings;

    const lastCheckedContent = <LastChecked authorization={authorization} />;
    const secondRowLastCheckedContent = isShowSecondRow
      ? lastCheckedContent
      : null;
    const thirdRowLastCheckedContent = isShowSecondRow
      ? null
      : lastCheckedContent;
    const hasSecondRowLeftSide =
      secondRowLastCheckedContent != null || hasStaticWarnings;

    const containsPaRelatedEnhancedServices =
      (authorization.enhancedServices ?? []).includes(
        EnhancedServiecesEnum.AuthInform
      ) ||
      (authorization.enhancedServices ?? []).includes(
        EnhancedServiecesEnum.PaBoost
      );

    content = (
      <>
        {/* First Row */}
        <HighlightOnMouseover
          data-cy="componentAuthRow"
          onClick={() => onClick(authorization)}
          marginBottom="5px"
        >
          <Flex
            alignItems="center"
            width={0.5625}
            justifyContent="space-between"
          >
            <Box
              aria-label="Associated Insurance Company"
              width={1 / 3}
              paddingRight="5px"
              sx={{ position: "relative" }}
            >
              {authorization.type !== "external" &&
              authorization.type !== "portal_external" ? (
                <Tooltip content={`Submitted through ${brandName}`}>
                  <Lotus src="../../../assets/lotus-icon.svg" />
                </Tooltip>
              ) : null}
              {insuranceLabel}
            </Box>
            <Box>
              <ViewOnPortalLink authorization={authorization} />
            </Box>
            <Box
              aria-label="Authorization Description"
              width={1 / 3}
              paddingRight="5px"
              alignItems="center"
              display="flex"
            >
              {getAuthDescription(authorization)}
              {containsPaRelatedEnhancedServices && (
                <EnhancedServicesIcon sx={{ fontSize: 20 }} />
              )}
            </Box>
            <Box aria-label="Authorization Status" width={1 / 3}>
              <RequestStatusButton authorization={authorization} />
            </Box>
          </Flex>
          <Box aria-label="Authorization Notes" width={0.4375}>
            <AuthorizationDetails authorization={authorization} />
          </Box>
        </HighlightOnMouseover>

        {/* Second Row */}
        {isShowSecondRow && (
          <Flex sx={{ padding: "5px" }}>
            {hasSecondRowLeftSide && (
              <Flex
                alignItems="center"
                width={0.5625}
                justifyContent="space-between"
              >
                <Flex
                  width={secondRowLastCheckedContent !== null ? 2 / 3 : 1}
                  flexWrap="wrap"
                  gap={1}
                  paddingRight={1}
                >
                  {warningMessages.map((warning, i) => (
                    <Box key={`warning_index_${i}`}>{warning}</Box>
                  ))}
                </Flex>
                {secondRowLastCheckedContent !== null && (
                  <Flex width={1 / 3}>{secondRowLastCheckedContent}</Flex>
                )}
              </Flex>
            )}
            <Flex
              width={hasSecondRowLeftSide ? 0.4375 : 1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <TagsList
                auth={authorization}
                onClick={() => setIsShowTagsModal(true)}
              />
            </Flex>
          </Flex>
        )}
        {/* Third Row */}
        <Flex sx={{ padding: "5px" }}>
          <Flex
            alignItems="center"
            width={0.5625}
            justifyContent="space-between"
          >
            <Flex
              width={thirdRowLastCheckedContent ? 2 / 3 : 1}
              flexWrap="wrap"
              gap={1}
            >
              {actionButtons.map((actionButton, i) => (
                <Box key={`action_button_index_${i}`}>{actionButton}</Box>
              ))}
            </Flex>
            {thirdRowLastCheckedContent && (
              <Box width={1 / 3}>{thirdRowLastCheckedContent}</Box>
            )}
          </Flex>
          <Flex width={0.4375} justifyContent="flex-end">
            <Stack direction="column" alignItems="flex-end">
              <SelectionAndAuthIdContainer aria-label="Assignee">
                <AuthorizationAssigneeSelect authorization={authorization} />
              </SelectionAndAuthIdContainer>
            </Stack>
          </Flex>
        </Flex>
        <ActionBanners
          authorization={authorization}
          currentAccount={currentAccount}
        />
      </>
    );
  }

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  return (
    <>
      <Flex aria-label="Authorization row" paddingX="6px" paddingY="5px">
        <Flex flexDirection="column" sx={{ width: "100%" }}>
          {content}
        </Flex>
      </Flex>
      {isAssociationModalOpen && (
        <AssociateCorrespondenceModal
          allowDisassociation
          authorization={{
            ...authorization,
            patient: parseAsNonNull(
              authorization.patient
            ) as unknown as PatientType,
          }}
          closeModal={() => setIsAssociationModalOpen(false)}
          openConfirmationModal={() => setShowConfirmationModal(true)}
          allowChanges
        />
      )}
      {isShowTagsModal && (
        <TagsModal
          auth={authorization}
          onClose={() => setIsShowTagsModal(false)}
        />
      )}
      {showConfirmationModal && (
        <Modal open>
          <ConfirmationModalContent
            messages={[
              "Thank you for making our system smarter!",
              "We will update this shortly from our end.",
            ]}
          />

          <ButtonContainer>
            <LoadingButton
              style={{ padding: "10px 25px" }}
              key="undoAutoAssociatedResponse"
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              Done
            </LoadingButton>
          </ButtonContainer>
        </Modal>
      )}
    </>
  );
};

const PatientTileContainer = styled.div.attrs({
  "aria-label": "Authorizations for patient",
})`
  position: relative;
  background: ${({ theme: { tileBackground } }) => tileBackground};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-height: 170px;
  padding: 8px;
`;

export type PatientTileProps = React.ComponentProps<typeof PatientTile>;

export const UnconnectedPatientTile: React.VoidFunctionComponent<{
  authorizations: Authorization[] | null;
  patient: Patient;
  filterByExpired: boolean;
  toAuthorization: (options: {
    id: string;
    step?: number;
    prefix?: string;
  }) => void;
  fetchMore: () => void;
  accounts: Account[];
}> = ({
  authorizations,
  fetchMore,
  patient,
  toAuthorization,
  filterByExpired,
  accounts,
}) => {
  const { data, error, loading } = useQuery<
    CurrentAccountQuery,
    CurrentAccountQueryVariables
  >(currentAccountQuery);

  if (loading) {
    return <div aria-label="Loading">Loading...</div>;
  }

  if (error) {
    return <div aria-label="Error">Failed to load patients.</div>;
  }

  const currentAccount = data?.currentAccount ?? null;
  if (currentAccount == null) {
    return <div>Failed to load patients.</div>;
  }

  return (
    <PatientTileContainer>
      <ErrorBoundary name={`patient_${patient.id}`}>
        <Flex padding="16px" flexDirection="column">
          <Flex
            justifyContent="flex-start"
            marginBottom="12px"
            alignItems="center"
          >
            <HeaderContainer>
              {patient.id !== null
                ? `${patient.lastName}, ${patient.firstName}`
                : "No Patient Selected"}
            </HeaderContainer>
            {patient.id !== null && (
              <HeaderContainer>
                ({moment(patient.dob).toDate().toLocaleDateString()})
              </HeaderContainer>
            )}
          </Flex>
          {authorizations !== null && (
            <Box>
              {authorizations.map((authorization, i) => {
                const isLast = i === authorizations.length - 1;

                return (
                  <div
                    key={authorization.id}
                    style={{ marginBottom: isLast ? undefined : "12px" }}
                  >
                    <AuthorizationRow
                      authorization={authorization}
                      currentAccount={currentAccount as Account}
                      filterByExpired={filterByExpired}
                      onClick={({ id, formDetails }) =>
                        toAuthorization({
                          id,
                          step: formDetails?.currentStep ?? undefined,
                          prefix: getAuthorizationRoute(authorization),
                        })
                      }
                      accounts={accounts}
                    />
                  </div>
                );
              })}
            </Box>
          )}
          {authorizations !== null && (
            <Box>
              {!_.isNil(patient.authorizationsCount) &&
                patient.authorizationsCount > authorizations.length && (
                  <TextButton onClick={fetchMore}>
                    +{patient.authorizationsCount - authorizations.length} more
                    authorizations (click to view)
                  </TextButton>
                )}
            </Box>
          )}
          {authorizations === null && (
            <Flex aria-label="Authorization row" paddingX="6px" paddingY="5px">
              <Flex flexDirection="column" sx={{ width: "100%" }}>
                No Authorizations
              </Flex>
            </Flex>
          )}
        </Flex>
      </ErrorBoundary>
    </PatientTileContainer>
  );
};

export const PatientTile = connect(
  () => ({}),
  (dispatch) => ({
    toAuthorization({
      id,
      step = 1,
      prefix = "",
    }: {
      id: string;
      step?: number;
      prefix?: string;
    }) {
      dispatch(push(`${prefix}?step=${step.toString()}&id=${id}`));
    },
  })
)(UnconnectedPatientTile);
