import { DialogActions, useTheme } from "@samacare/design";

interface DialogFooterProps {
  color?: "warning";
}
export const DialogFooter: React.FC<DialogFooterProps> = (props) => {
  const theme = useTheme();
  return (
    <DialogActions
      sx={{
        backgroundColor:
          props.color === "warning"
            ? theme.palette.warning.veryLight
            : "#e8e7fd",
        py: 1.25,
        px: 3,
        justifyContent: "flex-end",
      }}
    >
      {props.children}
    </DialogActions>
  );
};
