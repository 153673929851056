import { Authorization, PaOriginType } from "@samacare/graphql";

export const isDisplayPortalSpecificDetails = (
  authorization: Authorization
): boolean =>
  authorization.type ===
    window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.value &&
  authorization.paOrigin !== PaOriginType.Copilot;

export const isDisplayWebExtensionDetails = (
  authorization: Authorization
): boolean =>
  authorization.type ===
    window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.value &&
  authorization.paOrigin === PaOriginType.Copilot;
