// eslint-disable-next-line no-shadow
export enum PatientSourceType {
  // Manual addition by a user in the web interface
  ManualWeb = "manualWeb",

  // Manual addition by a user using Copilot
  ManualCopilot = "manualCopilot",

  // Manual addition by a user using Copilot with autodetect (differentiates users created using autodetect logic)
  ManualCopilotAutodetect = "manualCopilotAutodetect",

  // Patient record added via SFTP ingest jobs
  SFTP = "sftp",

  // Patient record added manually through the web application's upload process (ingest jobs)
  WebAppUpload = "webAppUpload",

  // Patient record imported directly from a local file
  LocalFile = "localFile",

  // Patient record added from Modernizing Medicine EMR integration
  ModernizingMedicine = "modernizingMedicine",

  // Patient record added from NextGen EMR integration
  NextGen = "nextGen",

  // Patient record added from Redox EMR integration
  Redox = "redox",

  // Patient record added from the legacy Redox implementation (WeInfuse)
  RedoxLegacy = "redoxLegacy",

  // Patient record added from EhrBridge EMR integration
  EhrBridge = "ehrBridge",

  // Patient record added from OncoEmr integration
  OncoEmr = "oncoEmr",
}
