import {
  Query,
  QueryPrescribersFindAllArgs,
  Prescriber,
} from "@samacare/graphql";
import { ApolloQueryResult, gql, useQuery } from "@apollo/client";

const getAllPrescribersByInstitutionQuery = gql`
  query getAllPrescribersByInstitution($InstitutionIdOverride: String) {
    prescribersFindAll(InstitutionIdOverride: $InstitutionIdOverride) {
      id
      firstName
      lastName
      label
      specialtyDescription
      specialtyCode
      NPI
      TIN
      DEA
      licenseNumber
    }
  }
`;

export const useInstitutionPrescribers = (): [
  serviceCodes: Prescriber[],
  loading: boolean,
  refetch: () => Promise<ApolloQueryResult<Query>>,
] => {
  const { data, loading, refetch } = useQuery<
    Query,
    QueryPrescribersFindAllArgs
  >(getAllPrescribersByInstitutionQuery, {
    // tried adding this as a refetch query on a mutation but it didn't always work
    fetchPolicy: "network-only",
  });

  return [data?.prescribersFindAll ?? [], loading, refetch];
};
