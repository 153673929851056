import { useEffect } from "react";
import { Location } from "@samacare/graphql";
import { useFindLocationByNpi } from "@samacare/hooks-data";
import { SearchByNpiPane } from "@samacare/form2";

interface SearchLocationByNpiPaneProps {
  onFound: (location: Location, alreadyExists: boolean) => void;
  onError: (error: string, NPI: string) => void;
  onClose: () => void;
  isWebExtension?: boolean;
}
export const SearchLocationByNpiPane: React.FC<SearchLocationByNpiPaneProps> = (
  props,
) => {
  const { onFound, isWebExtension } = props;
  const { findLocationByNpi, location, errorMessage, alreadyExists } =
    useFindLocationByNpi();

  useEffect(() => {
    if (location) {
      onFound(location, alreadyExists);
    }
  }, [location, onFound, alreadyExists]);

  return (
    <SearchByNpiPane
      isWebExtension={isWebExtension}
      objectName="location"
      errorMessage={errorMessage}
      onFind={findLocationByNpi}
      onError={props.onError}
      onClose={props.onClose}
    />
  );
};
