import _ from "lodash";
import PropTypes from "prop-types";

const config = CONFIG.DEFAULT_FIELDS;
const resultsPropTypeObj = _.reduce(
  CONFIG.DEFAULT_FIELDS,
  (props, { key }) => {
    if (
      _.includes(
        [
          config.PATIENT_GENDER_OTHER.key,
          config.PATIENT_GENDER_FEMALE.key,
          config.PATIENT_GENDER_MALE.key,
        ],
        key
      )
    ) {
      return { ...props, [key]: PropTypes.bool };
    }
    if (key === config.PATIENT_AGE.key) {
      return { ...props, [key]: PropTypes.number };
    }
    return { ...props, [key]: PropTypes.string };
  },
  {}
);
export const resultsPropType = PropTypes.exact({
  ...resultsPropTypeObj,
  InsuranceCompanyId: PropTypes.number,
});

const latestIntegrationMessageButtonsPropType = PropTypes.exact({
  value: PropTypes.bool,
  label: PropTypes.string,
});

const optionsPropTypes = PropTypes.exact({
  value: PropTypes.string,
  label: PropTypes.string,
});

const dataPropTypes = PropTypes.exact({
  display: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  options: PropTypes.arrayOf(optionsPropTypes),
  key: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
});

export const latestIntegrationMsgAndCustomPortalStepsPropTypes =
  PropTypes.exact({
    isTerminal: PropTypes.bool,
    componentType: PropTypes.string,
    title: PropTypes.string,
    buttons: PropTypes.arrayOf(latestIntegrationMessageButtonsPropType),
    length: PropTypes.number,
    imageURL: PropTypes.string,
    requiresHardReset: PropTypes.bool,
    skipSaving: PropTypes.bool,
    key: PropTypes.string,
    id: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.arrayOf(dataPropTypes),
    display: PropTypes.string,
    value: PropTypes.string,
    dataHeaders: PropTypes.arrayOf(PropTypes.string),
    maxQuantity: PropTypes.number,
    subType: PropTypes.string,
    isDraft: PropTypes.string,
    errorGroup: PropTypes.string,
    messageTimestamp: PropTypes.string,
    __typename: PropTypes.string,
  });

export const locationPropTypes = PropTypes.exact({
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  state: PropTypes.string,
});

const featureFlagsPropTypes = PropTypes.exact({
  __typename: PropTypes.string,
});

const referralFormsPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  pdfURL: PropTypes.string,
  description: PropTypes.string,
  __typename: PropTypes.string,
});

const institutionPropTypes = PropTypes.exact({
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  practicingSpecialty: PropTypes.string,
  featureFlags: featureFlagsPropTypes,
  ReferralForms: PropTypes.arrayOf(referralFormsPropTypes),
  __typename: PropTypes.string,
});

export const accountPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  phone: PropTypes.string,
  extension: PropTypes.string,
  fax: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isSamaUser: PropTypes.bool,
  isAdmin: PropTypes.bool,
  email: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isDeactivated: PropTypes.bool,
  institution: institutionPropTypes,
  __typename: PropTypes.string,
});

const formDetailsPropTypes = PropTypes.exact({
  currentStep: PropTypes.number,
});

const HCPCSCodesPropTypes = PropTypes.exact({
  code: PropTypes.string,
  quantity: PropTypes.string,
  modifier: PropTypes.string,
});

const patientPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  InsuranceCompanyId: PropTypes.string,
  institutionPatientId: PropTypes.string,
  dob: PropTypes.string,
  __typename: PropTypes.string,
});

const insuranceCompanyPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  responseRangeMax: PropTypes.number,
  responseRangeMin: PropTypes.number,
  __typename: PropTypes.string,
});

const createdByPropTypes = PropTypes.exact({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  __typename: PropTypes.string,
});

const latestNotePropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  note: PropTypes.string,
  isHidden: PropTypes.bool,
  createdBy: createdByPropTypes,
  __typename: PropTypes.string,
});

const formFieldConfigPropTypes = PropTypes.exact({
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  __typename: PropTypes.string,
});

const authorizedProceduresPropTypes = PropTypes.exact({
  title: PropTypes.string,
  value: PropTypes.string,
});

const correspondencePropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  fileURL: PropTypes.string,
  mimeType: PropTypes.string,
  isLatest: PropTypes.bool,
  authorizedProcedures: PropTypes.arrayOf(authorizedProceduresPropTypes),
  code: PropTypes.string,
  type: PropTypes.string,
  isHidden: PropTypes.bool,
  InstitutionId: PropTypes.string,
  patientMemberId: PropTypes.string,
  patientLastName: PropTypes.string,
  suggestedStatus: PropTypes.string,
  fileAWSKey: PropTypes.string,
});

const attachmentPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  awsKey: PropTypes.string,
});

const integratedEMRProcedurePropTypes = PropTypes.exact({
  name: PropTypes.string,
  code: PropTypes.string,
  icd: PropTypes.string,
  quantity: PropTypes.string,
});

const integratedEMRFilePropTypes = PropTypes.exact({
  key: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
});

const followUpPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  isReviewed: PropTypes.bool,
  type: PropTypes.string,
});

export const authorizationPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  config: resultsPropType,
  status: PropTypes.string,
  formId: PropTypes.string,
  formDetails: formDetailsPropTypes,
  updatedAt: PropTypes.string,
  submittedAt: PropTypes.string,
  createdByUser: PropTypes.bool,
  createdAt: PropTypes.string,
  version: PropTypes.number,
  InstitutionId: PropTypes.string,
  institutionAuthorizationId: PropTypes.string,
  isArchived: PropTypes.bool,
  displayReviewWarning: PropTypes.bool,
  hideFromExpiring: PropTypes.bool,
  PortalId: PropTypes.string,
  ICDs: PropTypes.arrayOf(PropTypes.string),
  HCPCSCodes: PropTypes.arrayOf(HCPCSCodesPropTypes),

  isReferral: PropTypes.bool,
  portalTitle: PropTypes.string,
  requiresAssociationReview: PropTypes.bool,
  emailReminderAt: PropTypes.string,
  emailReminderDescription: PropTypes.string,
  customPortalSteps: PropTypes.arrayOf(
    latestIntegrationMsgAndCustomPortalStepsPropTypes
  ),
  patient: patientPropTypes,
  insuranceCompany: insuranceCompanyPropTypes,
  latestCorrespondence: correspondencePropTypes,
  correspondences: PropTypes.arrayOf(correspondencePropTypes),
  latestNote: latestNotePropTypes,
  formFieldConfig: formFieldConfigPropTypes,
  submittedFormURL: PropTypes.string,
  submissionPhoneNumber: PropTypes.string,
  integratedEMRFilesDownloaded: PropTypes.arrayOf(integratedEMRFilePropTypes),
  integratedEMRProcedures: PropTypes.arrayOf(integratedEMRProcedurePropTypes),
  attachments: PropTypes.arrayOf(attachmentPropTypes),
  followUp: followUpPropTypes,
  __typename: PropTypes.string,
});
