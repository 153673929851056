import { Alert } from "@samacare/design/core";
import { useTheme, alpha } from "@samacare/design/core/styles";

export const FullBvNotificationAlert = ({
  patientId,
}: {
  patientId?: string;
}) => {
  const theme = useTheme();
  if (!patientId) {
    return null;
  }

  return (
    <Alert
      severity="info"
      sx={{
        width: "600px",
        background: alpha(theme.palette.secondary.dark, 0.08),
        "& .MuiAlert-icon": {
          color: theme.palette.secondary.dark,
        },
      }}
    >
      SamaCare investigates drug specific coverage, requirements and financials
      for this drug as an enhanced service.
    </Alert>
  );
};
