import gql from "graphql-tag";
import { PureComponent } from "react";
import styled from "styled-components";
import colors from "Resources/colors";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { withAlert } from "react-alert";

import { MfaSetupModalBody } from "../MfaSetupModal";
import { withCurrentAccount } from "../../graphql/Account";
import { WhiteButton } from "@@ui-kit/WhiteButton";

import Modal from "../Modal";
import { RequestNewPortalIntegration } from "./RequestNewPortalIntegration";
import { DownloadExtensionDialog } from "./components/DownloadExtensionDialog";
import { INSTRUCTION_MODAL_SHOWED_TIMES } from "../../util/constants";
import { ExtensionInstructionDialog } from "./components/ExtensionInstructionDialog";
import { PortalsDialog } from "./components/PortalsDialog";

const BtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const SetupLaterText = styled.div`
  color: ${colors.purple};
  margin-right: 24px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const PortalType = {
  LIVE_VIEWER: "LIVE_VIEWER",
  WEB_EXTENSION: "WEB_EXTENSION",
  AVAILITY_API: "AVAILITY_API",
};

export const reportMissingModalMutation = gql`
  mutation requestNewPortalIntegration(
    $insuranceCompany: String!
    $portalUrl: String!
    $usageFrequency: String!
  ) {
    requestNewPortalIntegration(
      input: {
        insuranceCompany: $insuranceCompany
        portalUrl: $portalUrl
        usageFrequency: $usageFrequency
      }
    ) {
      success
    }
  }
`;

export class BasePortalSelectionModal extends PureComponent {
  state = {
    selectedPortal: null,
    isReportingMissingPortal: false,
    isMfaPaneShown: false,
    showDownloadExtensionDialog: false,
    instructionModalShowedTimes: localStorage.getItem(
      INSTRUCTION_MODAL_SHOWED_TIMES
    ),

    showInstructionModalDialog: false,
    showNewPortalsModalDialog: false,
  };

  componentDidMount() {
    const { isWebExtensionConnected, isCypressMockExtension } = this.props;
    const { instructionModalShowedTimes } = this.state;

    if (!isWebExtensionConnected) {
      this.setState({ showDownloadExtensionDialog: true });
    }

    if (isWebExtensionConnected) {
      this.setState({
        showInstructionModalDialog: instructionModalShowedTimes < 3,
        showNewPortalsModalDialog: instructionModalShowedTimes >= 3,
      });
    }
    if (isCypressMockExtension) {
      this.setState({
        showInstructionModalDialog: false,
        showNewPortalsModalDialog: true,
      });
    }
  }

  handlePostPortalPreviewSelection = () => {
    const { onSelect } = this.props;
    const { selectedPortal } = this.state;
    onSelect(selectedPortal);
  };

  handleMfaBackClick = () => {
    this.setState({ isMfaPaneShown: false });
  };

  handleShowNewPortalModal = () => {
    this.setState({
      showInstructionModalDialog: false,
      showNewPortalsModalDialog: true,
    });
  };

  handlePortalSelect = async (portal) => {
    const { onSelect } = this.props;
    await onSelect(portal);
  };

  render() {
    const {
      alert,
      closeModal,
      open,
      client,
      account,
      portalAuthFormData,
      isCypressMockExtension,
    } = this.props;
    const {
      selectedPortal,
      isReportingMissingPortal,
      isMfaPaneShown,
      showDownloadExtensionDialog,
      showInstructionModalDialog,
      showNewPortalsModalDialog,
    } = this.state;

    let screen;
    let modalTitle = "Portal Submission";
    switch (true) {
      case isReportingMissingPortal:
        screen = "report_missing_portal";
        modalTitle = "Report Missing Modal";
        break;
      case showDownloadExtensionDialog:
        screen = "download_extension";
        break;
      case showInstructionModalDialog:
        screen = "extension_instruction";
        break;
      case showNewPortalsModalDialog:
        screen = "new_portals_dialog";
        break;
      case isMfaPaneShown:
        screen = "mfa_setup";
        break;
      default:
    }

    return (
      <Modal
        id="pendo_portalSelectionModal"
        header={modalTitle}
        open={open}
        onClick={closeModal}
        headerStyleOverride={{ textAlign: "left" }}
      >
        {screen === "download_extension" && (
          <DownloadExtensionDialog
            open={showDownloadExtensionDialog}
            onClose={closeModal}
            webExtensionUrl={CONFIG.CONSTANTS.WEB_EXTENSION_URL}
          />
        )}
        {screen === "extension_instruction" && (
          <ExtensionInstructionDialog
            open={showInstructionModalDialog}
            onClose={closeModal}
            onShowNewPortalModal={this.handleShowNewPortalModal}
          />
        )}
        {screen === "new_portals_dialog" && (
          <PortalsDialog
            open={showNewPortalsModalDialog}
            onClose={closeModal}
            portalAuthFormData={portalAuthFormData}
            handlePortalSelect={this.handlePortalSelect}
            isCypressMockExtension={isCypressMockExtension}
          />
        )}
        {screen === "report_missing_portal" && (
          <RequestNewPortalIntegration
            onCancel={() => this.setState({ isReportingMissingPortal: false })}
            onSubmit={async (input) => {
              try {
                const response = await client.mutate({
                  mutation: reportMissingModalMutation,
                  variables: input,
                });
                if (!response.data.requestNewPortalIntegration.success) {
                  throw new Error("Failed to submit request.");
                }
                alert.info("Thanks! We've received your request.");
                closeModal();
              } catch {
                alert.error(
                  "Sorry, an unexpected error occurred. Try refreshing the page, and if the issue persists, contact us for help."
                );
              }
            }}
          />
        )}
        {screen === "mfa_setup" &&
          selectedPortal.portalType === PortalType.WEB_EXTENSION && (
            <MfaSetupModalBody
              isOpen={isMfaPaneShown}
              portal={selectedPortal}
              currentAccount={account}
              onDoneClick={this.handlePostPortalPreviewSelection}
            >
              <BtnsWrapper>
                <WhiteButton onClick={this.handleMfaBackClick}>
                  Back
                </WhiteButton>
                <SetupLaterText onClick={this.handlePostPortalPreviewSelection}>
                  Set up later
                </SetupLaterText>
              </BtnsWrapper>
            </MfaSetupModalBody>
          )}
      </Modal>
    );
  }
}

export const PortalSelectionModal = compose(withCurrentAccount)(
  withApollo(withAlert()(BasePortalSelectionModal))
);

export default PortalSelectionModal;
