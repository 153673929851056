import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseButton from "../BaseButton";
import Modal from "../Modal";
import List from "@samacare/design/core/List";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemButton,
  ListItemText,
  Typography,
} from "@samacare/design";
import { Account, Authorization } from "@samacare/graphql";
import {
  ListRedoxPatientDocumentReferencesQueryQuery,
  ListRedoxPatientDocumentReferencesQueryQueryVariables,
  GetRedoxPatientDocumentReferenceQuery,
  GetRedoxPatientDocumentReferenceQueryVariables,
} from "@@generated/graphql";
import { useSelector } from "../../configureStore";
import { useLazyQuery } from "@apollo/client";
import listRedoxPatientDocumentReferencesQuery from "../../graphql/ListRedoxPatientDocumentReferencesQuery.gql";

import getRedoxPatientDocumentReference from "../../graphql/GetRedoxPatientDocumentReference.gql";

import { useAttachRedoxDocument } from "@@hooks/useAttachRedoxDocument";
import { IntegrationFileAttachButtonContainer } from "AuthorizationSharedComponents/IntegrationFileAttachButtonContainer";
import { LeftRightCenterAll } from "@samacare/component";
import PdfViewer from "@@components/PdfViewer";

export type RedoxFileType = {
  id: string;
  description: string;
  contentType: string;
  data?: string | null;
  updatedAt?: string;
};

export const RedoxFilesSuggestionModal: React.VoidFunctionComponent<{
  authorization: Authorization;
  account?: Account;
  maxFileSizeMB?: number;
}> = ({ authorization, maxFileSizeMB }) => {
  const attachments = useSelector((state) => state.form.attachments);
  const [open, setOpen] = useState(false);

  const [
    fetchDocuments,
    { data: documentReferencesData, loading: documentListLoading },
  ] = useLazyQuery<
    ListRedoxPatientDocumentReferencesQueryQuery,
    ListRedoxPatientDocumentReferencesQueryQueryVariables
  >(listRedoxPatientDocumentReferencesQuery, {
    variables: { patientId: +authorization.patient!.id },
  });

  const [
    fetchDocumentReference,
    { data: singleDocumentData, loading: singleDocumentLoading },
  ] = useLazyQuery<
    GetRedoxPatientDocumentReferenceQuery,
    GetRedoxPatientDocumentReferenceQueryVariables
  >(getRedoxPatientDocumentReference);

  const { selectedFile, selectedIndex, selectFile, attachSelectedFile } =
    useAttachRedoxDocument(authorization, maxFileSizeMB);

  const handleOpenModal = async () => {
    setOpen(true);
    await fetchDocuments();
  };

  useEffect(() => {
    if (singleDocumentData?.getRedoxPatientDocumentReference && selectedFile) {
      selectFile(
        {
          ...selectedFile,
          data: singleDocumentData.getRedoxPatientDocumentReference.data,
        },
        selectedIndex ?? 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDocumentData]);

  // filter out documents that have already been attached to the authorization, sort by updatedAt date
  const filteredDocuments = useMemo(() => {
    return _.sortBy(
      _.filter(
        documentReferencesData?.listRedoxPatientDocumentReferences || [],
        (file) => !_.find(attachments, { awsKey: file?.id })
      ),
      ({ updatedAt }: { updatedAt: string }) => updatedAt
    ).reverse() as RedoxFileType[];
  }, [documentReferencesData, attachments]);

  const onSelectFile = useCallback(
    async (file: RedoxFileType, i: number) => {
      if (!file.data) {
        await fetchDocumentReference({
          variables: {
            documentId: file.id,
            patientId: +authorization.patient!.id,
          },
        });
      } else {
        selectFile(file, i);
      }
    },
    [fetchDocumentReference, selectFile]
  );

  const renderLoading = () => {
    return (
      <LeftRightCenterAll sx={{ height: "400px" }}>
        <CircularProgress />
        <Typography variant="body1" color="primary" mt={2}>
          Loading PDFs...
        </Typography>
      </LeftRightCenterAll>
    );
  };

  const renderDocuments = () => {
    // If we’re loading the main list or a single doc, show spinner
    if (documentListLoading || singleDocumentLoading) {
      return renderLoading();
    }

    if (filteredDocuments.length === 0) {
      return (
        <LeftRightCenterAll sx={{ height: "400px" }}>
          <Typography variant="body1" color="textSecondary">
            No PDFs available from the EMR
          </Typography>
        </LeftRightCenterAll>
      );
    }

    return (
      <Box sx={{ display: "flex", gap: "15px" }}>
        <List sx={{ height: "400px", width: "250px", overflowY: "auto" }}>
          {_.map(filteredDocuments, (file, i) => (
            <ListItemButton
              key={`filtered_file_${file?.id}`}
              selected={selectedIndex === i}
              onClick={async () => onSelectFile(file, i)}
            >
              <ListItemText
                primary={file?.description}
                secondary={file?.updatedAt}
              />
            </ListItemButton>
          ))}
        </List>
        {selectedFile?.data && (
          <PdfViewer
            fileURL={`data:${selectedFile?.contentType};base64,${selectedFile?.data}`}
            width="550"
            height="400"
          />
        )}
      </Box>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Modal
        header="Select uploads from EMR"
        onClick={() => setOpen(!open)}
        open={open}
        headerStyleOverride={{ overflowY: "hidden" }}
      >
        <Box
          m={1}
          display="flex"
          flexDirection="column"
          sx={{ width: "800px" }}
        >
          {renderDocuments()}
          <Divider sx={{ my: 2 }} />
          <Box display="flex" justifyContent="center">
            <Button
              onClick={attachSelectedFile}
              disabled={!selectedFile || !authorization}
              variant="contained"
            >
              Attach
            </Button>
          </Box>
        </Box>
      </Modal>
      <IntegrationFileAttachButtonContainer>
        <BaseButton style={{ margin: "auto" }} onClick={handleOpenModal}>
          Attach from EMR
        </BaseButton>
      </IntegrationFileAttachButtonContainer>
    </div>
  );
};

export default RedoxFilesSuggestionModal;
