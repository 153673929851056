import { useEffect } from "react";
import { Prescriber } from "@samacare/graphql";
import { useFindPrescriberByNpi } from "@samacare/hooks-data";
import { SearchByNpiPane } from "@samacare/form2";

interface SearchPrescriberByNpiPaneProps {
  onFound: (prescriber: Prescriber, alreadyExists: boolean) => void;
  onError: (error: string, NPI: string) => void;
  onClose: () => void;
}
export const SearchPrescriberByNpiPane: React.FC<
  SearchPrescriberByNpiPaneProps
> = (props) => {
  const { onFound } = props;
  const { findPrescriberByNpi, prescriber, errorMessage, alreadyExists } =
    useFindPrescriberByNpi();

  useEffect(() => {
    if (prescriber) {
      onFound(prescriber, alreadyExists);
    }
  }, [prescriber, onFound, alreadyExists]);

  return (
    <SearchByNpiPane
      objectName="provider"
      errorMessage={errorMessage}
      onFind={findPrescriberByNpi}
      onError={props.onError}
      onClose={props.onClose}
    />
  );
};
