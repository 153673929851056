import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { AuthorizationForm } from "@samacare/graphql";

import FaxFormCard from "./FaxFormCard";
import FaxFormFilter, { FaxFormFilters } from "./FaxFormFilter";
import ZoomFormModal from "./ZoomFormModal";

interface FaxFormCardsListProps {
  forms: AuthorizationForm[];
  onSubmit: (params: { formId: string }) => void;
  favoriteForms: AuthorizationForm[];
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const findFormIndex = (
  forms: AuthorizationForm[],
  form: AuthorizationForm
): number => forms.findIndex((f) => f.id === form.id);

const FaxFormCardsList: React.FC<FaxFormCardsListProps> = ({
  forms,
  onSubmit,
  favoriteForms,
}) => {
  const [favoriteFormIds, setFavoriteFormIds] = useState<Set<string>>(
    () => new Set(favoriteForms.map((f) => f.id))
  );
  const [zoomedForm, setZoomedForm] = useState<AuthorizationForm | undefined>();
  const [filter, setFilter] =
    useState<keyof typeof FaxFormFilters>("recommended");

  const recentlyUsedForms = useMemo(() => {
    const oneMonthAgo = moment().subtract(1, "month");
    return forms.filter(
      (form) => form.lastUsed && oneMonthAgo.isBefore(form.lastUsed)
    );
  }, [forms]);

  const visibleForms = useMemo(() => {
    switch (filter) {
      case "favorite":
        return favoriteForms;
      case "recentlyUsed":
        return recentlyUsedForms;
      default:
        return forms;
    }
  }, [filter, forms, favoriteForms, recentlyUsedForms]);

  useEffect(() => {
    if (forms.length > 0 && visibleForms.length === 0) {
      setFilter("recommended");
    }
  }, [forms, visibleForms]);

  const availableForms = visibleForms.length ? visibleForms : forms;

  const handleFavoriteToggle = useCallback((id: string) => {
    setFavoriteFormIds((prevFavoriteFormIds) => {
      const updatedFavorites = new Set(prevFavoriteFormIds);
      updatedFavorites.has(id)
        ? updatedFavorites.delete(id)
        : updatedFavorites.add(id);
      return updatedFavorites;
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    setZoomedForm(undefined);
  }, []);

  const handleFinish = useCallback(() => {
    if (zoomedForm?.id) {
      onSubmit({ formId: zoomedForm.id });
      setZoomedForm(undefined);
    }
  }, [zoomedForm, onSubmit]);

  const handlePrev = useCallback(() => {
    if (!zoomedForm) return;
    const zoomedFormIndex = findFormIndex(availableForms, zoomedForm);
    const newIndex = zoomedFormIndex - 1;
    if (newIndex >= 0) {
      setZoomedForm(availableForms[newIndex]);
    } else if (newIndex < 0) {
      setZoomedForm(availableForms[availableForms.length - 1]);
    }
  }, [zoomedForm, availableForms]);

  const handleNext = useCallback(() => {
    if (!zoomedForm) return;
    const zoomedFormIndex = findFormIndex(availableForms, zoomedForm);
    const newIndex = zoomedFormIndex + 1;
    if (newIndex < availableForms.length) {
      setZoomedForm(availableForms[newIndex]);
    } else if (newIndex === availableForms.length) {
      setZoomedForm(availableForms[0]);
    }
  }, [zoomedForm, availableForms]);

  return (
    <>
      <FaxFormFilter
        filter={filter}
        amount={{
          recommended: forms.length,
          favorite: favoriteForms.length,
          recentlyUsed: recentlyUsedForms.length,
        }}
        onChange={setFilter}
      />
      <Container>
        {availableForms.map((form) => (
          <FaxFormCard
            key={form.id}
            form={form}
            setZoomedForm={setZoomedForm}
            isFavorite={favoriteFormIds.has(form.id)}
            onFavoriteChange={handleFavoriteToggle}
            onSubmit={onSubmit}
          />
        ))}
        {zoomedForm && (
          <ZoomFormModal
            pdfURL={zoomedForm.pdfURL}
            title={zoomedForm.title ?? ""}
            onClose={handleCloseModal}
            onFinish={handleFinish}
            onPrev={handlePrev}
            onNext={handleNext}
            counter={{
              totalAmount: availableForms.length,
              currentPosition: findFormIndex(availableForms, zoomedForm) + 1,
            }}
          />
        )}
      </Container>
    </>
  );
};

export default FaxFormCardsList;
