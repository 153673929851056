import { PureComponent } from "react";
import _ from "lodash";
import styled from "styled-components";
import Section from "AuthorizationSharedComponents/Section";
import Creatable from "react-select/creatable";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { connect } from "react-redux";

import RequiredTag from "../RequiredTag";
import AddDrugModal from "../../routes/NewAuthorization/InitializeSection/AddDrugModal";
import Modal from "../Modal";
import { getDrugOptionsQuery } from "../../graphql/DrugOptions";
import {
  setDrugOptionId as setDrugOptionIdAction,
  setFormFields as setFormFieldsAction,
} from "../../reducers/formReducer";
import { dispatchSetDrugOptions } from "../../reducers/drugOptions";
import { withCurrentAccount } from "../../graphql/Account";
import {
  InsuranceCompanySupportedCodingTypes,
  FilterFormBenefitType,
  EnhancedServiecesEnum,
} from "@samacare/graphql";
import { EnhancedServicesIcon } from "@samacare/component";
import { SponsoredBvCreationSection } from "../../BenefitsVerifications/SponsoredBvCreationSection";

const Selector = styled(Creatable)`
  width: 600px;
  border: ${(props) => (props.highlight ? `2px solid ${props.theme.red}` : "")};
`;

const SelectorTitle = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

const OptionLabel = styled.div`
  display: flex;
  align-items: center;
`;

const defaultFields = CONFIG.DEFAULT_FIELDS;
export class PrimaryDrug extends PureComponent {
  state = { newHCPCSValue: "" };

  componentDidMount = async () => {
    const { setDrugOptions, drugOptionsLoaded, client } = this.props;

    if (!drugOptionsLoaded) {
      const options = await client.query({
        query: getDrugOptionsQuery,
        fetchPolicy: "no-cache",
      });
      setDrugOptions(options.data.getDrugOptions);
    }
  };

  setDrug = (id = null, name = "", jcode = "") => {
    const { setFormFields, setDrugOptionId } = this.props;

    setDrugOptionId(id == null ? null : parseInt(id, 10));
    setFormFields({
      [defaultFields.PRIMARY_DRUG_NAME.key]: name,
      [defaultFields.HCPCS_0.key]: jcode,
      [defaultFields.ALL_HCPCS.key]: jcode,
      [defaultFields.BENEFIT_TYPES.key]: FilterFormBenefitType.MedicalOnly,
      [defaultFields.DRUG_CODE_TYPE.key]:
        InsuranceCompanySupportedCodingTypes.Jcode,
      [defaultFields.REQUEST_DESCRIPTION.key]: name.toUpperCase(),
    });
  };

  render() {
    const {
      authorization,
      drugOptions,
      results,
      // Save changes is only passed in for form authorizations
      saveChanges = () => {},
      highlight,
    } = this.props;
    const { newHCPCSValue } = this.state;

    return (
      <>
        <Section
          section={{
            items: results[defaultFields.PRIMARY_DRUG_NAME.key]
              ? [
                  defaultFields.PRIMARY_DRUG_NAME,
                  {
                    ...defaultFields.HCPCS_0,
                    onBlur: async () => {
                      // done to fetch whether there are additional required fields
                      await saveChanges();
                    },
                  },
                ]
              : [],
            title: "Search for JCODE/CPT code",
          }}
          childrenAfterSection
        >
          {!results[defaultFields.PRIMARY_DRUG_NAME.key] && (
            <div data-cy="controlPrimaryDrugName">
              <SelectorTitle>
                Service name (search by HCPCS or Name)
                <RequiredTag />
              </SelectorTitle>
              <Selector
                options={drugOptions}
                highlight={highlight}
                onChange={async (selected) => {
                  await this.setDrug(
                    _.get(selected, "id"),
                    _.get(selected, "drugName"),
                    _.get(selected, "code")
                  );
                  await saveChanges();
                }}
                createOptionPosition="first"
                formatCreateLabel={() =>
                  "The service I am looking for is missing"
                }
                onCreateOption={(value) => {
                  this.setState({ newHCPCSValue: value ?? "" });
                }}
                getOptionLabel={(option) => {
                  return (
                    <OptionLabel>
                      {option.label}{" "}
                      {!_.isEmpty(
                        _.intersection(
                          [
                            EnhancedServiecesEnum.AuthInform,
                            EnhancedServiecesEnum.PaBoost,
                          ],
                          option.enhancedServices
                        )
                      ) && (
                        <EnhancedServicesIcon
                          hover={false}
                          sx={{ fontSize: 20 }}
                        />
                      )}
                    </OptionLabel>
                  );
                }}
              />
            </div>
          )}
          {authorization.patient && (
            <SponsoredBvCreationSection auth={authorization} />
          )}
        </Section>
        {newHCPCSValue && (
          <Modal
            header="Add a Service to SamaCare"
            onClick={() => {
              this.setState({ newHCPCSValue: "" });
            }}
            open
          >
            <AddDrugModal
              set={this.setDrug}
              defaultValue={newHCPCSValue}
              authorizationId={authorization.id}
              close={() => {
                this.setState({ newHCPCSValue: "" });
              }}
            />
          </Modal>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    drugOptions: state.drugOptions.options,
    drugOptionsLoaded: state.drugOptions.loaded,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setFormFields(fields) {
    dispatch(setFormFieldsAction(fields));
  },
  setDrugOptionId(drugOptionId) {
    dispatch(setDrugOptionIdAction(drugOptionId));
  },
  setDrugOptions(options) {
    dispatch(dispatchSetDrugOptions(options));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withCurrentAccount)(withApollo(PrimaryDrug)));
