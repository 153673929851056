import Creatable from "react-select/creatable";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { getDrugOptionsQuery } from "../../../graphql/DrugOptions";
import {
  Authorization,
  FilterFormBenefitType,
  InsuranceCompanySupportedCodingTypes,
  Query,
} from "@samacare/graphql";
import { useSet } from "../../../hooks/useSet";
import { useSetDrugOptionId } from "../../../hooks/useSetDrugOptionId";
import colors from "../../../../resources/colors";
import { CSSObjectWithLabel, SingleValue } from "react-select";
import Modal from "../../../components/Modal";
import AddDrugModal from "../../NewAuthorization/InitializeSection/AddDrugModal";

type Option = { label: string; value: string };

const df = window.CONFIG.DEFAULT_FIELDS;

interface PrimaryDrugSelectorProps {
  auth: Authorization;
  highlight?: boolean;
  onChange?: (hcpcsCode: string) => void;
  isPrimary: boolean;
  value: string;
}
export const PrimaryDrugSelector: React.VFC<PrimaryDrugSelectorProps> = (
  props
) => {
  const set = useSet();
  const setDrugOptionId = useSetDrugOptionId();
  const [newHcpcsValue, setNewHcpcsValue] = useState<string | undefined>();
  const [createdOption, setCreatedOption] = useState<Option | undefined>();

  const { data } = useQuery<Query>(getDrugOptionsQuery, {
    fetchPolicy: "no-cache",
  });

  const adjDrugOptions = useMemo<Option[]>(() => {
    const drugOptions = data?.getDrugOptions ?? [];
    const options = drugOptions.map((drugOption) => ({
      value: drugOption.code ?? "",
      label: `[${drugOption.code}] ${drugOption.drugName}`,
    }));
    if (createdOption) {
      return [createdOption, ...options];
    }
    return options;
  }, [data, createdOption]);

  const selectedDrugOption = useMemo<Option | undefined>(() => {
    return adjDrugOptions.find((option) => option.value === props.value);
  }, [adjDrugOptions, props.value]);

  useEffect(() => {
    if (props.value && adjDrugOptions.length > 0 && !selectedDrugOption) {
      setCreatedOption({ value: props.value, label: `[${props.value}]` });
    }
  }, [props.value, selectedDrugOption, adjDrugOptions]);

  const setDrug = (
    id: number | null = null,
    name: string = "",
    jcode: string = "",
    isCreation = false
  ) => {
    if (props.isPrimary) {
      setDrugOptionId(id);
      set({
        [df.PRIMARY_DRUG_NAME.key]: name,
        [df.HCPCS_0.key]: jcode,
        [df.BENEFIT_TYPES.key]: FilterFormBenefitType.MedicalOnly,
        [df.DRUG_CODE_TYPE.key]: InsuranceCompanySupportedCodingTypes.Jcode,
        [df.REQUEST_DESCRIPTION.key]: name.toUpperCase(),
      });
    }
    if (isCreation) {
      setCreatedOption({ value: jcode, label: `[${jcode}] ${name}` });
    }
    if (props.onChange) {
      props.onChange(jcode);
    }
  };

  const setDrugAfterCreation = (id = null, name = "", jcode = "") => {
    setDrug(id, name, jcode, true);
  };

  const handleOptionSelection = (selected: SingleValue<Option>) => {
    const drugOptions = data?.getDrugOptions ?? [];
    const drugOption = drugOptions.find(
      (option) => option.code === selected?.value
    );
    const drugOptionId =
      drugOption == null ? null : parseInt(drugOption.id, 10);
    setDrug(drugOptionId, selected?.label.split("] ")[1], selected?.value);
  };

  const placeholder = `${
    props.isPrimary ? "Primary Service  -  " : ""
  }search by HCPCS or name`;

  return (
    <>
      <Creatable<Option>
        id="fieldHcpcsCode"
        options={adjDrugOptions}
        value={selectedDrugOption}
        styles={{
          control: (baseStyles) =>
            ({
              ...baseStyles,
              minHeight: "46px",
              minWidth: "300px",
              maxWidth: "400px",
              border: props.highlight ? `2px solid ${colors.red}` : "",
            } as CSSObjectWithLabel),
        }}
        onChange={handleOptionSelection}
        createOptionPosition="first"
        formatCreateLabel={() => "The service I am looking for is missing"}
        placeholder={placeholder}
        onCreateOption={(value) => {
          setNewHcpcsValue(value ?? "");
        }}
      />
      {newHcpcsValue && (
        <Modal
          header="Add a Service to SamaCare"
          onClick={() => {
            setNewHcpcsValue("");
          }}
          open
        >
          <AddDrugModal
            set={setDrugAfterCreation}
            defaultValue={newHcpcsValue}
            authorizationId={props.auth?.id}
            close={() => {
              setNewHcpcsValue("");
            }}
          />
        </Modal>
      )}
    </>
  );
};
